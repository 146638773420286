import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import AccessPanel from './AccessPanel';
import {BaseCard} from '../../../components/wizard';
import { extendedstyles } from '../../appstyles';

const extrastyles = (theme:Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    // overflow: 'scroll',
    justifyContent: 'space-between'
  },
  panel: {
    flex: '1 1 auto'
  },
});

const styles = extendedstyles(extrastyles);

class AccessCard extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.props.deb.log("AccessCard");
    this.state = {
      next: true,
      idtype: null,
      idfrom: null,
      fwopen: false
    };
  }

  render = () => {
    const { classes, theme, ...extraProps  } = this.props;
    const {record,data,extras,formdata,setItem} = this.props;
    return (
      <BaseCard
        cardid={'ACCESS'}
        cardname={'Access'}
        cardtitle={'Services'}
        instruction={'Select Service Access for the agent'}
        step={3}
        buttons={['START','CANCEL','PREV','NEXT','GUIDE']}
        {...extraProps}
      >
        <AccessPanel 
          data={extraProps.data}
          //record={record} 
          record={{...record,...formdata}}
          extras={extras}
          setItem={setItem}
          />
      </BaseCard>
    );
  }
}

AccessCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccessCard);