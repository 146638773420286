import React from 'react';
import Dataset from '../actions/Dataset';


const formatJsonDataset  =  (json:any,rec:any) => {
  
  const rtn = (<Dataset style={{color:'blue'}}  value={json} rec={rec} />);
  return rtn;
}

export {formatJsonDataset};
