import React from 'react';
//import { PropTypes } from 'prop-types';

import StartCard from './StartCard';
import EntityCard from './EntityCard';
import UserCard from './UserCard';
import FundCard from './FundCard';
import AccountCard from './AccountCard';
import AccessCard from './AccessCard';
import SendCard from './SendCard';
import CloseCard from './CloseCard';

const cards = {
  start: <StartCard name='start' />,
  entity: <EntityCard name='entity' />,
  user: <UserCard name='user' />,
  fund: <FundCard name='fund' />,
  account: <AccountCard name='account' />,
  access: <AccessCard name='access' />,
  send: <SendCard name='send' />,
  close: <CloseCard name='close' />
}

const list = ['start','entity','user','fund','account','access','send','close'];
const wizard = {cards,list};

export {cards,list};

export default wizard;