import {getConfig} from '../../core';

const isValidFromEForm = (eform:any[],data:any,mode?:any) => {
  const ids = eform.reduce((acc,rec)=>( ( !!rec.req && (rec.off?!(rec.off.includes(mode)):true) )?[...acc,rec.id]:acc),[]);
  //console.log('ids = ',ids,', data = ',data);
  const v = ids.reduce((acc:boolean,id:number)=>(acc&&(!!data[id]||data[id]===0)),true);
  return v;
}

const isValidFromService = (service:string,data:any,mode?:any) => {
  const config:any = getConfig(service);
  const {eform} = config.service;
  return isValidFromEForm(eform,data,mode);
}

const isValidFromConfig = (formconfig:any,data:any,mode?:any) => {
  const {eform} = formconfig.service;
  return isValidFromEForm(eform,data,mode);
}

const isValidFromFunction = (formconfigfn:Function,data:any,mode?:any) => {
  const config = formconfigfn();
  const {eform} = config.service;
  return isValidFromEForm(eform,data,mode);
}

const isValid = (param:any,data:any,mode?:any) => {
  if(Array.isArray(param)) return isValidFromEForm(param,data,mode);
  if(typeof param === 'string') return isValidFromService(param,data,mode);
  if(typeof param === 'function') return isValidFromFunction(param,data,mode);
  if(typeof param === 'object') return isValidFromConfig(param,data,mode);

  return false;
}

export {isValidFromEForm,isValidFromService,isValidFromConfig,isValidFromFunction,isValid};

export default isValid;
