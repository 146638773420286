import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import DownloadIcon from '@mui/icons-material/ZoomIn';
import {saveAs} from 'file-saver';
import { extendedstyles } from '../../appstyles';

const extrastyles = (theme:Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    // overflow: 'hidden',
    flex: '1 1 auto',
    background: theme.palette.background.paper,
  },
  imageList: {
    width: 800,
    height: 350,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  imageListItem: {
    
  },
  gridTileImage: {
    flex: '1 1 auto'
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
});
const styles = extendedstyles(extrastyles);

class VeriPane extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      count:0
    };
  }

  handleClick = (name:any) => (event:any,value:any) => {
    var {result} = this.props;
    console.log('result',result,value,name);
    var blob = new Blob(value);
    saveAs(blob);
  } 

  render() {
    const { record,photo,idcard,classes} = this.props;
    ///console.log('vpane.record,',record);
    //const photo card;
    return (
      <div className={classes.root}>
        {/* <ImageList rowHeight={300} spacing={1} className={classes.imageList}> */}
        <ImageList rowHeight={350} gap={1} className={classes.imageList}>
          <ImageListItem cols={1} rows={1} className={classes.imageListItem}>
            <img id={photo.resid} src={photo.src} alt="" className={classes.gridTileImage}/>
            <ImageListItemBar
              title={photo.title}
              position="top"
              actionIcon={
                <IconButton className={classes.icon}>
                  <DownloadIcon />
                </IconButton>
              }
              actionPosition="right"
              className={classes.titleBar}
            />
          </ImageListItem>
          <ImageListItem key={idcard.resid} cols={1} rows={1} className={classes.imageListItem}>
            <img id={idcard.resid} src={idcard.src} alt="" className={classes.gridTileImage}/>

            <ImageListItemBar
              title={idcard.title}
              position="top"
              actionIcon={
                <IconButton className={classes.icon}>
                  <DownloadIcon />
                </IconButton>
              }
              actionPosition="right"
              className={classes.titleBar}
            />
          </ImageListItem>
    
        </ImageList>
      </div>
    );
  }
}

VeriPane.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(VeriPane);
