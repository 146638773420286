import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
//import classNames from 'classnames';
import List from '@mui/material/List';
//import Assessment from '@mui/icons-material/Assessment';
import DashIcon from '@mui/icons-material/Assessment';
import MapIcon from '@mui/icons-material/LocationOn';
import MemberIcon from '@mui/icons-material/Group';
import PermIdentity from '@mui/icons-material/PermIdentity';
import DynamicMenuParent from './DynamicMenuParent';
import DynamicMenuChild from './DynamicMenuChild';
import DynamicMenuItem from './DynamicMenuItem';

const styles = (theme:Theme):any => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

class DynamicMenu extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      count:0
    };
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.page !== nextProps.page) {
  //       this.setState({page: nextProps.page});
  //   }
  // }

  handleClick = (name:any) => (event:any,value:any) => {
    console.log('dynamic handleclick:',name,event,value);
    this.setState({activeId:name});
  }

  mkKey = (k:any,n:any) => k+n.toLocaleString('en', {minimumIntegerDigits:3});

  toArray(o:any){
    Object.keys(o).reduce(function(out:any, key:any) {
      out.push({
        key: key,
        value: o[key]
      });
      return out;
    }, []);
  }

  mkMenus = (mns:any,handleClick:Function) => {
    var mnu,mnlist,mnkey,mni,smn,smtitle,smaction,smlist,smi,smkey;
    //const dash = {key:'DASHBOARD',title:'Dashboard',icon:<DashIcon/>,action:'dashboard'};
    //const maps = {key:'MAPBOARD',title:'Mapboard',icon:<MapIcon/>,action:'mapboard'};
    //const mems = {key:'MEMBOARD',title:'Members',icon:<MemberIcon/>,action:'memboard'};
    const logout = {key:'LOGOUT',title:'Logout',icon:<PermIdentity/>,action:'logout'};

    mnlist = [
      // (<DynamicMenuItem index={10} key={dash.key} title={dash.title} icon={dash.icon} divider action={dash.action} activeid={this.state.activeId} onClick={handleClick} />),
      // (<DynamicMenuItem index={20} key={maps.key} title={maps.title} icon={maps.icon} divider action={maps.action} activeid={this.state.activeId} onClick={handleClick} />),
      // (<DynamicMenuItem index={30} key={mems.key} title={mems.title} icon={mems.icon} divider action={mems.action} activeid={this.state.activeId} onClick={handleClick} />)
    ];
    //mni=0;
    mni=mnlist.length+1;
    for(var key in mns){
      // console.log('mns.key',key);
      if(!key || key === 'undefined') continue;
      mnu = mns[key];
      smn = mnu.smn;
      smlist = [];
      smi = 1;
      for(smtitle in smn){ 
        smaction = smn[smtitle];
        smkey = this.mkKey(key,smi);
        smlist.push((<DynamicMenuChild index={mni*10+smi} key={smkey} title={smtitle.replace(/^Manage\s+/,'')} action={smaction} activeid={this.state.activeId} onClick={handleClick} />)); //onclick
        smi++;
      }
      mnkey = this.mkKey(key,0);
      mnlist.push(
        (
          <DynamicMenuParent index={mni*10} key={mnkey} title={mnu.nam.replace(/ .*/,'')} divider activeid={this.state.activeId} onClick={this.handleClick}> 
            {smlist.map((smitem) =>
              smitem 
            )}
          </DynamicMenuParent>
        )
      );
      mni++;
    }
    mnlist.push(
      // (
        <DynamicMenuItem index={(mni+1)*10} key={logout.key} title={logout.title} icon={logout.icon} divider action={logout.action} activeid={this.state.activeId} onClick={handleClick} />
      // )
    )
    return mnlist;
  }

  render() {
    const {data, handleClick, classes} = this.props;
    const mns = data.mn||{};
    const dynmenus = this.mkMenus(mns,handleClick);
    return (
      <div className={classes.root}>
        <List component="nav">
          {dynmenus}
        </List>
      </div>
    );
  }
}

DynamicMenu.propTypes = {
  data: PropTypes.object.isRequired,
  handleClick: PropTypes.func
};

export default withStyles(styles)(DynamicMenu);
//export default withStyles(styles, { withTheme: true })(DynamicMenu);
