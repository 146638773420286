//react
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
//siblings
import {getConfig} from '../config';
import {ServiceBase} from '../../components/service';
import { extendedstyles } from '../appstyles';

const extrastyles = (theme:Theme) => ({});

const styles = extendedstyles(extrastyles);


class Main extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;

  isCustomPage(page:any){
    return false;
  }

  renderCustom() {
    console.log('In Main.render()');
    const {service,code,showNotification,setPage,setHome,classes, theme, ...others} = this.props;
    const title = "Main Records";
    const configuration = getConfig(service); 
    const xservice = React.cloneElement(<ServiceBase />,{
      title,
      service,
      code,
      configuration,
      setPage,
      setHome,
      showNotification,
      classes,
      theme,
      ...others
    });
    return (xservice);
  }

  renderDefault() {
    console.log('In Main.render()');
    const {service,code,showNotification,setPage,setHome,classes, theme, ...others} = this.props;
    const title = "Main Records";
    const configuration = getConfig(service); 
    const xservice = React.cloneElement(<ServiceBase />,{
      title,
      service,
      code,
      configuration,
      setPage,
      setHome,
      showNotification,
      classes,
      theme,
      ...others
    });
    return (xservice);
  }

  render(){
    const {service} = this.props;
    if(this.isCustomPage(service)) {
      return this.renderCustom();
    }
    else {
      return this.renderDefault();
    }
  }
}

Main.propTypes = {
  service: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  setPage: PropTypes.func,
  setHome: PropTypes.func,
  showNotification: PropTypes.func
};

export default withStyles(styles, { withTheme: true })(Main);
