import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
import {BaseCard} from '../../../components/wizard';
//import { CircularProgress } from '@mui/material/Progress';
// import sender from '../core/sender';
import { extendedstyles } from '../../appstyles';

const extrastyles = (theme:Theme) => ({
  card: {
    maxWidth: 345,
    minHeight: 600,
    margin: 'auto',
    paddingTop: 20,
  },
  media: {
    height: 28,
    marginLeft: '20px auto'
  },
  video: {
    width: '90%',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#28dab6',
    border: '1px solid #28dab6',
    borderRadius: '1',
    color: '#fffff',
    paddingLeft: 20,
    paddingRight: 20,
  },
  second: {
    margin: theme.spacing(1),
    backgroundColor: '#ffffff',
    border: '1px solid #cccccc',
    borderRadius: '1',
    color: '#cccccc',
    paddingLeft: 20,
    paddingRight: 20,
  },
  cancel: {
    margin: theme.spacing(1),
    backgroundColor: '#ffffff',
    borderRadius: '1',
    color: '#cccccc',
  },
  divider: {
    margin: '5px 20px 5px 20px',
  },
  progress: {
    margin: theme.spacing(0, 2),
  },
  spinner: {
    backgroundImage: 'url(lib/img/loading.gif)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 'auto',
    width: 80,
    height: 80,
  },
  note:{
    margin: theme.spacing(1),
  },
  error: {
    color: '#ff0000',
  }
});
const styles = extendedstyles(extrastyles);

class SendCard extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.props.deb.log("Send");
    // this.ui = this.props.getUI(this.props.uic,'NONE','UPLOAD','ALL');
    this.state = {
      next: false,
      percent: 0,
      // mesg: this.ui.INSTRUCTION,
      fwopen: false,
      error: false,
      status: 'Uploading'
    };
  }

  componentDidMount(){
    // var userdata = this.props;
    // let that = this;
    // sender.sendtoserver(userdata,this.handleProgress,(err,rtn) => {
    //   //console.log('err:',err);
    //   //console.log('rtn',rtn);
    //   const em = this.props.getUI(this.props.uic,'NONE','FAIL','ALL');
    //   //const sm = this.props.getUI(this.props.uic,'NONE','PASS','ALL');
    //   if(err){
    //     that.props.deb.log(rtn.msg,err);
    //     that.setState({mesg:rtn.msg});
    //     //return;
    //   }
    //   else if((rtn||{}).ref == undefined){
    //     console.log('caught undefined ref');
    //     that.props.deb.log('upload failed. ',rtn.msg,err);
    //     that.setState({mesg:em.INSTRUCTION});
    //   }
    //   else if((rtn.ref||'').match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i)){
    //     that.props.deb.log(rtn.msg);
    //     that.props.setParam('message',rtn.msg);
    //     that.props.setNext(that.props.name);
    //   }
    //   // else{
    //   //   that.props.deb.log(rtn.msg,'COMPLETED');
    //   //   that.setState({mesg:rtn.msg,status:'Completed'});
    //   // }
    // });
  }

  handleProgress = (ev:any,perc:any) => {
    this.setState({percent:perc});
  }

  handleNext = (ev:any)  => {
    this.props.setNext(this.props.name);
  }

  handleCancel = (ev:any)  => {
    this.props.setReset();
  }

  render = () => {
    const message = "You can submit from this page by pressing the appropriate button. Make sure you have reviewed properly";
    const { classes, theme, ...extraProps  } = this.props;  //fmtUI, logo, title, switchFn,
    // const {logo, title, switchFn, classes, theme  } = this.props;
    // const { TITLE, INSTRUCTION,CANCEL,RESTART} = this.ui;
    // const {error} = this.state;
    return (
      <div>
        <BaseCard
          cardid={'SEND'}
          cardname={'Send'}
          title={'Data submission'}
          instruction={'Submit user data to create account'}
          step={3}
          buttons={['START','CANCEL','PREV','NEXT','GUIDE']}
          {...extraProps}
        >
          <Typography>
            {message}
          </Typography>
        </BaseCard>
      </div>
    );
  }
}

SendCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SendCard);
