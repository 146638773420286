import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
//import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { Typography } from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ListIcon from '@mui/icons-material/List';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import {enableDragging} from '../dialogs/DraggablePaperComponent';
import {createFiltered} from '../form/formgenerator';
//import {bizpost} from '../../ajax';
import { extendedstyles } from '../../app/appstyles';

const extrastyles = (theme:Theme):any => ({
  badge:{
    margin: theme.spacing(1),
  },
  flex: {
    flex:1
  },
  hidden: {
    display: 'none'
  }
});

const styles = extendedstyles(extrastyles);

//array switch library. TODO: move to file
const tohash = (array:any[]) => array.reduce(acc=>(acc<<1)|1,0);
const isset = (hash:number,i:number) => ((hash&(1<<i))>0);
const set = (hash:number,i:number) => hash|(1<<i);
const unset = (hash:number,i:number) => hash & ~(1<<i);
const tolist = (array:any[]) => array.map((item:any)=>item.id);
const toarray = (array:any[],hash:number) => array.filter((item:any,i:number)=>isset(hash,i));

// unsigned int count = 0; 
// while (n) 
// { 
//     count += n & 1; 
//     n >>= 1; 
// } 
// return count; 
//const count = hash => {if(!hash) return c}

class GridFilter extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    //fb
    let meta = props.sdata.add || [];
    //cs
    let array = props.cols || [];
    let list = tolist(array);
    let hash = tohash(props.cols||[]);
    let count = list.length;
    this.state = {
      rmode: false,
      cmode: false,
      //fb
      meta: meta,
      data: {},
      dirty: 0,
      //cs
      array: array,
      list: list,
      hash: hash,
      count:count
    };
  }

  handleChange = (name:any) => (ev:any,v:any) => {
    this.setState({[name]:v});
  }

  handleSend = (ev:any) => {
    const {data} = this.state;
    this.props.refresh(data);
  }

  handleReload = (ev:any) => {
    // const {data} = this.state;
    this.props.refresh();
  }
  // from fields
  onChange = (name:any) => (value:any) => {
    let v = (typeof value === 'string' && value.length ===0)?null:value;
    this.setState= ((prevState:any) => ({data:{...prevState.data,[name]:v}})); // this.state.formData[name]=value;
  }

  handleSelect = (i:any) => (ev:any,v:any) => {
    console.log('cs change',i,v,this.state.hash);
    const {hash,list} = this.state;
    //update column selector
    this.setState= ((prevState:any) => ({hash:v>0?set(prevState.hash,i):unset(prevState.hash,i)}));
    //update grid
    const newhash = v?set(hash,i):unset(hash,i);
    const newlist = toarray(list,newhash);
    this.setState({count:newlist.length});
    this.props.setColumns({},newlist);
  }

  onClose = (event:any,reason:any) => {
    if(reason) return false;
    const {onClose} = this.props;
    if(typeof onClose  === 'function') onClose(event,reason);
  }
  
  render() {
    const {open,sdata,refresh,onClose,classes } = this.props;
    const {cmode,data,array,count,hash} = this.state;
    const mode = 'add';
    const record = data;
    const formatters = {};
    //console.log('fb.filteredform');
    const form = (<Fragment><div>test</div></Fragment>); //createFiltered(mode,sdata,record,formatters,this.onChange,classes);
    return (
      
      <Dialog
      // disableBackdropClick
      disableEscapeKeyDown
      //{...enableDragging}
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogTitle>
      <Toolbar>
        <Typography variant="h6" color="inherit" className={classes.flex}>
          {"Grid Filters"}
        </Typography>
        <IconButton color="inherit" onClick={onClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
      </DialogTitle>
      <DialogContent>
        <Accordion defaultExpanded={false} onChange={this.handleChange('rmode')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.flex}>
              Row Filter
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset" className={classes.formControl}>
              {/* <FormLabel component="legend">Search By:</FormLabel> */}
              <FormGroup>
                {form}
              </FormGroup>
              <FormHelperText>
                Enter search fields and send
              </FormHelperText>
            </FormControl>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={false} onChange={this.handleChange('cmode')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.userDataHeading}>
              Column Filter
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset" className={classes.formControl}>
              {/* <FormLabel component="legend">Toggle Columns</FormLabel> */}
              <FormGroup>
                {array.map((item:any,i:number)=>(
                <FormControlLabel key={i}
                  control={
                    <Checkbox checked={isset(hash,i)} onChange={this.handleSelect(i)} />
                  }
                  label={item.label}
                />
                ))}
              </FormGroup>
              <FormHelperText>At least 1 column</FormHelperText>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Badge
          color="secondary"
          badgeContent={count}
          invisible={!cmode}
          className={classes.badge}
        >
          <ListIcon {...(!cmode&&{className:classes.hidden})} />
        </Badge>
        <Button onClick={this.handleReload} color="inherit">
          Reload
        </Button>
        <Button onClick={onClose} color="secondary">
          Clear
        </Button>
        <Button onClick={this.handleSend} color="primary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
    );
  }
}

GridFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  // cols: PropTypes.arrayOf(PropTypes.object).isRequired,
  // fcols: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  setColumns: PropTypes.func.isRequired,
  refresh: PropTypes.func,
  onClose: PropTypes.func,
  //classes: PropTypes.object.isRequired,
  cols: PropTypes.arrayOf(PropTypes.object).isRequired,
  fcols: PropTypes.arrayOf(PropTypes.object),
  //open: PropTypes.bool,
  //setColumns: PropTypes.func.isRequired,
  //onClose: PropTypes.func,
};

export default withStyles(styles)(GridFilter);
