
const htmlpost = async(url:string,json:any) => {
  try{
    const config:any = {
      method:'POST',
      credentials:'include',
      body:JSON.stringify(json), //{s:'security',a:'basicdata'}
      headers: new Headers({
        'Content-Type': 'application/json', //TODO: Accept text/plain
        //'Accept-Type': 'text/plain'
      })
    };
    //console.log('using fetch with url',url,', and config',config);
    const resp = await fetch(url,config); //TODO: test for error
    const response = await resp.text();
    return response;
  }
  catch(err){
    return Promise.reject(err);
  }
}

const helppost = async(json:any) => htmlpost('/help',json);



export {helppost};
export default helppost;
