import React, {Component, Fragment} from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
//import Dialog, {DialogTitle,DialogContent, DialogContentText, DialogActions} from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import ImageList from '@mui/material/ImageList';
// import ImageListItem from '@mui/material/ImageListItem';
// import ImageListItemBar from '@mui/material/ImageListItemBar';
// import ZoomIcon from '@mui/icons-material/ZoomIn';
// import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle } from '@mui/material';
import ClosableBar from './ClosableBar';

const styles = (theme:Theme):any => ({
  button: {
    margin: theme.spacing(1)
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'block'
  },
  dialogcontent: {
    backgroundColor: 'white'
  },
  root: {
    //display: 'flex',
    //flexWrap: 'wrap',
    //justifyContent: 'space-around',
    // overflow: 'hidden',
    flex: '1 1 auto',
    background: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    //height: 350,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    //overflow: 'scroll'
  },
  gridListTile: {
    // flex: '1 1 auto',
  },
  gridTileImage: {
    //flex: '0 1 auto',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
});

class PreviewDialog extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      loading: false,
      open: false,
    };
  }

  render() {
    const {classes,open,photo,source,onClose} = this.props;
    const title = 'Preview image';
    const dialogClasses = {
      paper: classes.dialogcontent
    }
    return (
      <Fragment>
        <Dialog open={open} classes={dialogClasses}>
          <DialogTitle><ClosableBar onClose={onClose}>{title}</ClosableBar></DialogTitle>
          <DialogContent className={classes.dialogcontent}>
              <div className={classes.root}>
                {/* <ImageList rowHeight={350} gap={1} className={classes.gridList}> */}
                  {/* <ImageListItem cols={1} rows={1} className={classes.gridListTile}> */}
                    <img id={photo.resid} src={photo.src} alt="" className={classes.gridTileImage} />
                    {/* <ImageListItemBar
                      title={photo.title}
                      titlePosition="top"
                      actionIcon={
                        <IconButton className={classes.icon} onclick={onClose}>
                          <CloseIcon />
                        </IconButton>
                      }
                      actionPosition="right"
                      className={classes.titleBar}
                    />
                  </ImageListItem> */}
                {/* </ImageList> */}
              </div>
          </DialogContent>
          {/* <DialogActions>
            <Button variant="contained" color="secondary" onClick={this.stopLoading}><CloseIcon/>Close </Button>
          </DialogActions> */}
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PreviewDialog);
