import React, { Component } from 'react';
// import { PropTypes } from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import {clone} from '../../../core/utils';
import {formatDate} from '../../../components/renderers';
import {create} from '../../../components/form';
import { extendedstyles } from '../../appstyles';

const extrastyles = (theme:Theme) => ({
  root: {
    //width:'100%',
    //display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
  },
  input: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
  },
  textarea: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  column: {
    flexBasis: '50.0%',
  },
  hidden: {
    display:'none'
  },
  visible:{

  },
  userDataHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight:'bold',
  }
  
});
const styles = extendedstyles(extrastyles);

const config = {
  service:{
    name:'verification',
    title: 'Verification',
    eform:[{id:'nam',label:'Fullname',type:'tf',req:1,ops:{helperText:'Enter Fullname',pattern:"[a-zA-Z ]+"}},
          {id:'mbn',label:'Mobile No',type:'tf',req:0,ops:{helperText:'Enter Mobile Number',type:'tel'}},
          {id:'dob',label:'Date of Birth',type:'df',req:0,ops:{helperText:'Enter/select date of birth',InputLabelProps:{shrink: true,}}},
          {id:'gdi',label:'Gender',type:'cb',req:1,ops:{helperText:'Select Gender',t:'lcs',s:'gender'}},
          {id:'coi',label:'Nationality',type:'cb',req:1,ops:{helperText:'Select Nationality',t:'bcs',s:'country'}},
          {id:'iti',label:'ID Type',type:'cb',req:1,ops:{t:'bcs',s:'idtype'}},
          {id:'ino',label:'ID Number',type:'tf',req:1,ops:{helperText:'Enter ID Number',pattern:"[a-zA-Z0-9 ]+"}},
          {id:'dsc',label:'Remarks',type:'ta',req:0,ops:{}},
    ],
  },
  create:true,
  actions: {add:'Add'},
  panels: { //simple,dialog,resview,co      
    add:'simple'
  }
};

class VeriForm extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      loading: false,
      surname: undefined,
      given_names: undefined,
      date_of_birth: undefined,
      sex: false,
      nationality: undefined,
      address_line1: undefined,
      fopen: false
    };
  }

  onChange = (name:any) => (event:any,value:any) => {
    console.log(name,event.target.value);
    if(event.target.validity) console.log('vv:',event.target.validity.valid);
    if(event.target.checkValidity) console.log('vv:',event.target.checkValidity());
    //var cs = event.target.checkValidity(); //querySelector(':invalid');
    //console.log('cs:',cs);
    this.setState({
      [name]: value||event.target.value,
    });
  };

  getValues = () => {
    console.log('looking for values');
  }

  render = () =>{
    const {record,classes} = this.props;
    console.log('vform.record,', record);
    const sconfig = clone(config);
    const sdata = sconfig.service; 
    const mode = 'view';
    //const sdata = sdata;
    //const record = {};
    const formatters = {dob:formatDate};
    //const onChange = this.onChange.bind(this);
    //const testChange = this.onChange('nam');
    console.log('sdata:',sdata,'sconfig',sconfig);
    //console.log('testChange()',testChange);
    //const form = generateItemSet(mode,sdata,record,formatters,this.onChange,classes);
    const form = create(mode, sdata, record, formatters, this.onChange, classes);

    return (
      <div className={classes.root}>
        {form}
          {/* <div>
            {form.surname}
          </div>

          <div>
            {form.given_names}
          </div>

          <div>
            {form.date_of_birth}
          </div>
          <div>
            {form.sex}
          </div>
          <div>
            {form.nationality}
          </div>
          <div>
            {form.address_line1}
          </div> */}
        </div>
    );
  }
};

export default withStyles(styles, { withTheme: true })(VeriForm);
