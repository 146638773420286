import React from 'react';
import Typography from '@mui/material/Typography';
//import {grey, pink, purple, blue, teal, green, orange, lightGreen, red} from '@mui/material/colors';
//import moment from 'moment';

const formatStatus = (id:number) => {
  let text = ['Disabled','Active'][id] || 'unknown';
  let color:any = ['secondary','primary'][id] || 'inherit';
  let rtn = (<Typography color={color}>{text}</Typography>);
  return rtn;
}

const loginStatus = (id:number,rec:any) => {
  let text = ['Disabled','Active','Suspended'][id] || id;
  let color:any = ['default','primary','secondary'][id] || 'inherit';
  let rtn = (<Typography color={color}>{text}</Typography>);
  return rtn;
}

const simpleStatus = (id:number,rec:any) => {
  let name = ['disabled','active','stopped'][id] || 'unknown'; 
  return name;
}

export {formatStatus,loginStatus,simpleStatus};
