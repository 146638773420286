import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

const styles = (theme:Theme):any => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
  },
  hide:{
    display:'none',
  },
});

class FCheckBox extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      next: true,
      idtype: this.props.idtype
    };
  }

  handleChange = (name:any) => (event:any) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const {id,label,value,onChange,divider,classes } = this.props;
    //const dataset = [{k:'',v:''},{k:'',v:''}];

    return (
      <div className={classes.root}>
        <TextField
            label={label}
            id={id}
            name={id}
            type={'text'}
            margin='dense'
            // {...options}
            // fullWidth={true}
            className={classes.textField}
            value={value}
            onChange={onChange(id)}
          />
        {divider && <Divider />}
      </div>
    );
  }
}

FCheckBox.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(FCheckBox);
