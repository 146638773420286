import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import {BaseCard} from '../../../components/wizard';
import { extendedstyles } from '../../appstyles';

const extrastyles = (theme:Theme) => ({
  done: {
    margin: theme.spacing(1),
    backgroundColor: '#28dab6',
    color: '#fffff',
    width: '36px',
    height: '36px',
  },
  success: {
    color: '#28dab6'
  }
});

const styles = extendedstyles(extrastyles);

class CloseCard extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.props.deb.log("CloseCard");
    this.state = {
      next: false,
      fwopen: false
    };
  }
  
  // handleProgress = (ev:any,perc:any)  => {
  //   //DO NOTHING
  // }

  // handleNext = (ev) => {
  //   this.props.setNext(this.props.name);
  // }

  // handleCancel = (ev) => {
  //   //this.props.switchFn('intro');
  // }

  // handleDone = (ev) => {
  //   window.location.href = this.ui.URL;
  // }

  render = () => {
    const message = "Process completed successfull. Thank you. Bye!";
    const { classes, theme, ...extraProps  } = this.props;  
    return (
      <div>
        <BaseCard
          cardid={'CLOSE'}
          cardname={'close'}
          cardtitle={'Exit'}
          instruction={'Complete wizard'}
          step={7}
          buttons={['DONE','GUIDE']}
          {...extraProps}
        >
          <Typography>
            {message}
          </Typography>
        </BaseCard>
      </div>
    );
  }
}

CloseCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CloseCard); 