import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import StartPanel from './StartPanel';
import {BaseCard} from '../../../components/wizard';
import { extendedstyles } from '../../appstyles';

const extrastyles = (theme:Theme) => ({
  divider: {
    margin: '5px 20px 5px 20px',
  },
  bullets: {
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(12),
  },
});

const styles = extendedstyles(extrastyles);

class StartCard extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.props.deb.log("StartCard");
    this.state = {
      valid: true,
      iserror: false,
      message: ''
    };
  }

  render = () => {
    const { classes, theme, ...extraProps  } = this.props; 
    const {record,setItem} = this.props;
    // const {valid,iserror,message} = this.state;

    //initial data from caller service
    //const data = extraProps.data;
    //initial data for a fresh wizard. e.g create, new . TODO: caller must always bring something
    const data = {};
    return (
      <div>
        <BaseCard 
          cardid={'START'}
          cardname={'Start'}
          cardtitle={'Welcome'}
          instruction={'welcome message here'}
          step={0}
          buttons={['CANCEL','NEXT','GUIDE']}
          {...extraProps}
        >
          <StartPanel
            data={data}
            record={record}
            setItem={setItem}
          />
        </BaseCard>
      </div>
    );
  }
}

StartCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StartCard);
