import React, { Component } from "react";
import PropTypes from "prop-types";
import { Theme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { clone } from "../../../core/utils";
import { formatDate, formatStatus } from "../../../components/renderers";
import { create } from "../../../components/form/formgenerator";
//import {GridViewer} from '../../../components/grid';
import GridSwitch from "../../../components/grid/GridSwitch";
import { bizpost } from "../../../ajax";
import { extendedstyles } from "../../appstyles";

const extrastyles = (theme: Theme) => ({
  reqform: {
    //
  },
});

const styles = extendedstyles(extrastyles);

class AccessPanel extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props: any) {
    super(props);
    this.state = {
      source: {},
      preview: false,
      valid: false,
      acl: 0,
      count: 0,
      loading: false,
      data: [],
    };
  }

  componentDidMount() {
    this.props.setValid(this.state.valid);
  }

  //try and move this to ajax: async refresh(params,startfn,stopfn,errfn)
  // usage: await refresh(params,startfn.bind(this),stopfn.bind(this),errfn.bind(this))
  refresh = async (params: any) => {
    try {
      this.startLoading();
      const response = await bizpost(params);
      console.log("service response ", response);
      //TODO: build rows and add to state??
      this.setState({ data: response.sd, recordCount: +response.rc });
    } catch (err) {
      const error: any = err || {};
      // console.log(error);
      this.setState({ data: [], recordCount: 0 });
      //this.setState({recordCount:0});
      console.log("refresh error:", error);
      if (this.props.showNotification)
        this.props.showNotification(
          error.message || "request failed",
          error.type || "warn",
          error.title || "Loading Error"
        );
    } finally {
      if (this.state.loading) this.stopLoading();
    }
  };

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };
  /////////////////////////////////

  reload = (rec: any) => {
    if (!rec) return;
    if (!rec.rid) return;
    const params = { s: "service", a: "find", ati: rec.rid };
    this.refresh(params);
  };

  formconfig = () => {
    //combo.inputLabelProps => cilp
    //const cbip = {InputLabelProps:{ shrink:true,}};
    const config = {
      service: {
        name: "accessform",
        title: "Access Form",
        eform: [
          //:nam,:ati,:aci,:ayi,:mbn,:cbu,:cbp,:cbc,:cba,:lci,:dtl,:sts'
          //{ id:'acl',label:'Access Level',type:'fa',req:1,ops:{ multiple:true,helperText:'Select Permissions',t:'bcs',s:'service' } },
          {
            id: "aci",
            label: "Access",
            type: "fa",
            req: 1,
            ops: { multiple: false, helperText: "Select Access Type" },
            cp: { t: "bcs", s: "access" },
          },
        ],
      },
      create: true,
      actions: { add: "Add" },
      panels: { add: "simple" },
    };
    return config;
  };

  setValid = (data: any) => {
    const valid = !!data.ati;
    //console.log('valid:',valid);
    this.props.setValid(valid);
  };

  onChange = (name: any) => (value: any, m: any) => {
    console.log("form.onChange:", name, value, m, this.props.data);
    const { data } = this.props;
    const newdata = { ...data.data, [name]: value }; //newval
    this.setValid(newdata);
    this.props.setItem("data", "data", newdata);
    this.reload(m);
  };

  onSwitch = (recs: any) => {
    //onSwitch = (eform,data,mode) => {
    console.log("onSwitch.recs:", recs);
    const { data } = this.state;
    console.log("onSwitch.data:", data);
    //console.log('onSwitch.param3:',mode);
    //const ids = eform.reduce((acc,rec)=>( ( !!rec.req && (rec.off?!(rec.off.includes(mode)):true) )?[...acc,rec.id]:acc),[]);
    //console.log('ids = ',ids,', data = ',data);
    //const v = ids.reduce((acc,id)=>(acc&&(!!data[id]||data[id]===0)),true);
    //return v;
  };

  makeform = () => {
    const config = this.formconfig();

    const { record, extras, classes } = this.props;
    const sconfig = clone(config);
    const sdata = sconfig.service;
    const mode = "add";
    const formatters = { dob: formatDate };
    const onChange = this.onChange.bind(this);
    const form = create(
      mode,
      sdata,
      record,
      formatters,
      onChange,
      classes,
      extras
    );
    return form;
  };

  makegrid = () => {
    const { ...others } = this.props;
    const { data } = this.state;
    const [page, rowsPerPage, order, orderBy] = [0, 7, "asc", "rid"];
    const grid = [
      ["nam", "Service"],
      ["stn", "Type"],
      ["apn", "Application"],
      ["sts", "Status"],
    ];
    const rownum = true; //numbered list
    var init = rownum
      ? [{ id: "id", numeric: false, disablePadding: true, label: "No" }]
      : [];
    const cols = grid.reduce((ac, va) => {
      ac.push({
        id: va[0],
        numeric: false,
        disablePadding: true,
        label: va[1],
      });
      return ac;
    }, init);
    const gdformatters = {
      sts: formatStatus,
      dcd: formatDate,
      stp: formatDate,
    };
    const filterColumns = (cols: any) => cols;

    return (
      <GridSwitch //TODO: Add service and code for remote activity?
        cols={cols}
        recordset={data}
        rowsPerPage={rowsPerPage}
        page={page}
        order={order}
        orderBy={orderBy}
        formatters={gdformatters}
        filterColumns={filterColumns}
        onSwitch={this.onSwitch}
        {...others}
      />
    );
  };

  render() {
    const { classes } = this.props;
    const form = this.makeform();
    const grid = this.makegrid();

    return (
      <div className={classes.root}>
        {form}
        {grid}
      </div>
    );
  }
}

AccessPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AccessPanel);
