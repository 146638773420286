//react
import React from 'react';
import DialogContentText from '@mui/material/DialogContentText';

const dialogtexts:any = {
  delete: ["Are you sure you want to delete this record?",
    "Please note that this is not reversible!"].join('<br>'),
  retry: "Click Send to retry processing this record",
  check: "Click Send to check the status of this record",
  print: "Click Send to print this record",
  process: "Click Send to submit this record for processing",
  upload: "Click Send to upload this record for processing",
  close: "Are you sure you want to close this record?"
}


const getDialogs = (name:any) => {
  const dt = dialogtexts[name];
  if (dt) {
    return (
      <DialogContentText>
        {dt}
      </DialogContentText>
    )
  }
  return false;
}

export {dialogtexts,getDialogs}

export default getDialogs;
