import React, {Component} from 'react';

import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar'; 
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import ConfIcon from '@mui/icons-material/Settings';
import StatIcon from '@mui/icons-material/PieChart';
import MasterGrid from '../../components/layout/InfoGrid';
import {bizpost} from '../../ajax';
import { clone } from '../../core/utils';
import { formatDate, formatStatus, loginStatus } from '../../components/renderers';
import {getConfig} from '../../core';
import { createItemList } from '../../components/form';
import ChangePassword from '../custom/ChangePassword';
import { extendedstyles } from '../appstyles';
import { Button } from '@mui/material';

const extrastyles = (theme:Theme) => ({
  profileform: {
    padding: theme.spacing(1),
    width: 450,
  },
  titlebar: {
    color: 'white',
    textALign: 'center',
    flex:1
  },
  formControl: {
    margin: theme.spacing(1),
  },
  input: {
    margin: theme.spacing(1),
  },
  actionbox: {
    display: 'flex',
    justifyContent: 'center'
  },
  action: {
    margin: 'auto 5px',
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(1),
  },
  avatarIcon: {
    float: 'left',
    display: 'block',
    marginRight: 15,
    boxShadow: '0px 0px 0px 8px rgba(0,0,0,0.2)'
  },
  titletext: {

  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  details:  {
    width: 400,
  },
  menu: {
    //width: 200,
  },
  hide: {
    display:'node'
  },
  show: {
    display:'inline-block'
  },
  logo: {
    cursor: 'pointer',
    fontSize: 22,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightLight,
    backgroundImage: 'url(lib/img/logo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '0 50%',
    backgroundColor: 'transparent',
    paddingLeft: 5,
    paddingRight: 5,
    height: 20,
    width:25,
    [theme.breakpoints.down('sm')]:{
      display:'none',
    },
  }
  
});

const styles = extendedstyles(extrastyles);

  
class Profile extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);

    this.state = {
      loading: false,
      count: 0,
      open: false,
      sbopen: false,
      sbmesg: '',
      username: '',
      opassword:'',
      npassword:'',
      cpassword:'',
      showPassword: false,
      context: props.context,
      serviceid: 1,
      remember: false,
      valid: props.valid||true,
      data: {}
    };
  }

  onChange = (name:any) => (value:any) => {
    console.log('wizform.onChange:',name, value,this.props.data);
    const {data} = this.props;
    const newdata = {...data.data,[name]:value};
    //this.setValid(newdata);
    this.props.setItem('data','data',newdata);
    //
  }

  makeform = () => {
    //const {service,userData,code,showNotification,setPage,setHome,classes, theme} = this.props;
    const {service,userData} = this.props;
    //console.log('service:',service);
    const record = userData.bd;
    const config = getConfig(service)(); //()?getConfig(service):this.configuration;
    //console.log('config:',config);
    const pans:any = {
      fnm:'user',
      onm:'user',
      snm:'user',
      nam:'user',
      unm:'user',
      rmk:'user',

      rnm:'info',
      ety:'info',
      enm:'info',
      ct1:'info',
      ct2:'info',
      eml:'info',

      lvn:'conf',
      mct:'conf',
      sto:'conf',
      pla:'conf',
      ila:'conf',
      lst:'conf',

      flg:'stat',
      dct:'stat',
      lpd:'stat',
      lld:'stat',
      ast:'stat',
      stp:'stat'};

    const sconfig = clone(config);
    const sdata = sconfig.service;
    const mode = 'view';
    const formatters = { stp: formatDate,ast:formatStatus,lst:loginStatus };
    const onChange = this.onChange.bind(this);
  
    const  extras = {};
    const list = createItemList(mode,sdata,record,formatters,onChange, extras);
    const panes:any = list.reduce((acc:any,item:any,idx:number,arr:any[])=>{
      if(pans[item.id]) {
        acc[pans[item.id]].push(item);
      }
      return  acc;
    },{user:[],info:[],conf:[],stat:[]});
    return panes;
  }

  setValid = (valid:any) => {
    this.setState({valid})
  }

  setParamItem = (key:string,name:any,val:any,done:any) =>{
    try{
      this.setState((prevState:any)=>({[key]:{...prevState[key],[name]:val}}),done)
    }
    catch(e){
      console.log('setParamItem error: ',key,name,val);
    }
  }

  handleLogout = async() => {
    var that = this;
    try{
      const json = {s:'security',a:'logout'};
      const response = await bizpost(json);
      document.location.reload();
    }
    catch(error){
      that.setState({open:false});
      that.props.close();
    }
    finally{
      this.stopLoading();
    }
  }

  handleReset = () => {
    this.setParamItem('data','data',{},()=>{});
  }

  doCancel = () => {};

  doSubmit = async() => {
    const { classes, theme, showNotification} = this.props;
    try{
      const {data} = this.state;
      const {opd,npd,cpd} = data.data;
      var json = {s:'security',a:'change',opw:opd,npw:npd};

      if(npd !== cpd){
        showNotification('Password Do Not Match','warn','Mismatch Password');
          return;
      }

      this.startLoading();

      const response = await bizpost(json);
      // console.log('change password response ',response);
      if(response.success){
        showNotification(response.sm,'success',response.st)
        this.handleLogout();
      }
      else if(response.failure){
        if (response.en===60){
        }
        else{
          showNotification(response.message,response.type||'error',response.title);
          return Promise.reject(response);
        }
      }       
    }
    catch(err){
      const error:any = err || {};
      console.log('submit.error:',JSON.stringify(error));
      showNotification(error.message||"request failed",error.type||'warn',error.title||'Update Error');
      return Promise.reject(error);
    }
    finally{
      this.stopLoading();
    }
     
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }

  render() {
    //const {service,code,showNotification,setPage,setHome,classes, theme} = this.props;
    const {userData,classes} = this.props;
    const unm = (userData.bd||{}).unm; 

    //console.log('data:',data);
    const avatarsrc = `/api/photo/${unm}`;
    const {user,info,conf,stat} = this.makeform();
    const {data,valid} = this.state;
    //console.log('info:',info,'conf:',conf);
    return (
      <MasterGrid>
        <Paper elevation={3} square={false}>
        <AppBar position="static">
          <Toolbar>
            {/* <div className={classes.logo} style={{backgroundImage: `url(${logosrc})`}}/> */}
            
            <Avatar src={avatarsrc} className={classes.avatarIcon}></Avatar>
            <Typography variant="h5" className={classes.titlebar}>
              Profile
            </Typography>
          </Toolbar>
        </AppBar>
          <div>
          {/* <form id="profileform" className={classes.profileform}> */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <UserIcon className={classes.leftIcon} />
                <Typography variant="h5" className={classes.titletext}>
                  User
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <form className={classes.profileform}>
                  {user.map((item:any) => (<React.Fragment key={item.id}>{item.dom}</React.Fragment>))}
                </form>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <InfoIcon className={classes.leftIcon} />
                <Typography variant="h5" className={classes.titletext}>
                  Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <form className={classes.profileform}>
                  {info.map((item:any) => (<React.Fragment key={item.id}>{item.dom}</React.Fragment>))}
                </form>
              </AccordionDetails>
            </Accordion>
            <ChangePassword 
              data={data}
              record={{}}
              valid={valid}
              setValid={this.setValid.bind(this)}
              setItem={this.setParamItem.bind(this)}
              doSubmit={this.doSubmit.bind(this)}
            />  
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ConfIcon className={classes.leftIcon} />
                <Typography variant="h5" className={classes.titletext}>
                  Configuation
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <form className={classes.profileform}>
                  {conf.map((item:any) => (<React.Fragment key={item.id}>{item.dom}</React.Fragment>))}
                </form>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <StatIcon className={classes.leftIcon} />
                <Typography variant="h5" className={classes.titletext}>
                  Status
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <form className={classes.profileform}>
                  {stat.map((item:any) => (<React.Fragment key={item.id}>{item.dom}</React.Fragment>))}
                </form>
              </AccordionDetails>
            </Accordion>
          {/* </form> */}
          </div>
          <div className={classes.actionbox}>
            <Button variant="contained" color="secondary" className={classes.action} onClick={this.doCancel}>Cancel</Button>
            {/* <Button variant="contained" color="primary" className={classes.action} onClick={this.doSubmit}>Submit</Button> */}
          </div>
        </Paper>
        <div>
          
        </div>
      </MasterGrid>
    );
  }
  
}

export default withStyles(styles, { withTheme: true })(Profile);
