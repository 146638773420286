import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import Paper from '@mui/material/Paper';
import {grey} from '@mui/material/colors';
import { Button } from '@mui/material';
import {getIcon} from '../../base';


const styles = (theme:Theme):any => ({
  paper: {
    flex: 1,
  },
  button: {
    width: '100%',
    justifyContent: 'start',
    alignItems: 'center'
  },
  iconSpan: {
    float: 'left',
    height: 90,
    width: 90,
    textAlign: 'center',
    //backgroundColor: theme.color
  },
  icon: {
    height: 48,
    width: 48,
    marginTop: 20,
    fill: 'white',
    maxWidth: '100%'

  },
  content: {
    padding: '5px 5px',
    marginLeft: 90,
    // marginTop: 'auto',
    height: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    alignContent: 'space-around'
  },
  text: {
    flex: '1 1 40px',
    alignSelf: 'center',
    fontSize: 20,
    fontWeight: theme.typography.fontWeightLight,
    color: grey['800']
  },
  number: {
    display: 'block',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 18,
    color: grey['800']
  }
});

class MenuBox extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      test: null
    };
  }

  render() {
    const {color, title, value, icon, onClick, classes} = this.props;
    const extras = {
      span:{backgroundColor: color}
    };
    const Icon = getIcon(icon);
    return (
      <Paper className={classes.paper}>
        <Button onClick={onClick} className={classes.button}><div>
          <span className={classNames(classes.iconSpan,extras.span)} style={extras.span}>
            <Icon color={'inherit'} className={classes.icon} />
          </span>

          <div className={classes.content}>
            <span className={classes.text}>{title}</span>
            <span className={classes.number}>{value}</span>
          </div>
          </div>
        </Button>
      </Paper>
      );
  }
}

MenuBox.propTypes = {
  Icon: PropTypes.any, // eslint-disable-line
  color: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.any
};

// export default InfoBox;
export default withStyles(styles, { withTheme: true })(MenuBox);
