import React, {Component} from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';

const styles = (theme:Theme):any => ({
  rootbox:{
    position: 'relative',
    height: '100vh',
  },
  gridbox: {
    // margin: '0 auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)'
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
  },
  messagebox:{
    // height: '100%',
    width: 600,
    backgroundImage: 'url(lib/img/bg02.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '0 50%',
    backgroundColor: 'transparent',
  },
  loginbox: {
    //width: 450
    margin:'50px auto',
    display: 'inline-block'
  },
  loginform: {
    padding: theme.spacing(1),
  },
  titlebar: {
    color: 'white',
    textALign: 'center',
    flex:1
  },
  formControl: {
    margin: theme.spacing(1),
  },
  input: {
    margin: theme.spacing(1),
  },
  actionbox: {
    display: 'flex',
    justifyContent: 'center'
  },
  action: {
    margin: 'auto 5px',
  },
  linkbox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'right'
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
  },
  menu: {
    //width: 200,
  },
  hide: {
    display:'node'
  },
  show: {
    display:'inline-block'
  },
  logo: {
    cursor: 'pointer',
    fontSize: 22,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightLight,
    backgroundImage: 'url(lib/img/logo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '0 50%',
    backgroundColor: 'transparent',
    paddingLeft: 5,
    paddingRight: 5,
    height: 20,
    width:25,
    [theme.breakpoints.down('sm')]:{
      display:'none',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  alertdiv: {
    width: 400,
    height: 400,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  
});
  
class LoginMain extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);

    this.state = {
      loading: false,
      count: 0,
      open: false,
      sbopen: false,
      sbmesg: '',
      username: '',
      password:'',
      showPassword: false,
      context: props.context,
      serviceid: 1,
      remember: false,
      notstatus: false,
      nottype: 'info',
      nottitle: 'Notification',
      notmessage: 'Notification'
    };
  }

  onNotificationClose = (event:any) => {
    this.setState({notstatus:false,nottype:'info',nottitle:'Notification',notmessage:'Notification'});
  }

  handleSBClose = (event:any,reason:any) => {
    console.log('Notification closed');
    this.setState({sbopen:false,sbmesg:''});
  }

  render() {
    const {classes} = this.props;
    //const {loading,sbopen} = this.state;
    const {notstatus,nottype,nottitle,notmessage} = this.state;
    const vertical = 'top', horizontal = 'center';
    //const logosrc = '/api/logo/icon.png';

    //const pwdAdornProps:any = {position:'end'};

    return (
        <div>

          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={this.state.sbopen}
            onClose={this.handleSBClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{this.state.sbmesg}</span>}
          />
          
          <Backdrop className={classes.backdrop} open={notstatus} onClick={this.onNotificationClose}>
          <div className={classes.alertdiv}>
            <Alert severity={nottype} variant="filled">
            <AlertTitle>{nottitle}</AlertTitle>
              {notmessage}
            </Alert>
            </div>
          </Backdrop>
        </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LoginMain);

//export default LoginPage;
