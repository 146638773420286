//import { createElement } from "react";
import moment from 'moment';
import {setConfig} from './serviceconfig';

const ServiceData = setConfig({});

const tuples = (jso:any) => Object.keys(jso).map(key => ({k:key,v:jso[key]}));

const ucfirst = (lower:string) => lower.replace(/^\w/, (c) => c.toUpperCase());
 
const clone = (config:any) => {
  var sd = Object.assign({},ServiceData,config); 
  sd.service = Object.assign( {},ServiceData.service,config.service);
  var serviceforms = (sd.service.eform).reduce((acc:any,v:any)=> {
    Object.keys(acc).map(k=>acc[k].push([v.id,v.label,v.type,v.req,v.ops]));
    return acc;
  },{add:[],edit:[],view:[]});
  sd.service = Object.assign( {},ServiceData.service,serviceforms,config.service);
  var init = sd.service.num?[{id:'id',numeric:false,disablePadding:true,label:'No'}]:[];
  sd.service.cols = sd.service.grid.reduce((ac:any,va:any)=>{
    ac.push({id:va[0],numeric:false,disablePadding:true,label:va[1]});
    return ac;
  },init);
  return sd;
} 

const formatDate = (id:any) => {  //id must be isostamp 
  return moment(id).format('DD-MMM-YYYY HH:mm');
}

const getSource = (id:any,rec:any) => {
  if(rec.dsc === "") return null;
  const it = JSON.parse(rec.dsc) || {};
  return it.src||'None';
}

const clamp = (value:number, min = 0, max = 100) =>{
  return Math.min(Math.max(value, min), max);
}

export {tuples,ucfirst,clone,clamp,formatDate,getSource};

const funcs = {
  isArray(arg:any) {
    return Object.prototype.toString.call(arg) === '[object Array]';
  },
}

export function isArray(arg:any) {
  return Object.prototype.toString.call(arg) === '[object Array]';
}

export function isObject(arg:any) {
  return Object.prototype.toString.call(arg) === '[object Object]';
}

export function isFunction(arg:any) {
  return typeof arg === 'function';
}

export default funcs;
