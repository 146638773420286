import { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { clone } from '../../../core/utils';
import { formatDate, formatGender } from '../../../components/renderers';
import { create, isValid } from '../../../components/form';
import { extendedstyles } from '../../appstyles';

const extrastyles = (theme:Theme) => ({
  reqform: {
    //
  },
});
const styles = extendedstyles(extrastyles);

class SystemPanel extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      source: {},
      preview: false,
      valid: false,
      count:0
    };
  }

  componentDidMount(){
    this.props.setValid(this.state.valid);
  }

  formconfig = () => {
    const config = {
      service: {
        name: 'system',
        title: 'System Information',
        eform: [
          {id:'cui',label:'Currency',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'currency'}},
          {id:'bri',label:'Branch',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'branch'}},
          {id:'ano',label:'Account No',type:'tf',req:0,ops:{}},
          {id:'anm',label:'Account Name',type:'tf',req:0,ops:{}},
          {id:'ctn',label:'Contact',type:'tf',req:0,ops:{}},
        ],
      },
      create: true,
      actions: { add: 'Add' },
      panels: { add: 'simple' },
    };
    return config;
  }

  setValid = (data:any)  => {
    const config = this.formconfig();
    const valid = isValid(config,data);
    console.log('valid:',valid);
    this.props.setValid(valid);
  }

  onChange = (name:any) => (value:any) => {
    console.log('wizform.onChange:',name, value,this.props.data);
    const {data} = this.props;
    const newdata = {...data.data,[name]:value};
    this.setValid(newdata);
    this.props.setItem('data','data',newdata);
  }

  makeform = () => {
    const config = this.formconfig();
    const { record, classes, extras } = this.props;
    const sconfig = clone(config);
    const sdata = sconfig.service;
    const mode = 'add';
    const formatters = {dob:formatDate, gdi:formatGender};
    const onChange = this.onChange.bind(this);
    const form = create(mode,sdata,record,formatters,onChange,classes,extras);
    return form;
  }

  render() {
    return this.makeform();
  }
}

SystemPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SystemPanel);
