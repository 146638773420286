import React,{Component} from 'react';
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Radio';
import GridHeader from './GridHeader';
//import GridToolbar from './GridToolbar';
import GridPagingActions from './GridPagingActions';
import {gridSort} from './GridSorter';
import { extendedstyles } from '../../app/appstyles';

const extrastyles = (theme:Theme):any => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: { 
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

const styles = extendedstyles(extrastyles);

class GridEditor extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      order: this.props.order || 'asc',
      orderBy: this.props.orderBy, //'calories',
      selected: [],
      colids: props.cols.map((row:any)=>row.id),
      cols: props.cols,//this.props.cols,
      data: props.data,//this.props.data,
      page: props.page || 0,//this.props.page || 0,
      rowsPerPage: props.rowsPerPage||10,//this.props.rowsPerPage||5,
      selectedRecord: null,
      selectedIndex: null,
      actions: [],
    };
  }

  // filterColumns = () => {
  //   const {cols} = this.props;
  //   const {colids} = this.state;
  //   let t=cols.filter(row=>colids.includes(row.id));
  //   console.log(cols,colids,t);
  //   return t;
  // }

  // setColumns = (event,xcols) => {
  //   console.log('setColumn(e,v): ',event,xcols);
  //   this.setState({colids:xcols});
  // }

  onColumnSort = (event:any, property:any) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  // onRowSelectAll = (event:any) => {
  //   if (this.state.selectedIndex){ //(event.target.checked) {
  //     this.setState({ selected: [], selectedRecord: null, selectedIndex:null });
  //   }
    
  // };

  // onRowSelect = (event, id) => {
  //   // console.log('id',id);
  //   if(this.state.selectedIndex === id){
  //     return;
  //   }
  //   //RSM
  //   const rec = this.props.data[id-1];
  //   //console.log('index',id-1)
  //   //determine if actions should be updated by calling a parent method here
  //   const {actions} = this.props;
  //   const actype = typeof actions;
  //   const gridactions = Array.isArray(actions) ? actions
  //                     : (actype === 'function')?actions(id,rec)
  //                     : (actype === 'object')?Object.keys(actions)
  //                     : (actype === 'string')?[actions]:[];
  //   //const acts = {function:actions(id,rec):(actype == 'array')?actions:(actype == 'string')?[action]:[];}
  //   //
  //   console.log('gp.handleclick.actions:', actions);
  //   console.log('gp.handleclick.actype:', actype);
  //   console.log('gridactions:',gridactions);
  //   this.setState({ selected: [id] , selectedRecord: rec, selectedIndex:id, actions: gridactions});
  // };

  onPageChange = (event:any, page:number) => {
    this.setState({ page });
  };

  onRowsPerPageChange = (event:any) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  
  //RSM
  isSelected = (id:any) => this.state.selectedIndex === id;

  render() {
    // const { title, sdata, cols,data, formatters, actionSet, onAction, handleSelect, refresh,setColumns,filterColumns,classes } = this.props;
    // const { selected, selectedRecord, actions, order, orderBy, rowsPerPage, page,colids } = this.state;
    const { cols,data, formatters,filterColumns,classes } = this.props;
    const { selected, order, orderBy, rowsPerPage, page,colids } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const fm = formatters||{};
    // console.log("filterColumns",cols,"props",this.props);
    const fcols = filterColumns(cols,colids);
    return (
      <Paper className={classes.root}>
        {/* <GridToolbar 
          title={title} 
          numSelected={selected.length} 
          selectedRecord={selectedRecord}
          handleSelect={handleSelect}
          onAction={onAction}
          refresh={refresh}
          actions={actions}
          actionSet={actionSet}
          setColumns={setColumns}
          cols={cols}
          fcols={fcols}
          sdata={sdata}
        /> */}
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <GridHeader
              rows={fcols}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              //onSelectAllClick={this.onRowSelectAll}
              //onRequestSort={this.onColumnSort}
              rowCount={data.length}
            />
            <TableBody>
              {gridSort(data, order, orderBy)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      //onClick={event => this.onRowSelect(event, n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell key="rsm" padding="checkbox">
                        <Checkbox checked={isSelected}/>
                      </TableCell>
                      {fcols.map((col:any) =>
                      <TableCell key={col.id} padding="none">
                        {fm[col.id]?fm[col.id](n[col.id],n):n[col.id]}
                       </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={fcols.length+1} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={fcols.length+1}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.onPageChange}
                  onRowsPerPageChange={this.onRowsPerPageChange}
                  //ActionsComponent={GridPagingActions}
                  //ActionsComponent={'span'}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

GridEditor.propTypes = {
  filterColumns:PropTypes.func,
  classes: PropTypes.object.isRequired,
  rowsPerPage: PropTypes.number,
  data: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default withStyles(styles)(GridEditor);
