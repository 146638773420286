import React,{Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
//import  {pink} from '@mui/material/colors';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewCompact';
// import DeleteIcon from '@mui/icons-material/Delete';
// import ManIcon from '@mui/icons-material/Assignment';
// import RetryIcon from '@mui/icons-material/Replay';
// import CloseIcon from '@mui/icons-material/HighlightOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterListIcon from '@mui/icons-material/FilterList';
// import ColumnViewIcon from '@mui/icons-material/ViewColumn';
import { lighten } from '@mui/system/colorManipulator';
import SelectionAlert from './SelectionAlert';
// import ColumnSelector from './ColumnSelector';
//import FilterBox from './FilterBox';
import GridFilter from './GridFilter';
import {filterActions} from './GridActions';
import { extendedstyles } from '../../app/appstyles';

const extrastyles = (theme:Theme):any => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actionlist: {
    display: 'flex'
  },
  actions: {
    display: 'flex',
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

const styles = extendedstyles(extrastyles);


// const actionmenus = [
//   {action:'edit',label:'Edit',title:'Edit',icon:<EditIcon/>},
//   {action:'view',label:'View',title:'View',icon:<ViewIcon/>},
//   {action:'delete',label:'Delete',title:'Delete',icon:<DeleteIcon/>},
//   {action:'man',label:'Manage',title:'Manage',icon:<ManIcon/>},
//   {action:'retry',label:'Retry',title:'Retry',icon:<RetryIcon/>},
//   {action:'close',label:'Close',title:'Close',icon:<CloseIcon/>}
// ];

// const defaultmenus = actionmenus.slice(0,2);
    
// const filterActions = (actions) => {
//   if(actions.length === 0) return defaultmenus;
//   return actionmenus.reduce((ac,v) => {
//     if(actions.indexOf(v.action)>-1) ac.push(v);
//     return ac;
//   },[]);
// }

class GridToolbar extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      mode:0,
      opencs:false,
      openfb: false
    };
  }

  openSelector = (ev:any) => this.setState({opencs:true});
  closeSelector = (ev:any) => this.setState({opencs:false});
  openFilter = (ev:any) => this.setState({openfb:true});
  closeFilter = (ev:any) => this.setState({openfb:false});
  
  render() {
    console.log('in GridToolbar.render()');
    const { title, sdata, actions, numSelected, selectedRecord, cols, fcols, setColumns,handleSelect, refresh, classes } = this.props;
    //const { opencs,openfb } = this.state;
    const { openfb } = this.state;
    const menu = filterActions(actions);
    //console.log('acmenus',menu);
    //[{action:'edit',label:'Edit',title:'Edit'},{action:'view',label:'View',title:'View'},{action:'delete',label:'Delete',title:'Delete'}];
    return (
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          <Typography variant="h4" id="tableTitle">
            {title}
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.title}>
          <SelectionAlert numSelected={numSelected}/>
        {/* {numSelected > 0 && (
          <Typography color="inherit" variant="subheading">
            {numSelected} selected
          </Typography>
        )} */}
        </div>
        {(numSelected > 0) ? (
          <div className={classes.actions}>
          {menu.map((mi:any)=>(
            <React.Fragment key={mi.action}>
              <Tooltip title={mi.title} placement="top">
                <IconButton aria-label={mi.label} onClick={handleSelect({item:selectedRecord,action:mi.action})}>
                  {mi.icon}
                </IconButton>
              </Tooltip>
            </React.Fragment>
          ))}
            <Tooltip title="Refresh" placement="top">
              <IconButton aria-label="Refresh Grid" onClick={refresh}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
          ): (
          <div className={classes.actions}>
            {/* <Tooltip title="Columns" placement="top">
              <IconButton aria-label="Columns" onClick={this.openSelector}>
                <ColumnViewIcon color="primary" />
              </IconButton>
            </Tooltip>
            <ColumnSelector open={opencs} onClose={this.closeSelector} cols={cols} fcols={fcols} setColumns={setColumns} /> */}
            <Tooltip title="Refresh" placement="top">
              <IconButton aria-label="Refresh Grid" onClick={refresh}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Filter list" placement="top">
              <IconButton aria-label="Filter list" onClick={this.openFilter}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            {/* <FilterBox open={openfb} sdata={sdata} onClose={this.closeFilter} setColumns={setColumns} refresh={refresh}/> */}
            <GridFilter open={openfb} sdata={sdata} onClose={this.closeFilter} cols={cols} fcols={fcols} setColumns={setColumns} refresh={refresh}/>
          </div>
          )}
        
      </Toolbar>
    );
  }
}

GridToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  actions: PropTypes.array.isRequired,
};

export default withStyles(styles)(GridToolbar);
