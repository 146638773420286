import React, {Component, Fragment} from 'react';
import {Theme} from '@mui/material/styles';
import {withStyles } from '@mui/styles';
import LoginMain from '../login';
import Message from '../messages/Message';

const styles = (theme:Theme):any => ({});
  
class LoginPage extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);

    this.state = {
      loading: false,
      count: 0,
      open: false,
      status: false,
      type: 'info',
      title: 'Notification',
      message: 'Notification'
    };
  }

  onMessage = (type:any,title:any,message:any) => {
    this.setState({open:true,type,title,message});
  }

  onClose = (event:any,reason:any) => {
    this.setState({open:false});
  }

  onCancel = () => {
    this.setState({loading:false});
  }

  render() {
    const {home,apptheme,logincontext,classes} = this.props;
    const {open,type,title,message} = this.state;

    return (
      <Fragment>
      <LoginMain home={home} apptheme={apptheme} logincontext={logincontext} onMessage={this.onMessage}/>
      <Message 
        open={open} 
        type={type}
        title={title}
        message={message}
        onClose={this.onClose}
      />
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LoginPage);
