const bcs: any = {
  account: {
    sv: "account",
    lb: "Account",
    nm: "aci",
    vf: "rid",
    df: "nam",
    rf: "acn",
  },
  accounttype: {
    sv: "accounttype",
    lb: "Account Type",
    nm: "ati",
    vf: "rid",
    df: "nam",
    rf: "atn",
  },
  //agenttype: {sv:'agenttype',lb:'Agent Type',nm:'ati',vf:'rid',df:'nam',rf:'atn'},
  channel: {
    sv: "channel",
    lb: "API Channel",
    nm: "chi",
    vf: "rid",
    df: "nam",
    rf: "chn",
  },
  api: { sv: "api", lb: "API", nm: "api", vf: "rid", df: "nam", rf: "apn" },
  apitype: {
    sv: "apitype",
    lb: "API Type",
    nm: "api",
    vf: "rid",
    df: "nam",
    rf: "apn",
  },
  context: {
    sv: "context",
    lb: "Context",
    nm: "ctx",
    vf: "rid",
    df: "nam",
    rf: "ctx",
  },
  entity: {
    sv: "entity",
    lb: "Entity",
    nm: "eid",
    vf: "rid",
    df: "nam",
    rf: "enm",
  },
  entitytype: {
    sv: "entitytype",
    lb: "EntityType",
    nm: "eti",
    vf: "rid",
    df: "nam",
    rf: "ety",
  },
  gender: {
    sv: "gender",
    lb: "Gender",
    nm: "gdi",
    vf: "rid",
    df: "nam",
    rf: "gdn",
  },
  loginstatus: {
    sv: "loginstatus",
    lb: "Login Status",
    nm: "lst",
    vf: "rid",
    df: "nam",
    rf: "lst",
  },
  loglevel: {
    sv: "loglevel",
    lb: "Log Level",
    nm: "lvi",
    vf: "rid",
    df: "nam",
    rf: "lvn",
  },
  role: { sv: "role", lb: "Role", nm: "roi", vf: "rid", df: "nam", rf: "rnm" },
  user: { sv: "user", lb: "User", nm: "usi", vf: "rid", df: "nam", rf: "usr" },

  client: {
    sv: "client",
    lb: "Cleint",
    nm: "lci",
    vf: "rid",
    df: "nam",
    rf: "cln",
  },
  configuration: {
    sv: "configuration",
    lb: "Configuration",
    nm: "cfi",
    vf: "rid",
    df: "nam",
    rf: "cfn",
  },
  country: {
    sv: "country",
    lb: "Country",
    nm: "coi",
    vf: "rid",
    df: "nam",
    rf: "coy",
  },
  currency: {
    sv: "currency",
    lb: "Currency",
    nm: "cui",
    vf: "rid",
    df: "nam",
    rf: "cur",
  },
  //customer:{sv:'customer',lb:'Customer',nm:'cid',vf:'rid',df:'nam',rf:'cnm'},
  dashtype: {
    sv: "dashtype",
    lb: "Dashtype",
    nm: "dti",
    vf: "rid",
    df: "nam",
    rf: "dtn",
  },
  district: {
    sv: "district",
    lb: "District",
    nm: "dsi",
    vf: "rid",
    df: "nam",
    rf: "dsn",
  },
  fund: { sv: "fund", lb: "Fund", nm: "fui", vf: "rid", df: "nam", rf: "fun" },
  idtype: {
    sv: "idtype",
    lb: "ID Type",
    nm: "iti",
    vf: "rid",
    df: "nam",
    rf: "itn",
  },
  location: {
    sv: "location",
    lb: "Location",
    nm: "lci",
    vf: "rid",
    df: "nam",
    rf: "lcn",
  },
  providertype: {
    sv: "providertype",
    lb: "Providertype",
    nm: "pti",
    vf: "rid",
    df: "nam",
    rf: "ptn",
  },
  region: {
    sv: "region",
    lb: "Region",
    nm: "rgi",
    vf: "rid",
    df: "nam",
    rf: "rgn",
  },
  report: {
    sv: "report",
    lb: "Report",
    nm: "rpi",
    vf: "rid",
    df: "nam",
    rf: "rpn",
  },
  reportstatus: {
    sv: "reportstatus",
    lb: "Report Status",
    nm: "rsi",
    vf: "rid",
    df: "nam",
    rf: "rsn",
  },
  requeststatus: {
    sv: "requeststatus",
    lb: "Request Status",
    nm: "rsi",
    vf: "rid",
    df: "nam",
    rf: "rsn",
  },

  service: {
    sv: "service",
    lb: "Service",
    nm: "svi",
    vf: "rid",
    df: "nam",
    rf: "svn",
  },
  servicemode: {
    sv: "servicemode",
    lb: "Service Mode",
    nm: "smi",
    vf: "rid",
    df: "nam",
    rf: "smn",
  },
  servicetype: {
    sv: "servicetype",
    lb: "Servicetype",
    nm: "sti",
    vf: "rid",
    df: "nam",
    rf: "stn",
  },
  settlementstatus: {
    sv: "settlementstatus",
    lb: "Settlementstatus",
    nm: "rpi",
    vf: "rid",
    df: "nam",
    rf: "rpn",
  },
  transactionstatus: {
    sv: "transactionstatus",
    lb: "Transactionstatus",
    nm: "xsi",
    vf: "rid",
    df: "nam",
    rf: "xsn",
  },
  transactiontype: {
    sv: "report",
    lb: "Transactiontype",
    nm: "xti",
    vf: "rid",
    df: "nam",
    rf: "xtn",
  },

  uidoc: {
    sv: "uidoc",
    lb: "UI Document",
    nm: "dci",
    vf: "rid",
    df: "nam",
    rf: "dcn",
  },
  uipage: {
    sv: "uipage",
    lb: "UI Page",
    nm: "pgi",
    vf: "rid",
    df: "nam",
    rf: "pgn",
  },
  uisection: {
    sv: "uisection",
    lb: "UI Section",
    nm: "sci",
    vf: "rid",
    df: "nam",
    rf: "scn",
  },
  uidevice: {
    sv: "uidevice",
    lb: "UI Device",
    nm: "dvi",
    vf: "rid",
    df: "nam",
    rf: "dvn",
  },
  uilang: {
    sv: "uilang",
    lb: "UI Language",
    nm: "lgi",
    vf: "rid",
    df: "nam",
    rf: "lgn",
  },
};

const servicelist: any = [
  ["idv", "ID Verification"],
  ["idv-poa", "ID+POA Verification"],
  ["facematch", "Liveness+Facematch"],
  ["facematch-id", "Liveness+Facematch+ID"],
  ["facematch-id-poa", "Liveness+Facematch+ID+POA"],
  ["videomatch", "Videomatch"],
  ["photomatch", "Photomatch"],
  ["regmatch", "Regmatch"],
];

const lcs: any = {
  branch: {
    lb: "Branch",
    nm: "bri",
    vf: "rid",
    df: "nam",
    et: "Select",
    sd: [["0", "Default"]],
  },
  context: {
    lb: "Service",
    nm: "ctx",
    vf: "rid",
    df: "nam",
    et: "Select",
    sd: [
      [1, "System"],
      [2, "Admission"],
      [4, "Registration"],
      [8, "Examination"],
      [16, "Finance"],
    ],
  },
  credit: {
    lb: "Credit",
    nm: "cre",
    vf: "rid",
    df: "nam",
    et: "Select",
    sd: [
      [1, "1 HR"],
      [2, "2 HRS"],
      [3, "3 HRS"],
      [4, "4 HRS"],
      [5, "5 HR"],
      [6, "6 HRS"],
      [7, "7 HRS"],
      [8, "8 HRS"],
    ],
  },
  country: {
    lb: "Country",
    nm: "coi",
    vf: "rid",
    df: "nam",
    et: "Select",
    sd: [
      ["1", "Ghana"],
      ["2", "Nigeria"],
      ["3", "Cote D'Ivoire"],
    ],
  },
  format: {
    lb: "Format",
    nm: "fmt",
    vf: "rid",
    df: "nam",
    et: "Select",
    sd: [
      ["pdf", "Portable Document(PDF)"],
      ["csv", "Comma Separated(CSV)"],
    ],
  },
  gender: {
    lb: "Gender",
    nm: "gdi",
    vf: "rid",
    df: "nam",
    et: "Select",
    sd: [
      [1, "Male"],
      [0, "Female"],
    ],
  },
  regstatus: {
    lb: "Registration",
    nm: "rst",
    vf: "rid",
    df: "nam",
    et: "Select",
    sd: [
      [1, "Registered"],
      [0, "Unregistered"],
    ],
  },
  reporttype: {
    lb: "Report Type",
    nm: "rpt",
    vf: "rid",
    df: "nam",
    et: "Select",
    sd: [
      ["sum", "Summary"],
      ["det", "Detail"],
    ],
  },
  service: {
    lb: "Service",
    nm: "svc",
    vf: "rid",
    df: "nam",
    et: "Select",
    sd: servicelist,
  },
  status: {
    lb: "Status",
    nm: "sts",
    vf: "rid",
    df: "nam",
    et: "Select",
    sd: [
      [1, "Enabled"],
      [0, "Disabled"],
      [2, "Locked"],
    ],
  },
  title: {
    lb: "Title",
    nm: "tti",
    vf: "rid",
    df: "nam",
    et: "Select",
    sd: [
      [3, "Dr."],
      [2, "Mrs."],
      [1, "Mr."],
      [0, "Miss"],
    ],
  },
};

function basic(service: string) {
  return bcs[service];
}

function local(service: string) {
  return lcs[service];
}

function combo(service: string) {
  return bcs[service] || lcs[service];
}

function combox(type: string, service: string) {
  return { bcs, lcs }[type][service];
}

export { bcs, lcs, basic, local, combo };
export default combox;
