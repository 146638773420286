import {saveAs} from 'file-saver';
import {getFormConfig,processResponse,responseBlob,basesubmit} from './helpers';

const jsonpost = async(url:string,json:any) => {
  try{
    const config:any = {
      method:'POST',
      credentials:'include',
      body:JSON.stringify(json), //{s:'security',a:'basicdata'}
      headers: new Headers({
        'Content-Type': 'application/json' //TODO: Accept application/json
      })
    };
    //console.log('using fetch with url',url,', and config',config);
    const resp = await fetch(url,config);
    const response = await resp.json();
    const {error,data} = processResponse(response);
    if(error) return Promise.reject(data);
    else return data;
  }
  catch(err){
    return Promise.reject(err);
  }
}

const bizpost = async(json:any) => jsonpost('/biz',json);

////
const bizfile = async (url:string,json:any,filename:string,type:any,ext:any) => {
  try{
    const config:any = {
      method:'POST',
      credentials:'include',
      body:JSON.stringify(json), //{s:'security',a:'basicdata'}
      headers: new Headers({
        'Content-Type': 'application/json', //TODO: Accept application/*
      })
    };
    const resp = await fetch(url,config);
    console.log('bizfile.status: ',resp.status);
    console.log('bizfile.statusText: ',resp.statusText);
    console.log('bizfile.ok: ',resp.ok);
    console.log('bizfile.headers: ',JSON.stringify(resp.headers));
    if(!resp.ok){
      //const {success,message} = resp.json();
      const err:any = await resp.text();
      console.log('bizfile.resp.error:',err);
      return Promise.reject(err.message||err||'request failed...');
    }
    const blob = await resp.blob();
    console.log('url: ',url);
    console.log('filename: ',filename);
    console.log('type: ',type);
    console.log('ext: ',ext);
    const {error,success} = responseBlob(blob,ext,type,filename);
    if(error) return Promise.reject(error);
    return success;
  }
  catch(error){
    console.log('bizfile.error: ',error);
    return Promise.reject(error);
  }
}

const bizpdf = async (url:string,json:any,filename:string) => {
  try{
    return bizfile(url,json,filename,'pdf','application/pdf');
  }
  catch(err){
    return Promise.reject(err);
  }
}

const bizzip = async (url:string,json:any,filename:string) =>{
  try{
    return bizfile(url,json,filename,'zip','application/zip');
  }
  catch(err){
    return Promise.reject(err);
  }
}

const bizdownload = async (meta:any,url:string,json:any) => {
  try{
    const ext = meta.name.split('.').pop();
    return await bizfile(url,json,meta.name,ext,meta.type);
  }
  catch(err){
    return Promise.reject(err);
  }
}

const getpdf = async (params:any,name:string,data:any,record:any) =>{
  try{
    //var refid = record.rfi;
    //var trackid = record.tri;
    const docid = '001';
    var filename = name||'middleware_report_'+docid+'.pdf';
    //this.startLoading();
    const response = await bizpdf('/biz/pdf',params,filename);
    var blob = new Blob([response], {type: "application/pdf"}); //new Blob([JSON.stringify(recordset)], {type: "application/json;charset=utf-8"});
    saveAs(blob, filename);
  }
  catch(error){
    console.log('pdferror',error);
    return Promise.reject(error);
  }
}

const getcsv = async (params:any,name:string,data:any,record:any) =>{
  try{
    //var refid = record.rfi;
    //var trackid = record.tri;
    const docid = '001';
    var filename = name||'middleware_report_'+docid+'.csv';
    //this.startLoading();
    const response = await bizpdf('/biz/pdf',params,filename);
    var blob = new Blob([response], {type: "application/csv"}); //new Blob([JSON.stringify(recordset)], {type: "application/json;charset=utf-8"});
    saveAs(blob, filename);
  }
  catch(error){
    console.log('pdferror',error);
    return Promise.reject(error);
  }
}

const downloadFile = async (mode:string,params:any,name:string,data:any,record:any) =>{
  try{
    //var refid = record.rfi;
    //var trackid = record.tri;
    //const docid = '001';
    var filename = `middleware_report_${name}.${mode}`;
    //var ctypes = Object.assign({name:filename,[mode]:`application/${mode}`},{txt:'',json:}
    var path = `/biz/print/${mode}`;
    var meta = {
      name: filename,
      ctype: `application/${mode}`,
      type: `application/${mode}`
    }
    //{refid:refid,trackid:trackid}
    //this.startLoading();
    return await bizdownload(meta,path,params);
  }
  catch(error){
    console.log("Print Error ",error);
    return Promise.reject(error);
  }
}


////

const formsubmit = async(url:string,form:any,bp:any) => {
  const fn = (fd:any) => {
    for(let key in bp){
      fd.append(key,bp[key]);
    }
    return fd;
  }

  try{
    const config = getFormConfig();
    return await basesubmit(url,form,config,fn);
  }
  catch(err){
    const error:any  = err || {};
    console.log('formsubmit error:'+error.message);
    var rtn = {failure:true,title:error.title,type:error.type,mesg:error.message,data:null};
    return Promise.reject(rtn);
  }
}

const filesubmit = async(url:string,form:any) => {
  try{
    const config = getFormConfig();
    return await basesubmit(url,form,config);
  }
  catch(err){
    const error:any  = err || {};
    console.log('formsubmit error:'+error.message);
    var rtn = {failure:true,title:error.title,type:error.type,mesg:error.message,data:null};
    return Promise.reject(rtn);
  }
}

const datasubmit = async(url:string,json:any) => {
  try{
    const config:any = {
      method:'POST',
      credentials:'include',
      body:JSON.stringify(json), //{s:'security',a:'basicdata'}
      headers: new Headers({
        'Content-Type': 'application/json', //TODO: Accept application/json
        'Accept': 'application/json'
      })
    };
    const resp = await fetch(url,config);
    const response = await resp.json();
    const {error,data} = processResponse(response);
    if(error) return Promise.reject(data);
    else return data;
  }
  catch(err){
    return Promise.reject(err);
  }
}

const bizrequest = {jsonpost,bizpost,bizfile,bizpdf,bizzip,bizdownload,downloadFile,formsubmit,filesubmit,datasubmit};
export {jsonpost,bizpost,bizfile,bizpdf,bizzip,bizdownload,downloadFile,formsubmit,filesubmit,datasubmit};
export default bizrequest;
