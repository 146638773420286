import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import {grey, orange, cyan, pink, purple,blue,green,brown,red,teal} from '@mui/material/colors';
import {Tooltip, PieChart, Pie, Cell, ResponsiveContainer, Legend} from 'recharts';
import ExpandMore from '@mui/icons-material/ExpandMore';

const styles = (theme:Theme):any => ({
  paper: {
    backgroundColor: 'white',
    minHeight: 400,
    //overflow: 'scroll',
  },
  div: {
    height: 95,
    padding: '5px 15px 0 15px'
  },
  header: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    color: 'white',
    backgroundColor: orange['600'],
    padding: 10,
  },
  subheader: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    backgroundColor: orange[600],
    color: 'white'
  },
  legend: {
    paddingTop: 20,
  },
  pieChartDiv: {
    height: 290,
    textAlign: 'center'
  },
  clear: {
    clear: 'both'
  },
  customtooltip:{
    backgroundColor: 'white',
    padding: 5
       },
  nodata: {
    display: 'flex',
    justifyContent: 'center',
  }
});

class Feature extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      count:0,
      mode:'tot',//'amt',
    };
  }

  objformat = (data:any) => {
    const conf:any = {
      'PUSH': { id: 'PUSH', name: 'PUSH', value: 0, color: cyan[600], icon: <ExpandMore color={'inherit'} /> },
      'PULL': { id: 'PULL', name: 'PULL', value: 0, color: grey[600], icon: <ExpandMore color={'inherit'} /> },
      // 'APEXDIRECTOP': { id: 'APEXDIRECTOP', name: 'APEXDIRECT OP', value: 0, color: orange[600], icon: <ExpandMore color={'inherit'} /> },
      // 'APEXDIRECTOPREV': {id:'APEXDIRECTOPREV', name: 'APEXDIRECT OP REV', value: 0, color: cyan[600], icon: <ExpandMore color={'inherit'}/>},
      // 'APEXLINKRCB': {id:'APEXLINKRCB', name: 'VODACASH', value: 0, color: grey[600], icon: <ExpandMore color={'inherit'}/>},
      // 'APEXDIRECTRCB': {id:'APEXDIRECTRCB', name: 'MTNMOMO', value: 0, color: orange[600], icon: <ExpandMore color={'inherit'}/>},
    };
    let rtn:any = [];
    const {mode} = this.state;
    data.forEach((item:any)=>{
      if(conf[item.amc]) {
        var rec = conf[item.amc];
        rec.value = item[mode];
        rtn.push(rec);
      }
    });
    return rtn;
  }

  format = (data:any) => {
    const conf:any = {
      'USER': { id: 'USER', name: 'USER', value: 0, color: cyan[600], icon: <ExpandMore color={'inherit'} /> },
      'CUSTOMER': { id: 'CUSTOMER', name: 'CUSTOMER', value: 0, color: grey[600], icon: <ExpandMore color={'inherit'} /> },
      // 'APEXDIRECTOP': { id: 'APEXDIRECTOP', name: 'APEXDIRECT OP', value: 0, color: orange[600], icon: <ExpandMore color={'inherit'} /> },
      // 'APEXDIRECTOPREV': {id:'APEXDIRECTOPREV', name: 'APEXDIRECT OP REV', value: 0, color: cyan[600], icon: <ExpandMore color={'inherit'}/>},
      // 'APEXLINKRCB': {id:'APEXLINKRCB', name: 'VODACASH', value: 0, color: grey[600], icon: <ExpandMore color={'inherit'}/>},
      // 'APEXDIRECTRCB': {id:'APEXDIRECTRCB', name: 'MTNMOMO', value: 0, color: orange[600], icon: <ExpandMore color={'inherit'}/>},
    };
    let rtn:any = [];
    const {mode} = this.state;
    data.reduce((acc:[],item:any)=>{
      if(conf[item.amc]) {
        var rec = conf[item.amc];
        rec.value = item[mode];
        rtn.push(rec);
      }
      return acc;
    },[]);
    return rtn;
  }

  getMode(data:any){
    const {meta,mode:modedata} = data;
    const modes = meta.filter((val:any,idx:number,arr:any[])=> val.group==='mode');
    const rtn = modes.reduce((acc:any[],rec:any,idx:number,arr:any) => {
      rec.value = modedata[rec.code];
      acc.push(rec);
      return acc;
    },[]);
    return rtn;
  }

  getChannel(data:any){
    const {meta,channel:chandata} = data;
    const chans = meta.filter((val:any,idx:number,arr:any[])=> val.group==='mode');
    const rtn = chans.reduce((acc:any[],rec:any,idx:number,arr:any) => {
      rec.value = chandata[rec.code];
      acc.push(rec);
      return acc;
    },[]);
    return rtn;
  }

  customTooltip = ({active,payload}:any) => {
    const {classes} = this.props
      if(active){
        return(
          <div className={classes.customtooltip}>
          <p className={classes.revenue}>{`${payload[0].name} : ${payload[0].value}`}</p>
          </div>
        )
      }
      return null;
     }

  setAnchor = (cx:number) => (x:number):string => x > cx ? "start" : "end";

  formatLabel = (data:any,cx:number,cy:number,midAngle:number,innerRadius:number,outerRadius:number,value:number,index:number) => {
    console.log("handling label?");
    const RADIAN = Math.PI / 180;
    // eslint-disable-next-line
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    // eslint-disable-next-line
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    // eslint-disable-next-line
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="#8884d8" textAnchor={"start"}
        dominantBaseline="central"
      >
        {data[index].name} ({value})
      </text>
    );
  }

  render() {
    const {data,classes} = this.props;
    //const {mode} = this.state;
    const usage = this.format(data);

    return (
      <Paper className={classes.paper}>
        <div className={classes.header}>Meter Distribution</div>
        <div className={classes.div}>
            <div className={classes.pieChartDiv}>
              <ResponsiveContainer >
                {data.length>0?
                <PieChart >
                  <Pie
                    innerRadius={50}
                    outerRadius={100}
                    data={usage}
                    dataKey='value'
                    fill="#8884d8"
                    label>
                    {usage.map((item:any) => <Cell key={item.recid} fill={item.color}/>)}
                  </Pie>
                  <Tooltip content={<this.customTooltip />}/>
                  <Legend />
                </PieChart>
                : <div className={classes.nodata}>No data to show</div>}
              </ResponsiveContainer>
            </div>
        </div>
      </Paper>
    );
  }
}

Feature.propTypes = {
  data: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(Feature);
