import React,{Component} from 'react';
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import HeaderCell from './GridHeaderCell';
import { extendedstyles } from '../../app/appstyles';

const extrastyles = (theme:Theme):any => ({
});

const styles = extendedstyles(extrastyles);

class GridMSMHeader extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      mode:0
    };
  }

  createSortHandler = (property:any) => (event:any) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    //const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;
    const { rows, onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          <HeaderCell padding="checkbox">
            <Checkbox 
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </HeaderCell>
          {rows.map((row:any) => {
            return (
              <HeaderCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </HeaderCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

GridMSMHeader.propTypes = {
  rows: PropTypes.array.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


export default withStyles(styles)(GridMSMHeader);