import React,{ Component } from "react";
import PropTypes from 'prop-types';
//material-ui design helpers
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
//material-ui core components
import { Typography } from "@mui/material";
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const styles = (theme:Theme):any => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

class DialogClosableTitle extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;

  render(){
    const { children, classes, onClose } = this.props;
    return(
      <DialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
    )
  }
}

DialogClosableTitle.propTypes = {
  onClose: PropTypes.func,
}

//DialogClosableTitle = withStyles(styles, { withTheme: true })(DialogClosableTitle);
export default withStyles(styles, { withTheme: true })(DialogClosableTitle);