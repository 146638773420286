import React, { Component } from "react";
import PropTypes from "prop-types";
import { Theme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
// import CardActionArea from '@mui/material/CardActionArea';
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
// import CardMedia from '@mui/material/CardMedia';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Toolbar from "@mui/material/Toolbar";
import Validator from "./Validator";
//import UIContent from '../core/uicontent';

const styles = (theme: Theme): any => ({
  card: {
    // maxWidth: 900,
    // minHeight: 600,
    //maxHeight: 500,
    //width: '100%',
    //height: '100%',
    margin: 0, //'auto',
    marginTop: 5,
    paddingTop: 5,
    display: "flex",
    flexDirection: "column",
  },
  toolbar: {
    margin: 0,
    marginLeft: 20,
    marginRight: 20,
    padding: 0,
    minHeight: 10,
    justifyContent: "space-between",
  },
  cardheader: {
    margin: 0,
    padding: 0,
  },
  cardstepper: {
    margin: 0,
    padding: 0,
  },
  media: {
    height: 10, //28,
    marginLeft: "20px auto",
  },
  content: {
    flex: "1 1 auto",
  },
  body: {
    //height: 400, //TODO: get from props.size
    //overflowY: 'scroll',
    minHeight: 400,
    margin: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
    alignSelf: "flex-end",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttons: {
    start: {
      margin: theme.spacing(1),
    },
    cancel: {
      margin: theme.spacing(1),
    },
    prev: {
      margin: theme.spacing(1),
    },
    next: {
      margin: theme.spacing(1),
    },
    done: {
      margin: theme.spacing(1),
    },
    guide: {
      margin: theme.spacing(1),
    },
  },
  hide: {
    display: "none",
  },
  divider: {
    margin: "5px 20px 5px 20px",
  },
});

class BaseCard extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any = { panelMode: true };
  constructor(props: any) {
    super(props);

    this.state = {
      next: true,
      step: 1,
      valid: props.valid || true,
      fwopen: false,
    };
  }

  setValid = (valid: any) => {
    this.setState({ valid });
  };

  handleStart = () => {
    const { name, setFirst } = this.props;
    setFirst(name);
  };

  handlePrev = () => {
    this.props.setPrev(this.props.name);
  };

  handleNext = async () => {
    const { name, setNext, doSubmit } = this.props;
    try {
      if (typeof doSubmit === "function") {
        await doSubmit();
        setNext(name);
      } else setNext(name);
    } catch (error) {
      console.log("BASECARD.error:", error);
    }
  };

  handleSend = async () => {
    const { name, setNext, doSubmit } = this.props;
    try {
      if (typeof doSubmit === "function") {
        await doSubmit();
        setNext(name);
      } else setNext(name);
    } catch (error) {
      console.log("BASECARD.error:", error);
    }
  };

  handleDone = () => {
    const { name, setReset } = this.props;
    setReset(name);
  };

  handleCancel = () => {
    const { name, setReset } = this.props;
    setReset(name);
  };

  handleClick = (action: any) => () => {
    //console.log(action,' triggered');
    const { step } = this.state;
    const steps: any = {
      next: 1,
      prev: -1,
      start: 1 - step,
      send: 1,
      done: 1,
      cancel: -step,
    };
    //const acts:any = {next:'handleNext',prev:'handlePrev',start:'handleNext',send:'handleSend',done:'handleDone',cancel:'handleCancel'};
    //this[acts[action]]();
    const acts: any = {
      next: this.handleNext,
      prev: this.handlePrev,
      start: this.handleNext,
      send: this.handleSend,
      done: this.handleDone,
      cancel: this.handleCancel,
    };
    acts[action]();
    let newstep = step + steps[action];
    //console.log('newstep ',newstep);
    this.setState({ step: newstep });
  };

  addExtraProps = (props: any) => {
    const children = React.Children.map(this.props.children, (child: any) => {
      if (this.props.panelMode || child.type === Validator) {
        //console.log('childType = ',child.type,Validator);
        return React.cloneElement(child, props);
      } else {
        return child;
      }
    });
    return children;
  };

  render = () => {
    const { valid: svalid } = this.state;
    // const {routes,uic,logo, title, children, classes, theme } = this.props;
    const {
      valid: pvalid,
      routes,
      cardtitle,
      cardname,
      instruction,
      step,
      buttons,
      classes,
    } = this.props;
    //TODO: if buttons is object, map keys as btn.id and values as btn.text else if array use as btn.id
    const buttoncolors: any = {
      START: "primary",
      CANCEL: "warning",
      PREV: "secondary",
      NEXT: "primary",
      SEND: "primary",
      DONE: "primary",
      GUIDE: "info",
    };
    const steps = routes; //uic.list ||

    const valid = svalid && pvalid;

    console.log("buttons:", buttons, svalid, pvalid, valid);

    const children = this.addExtraProps({ setValid: this.setValid });
    return (
      <Card className={classes.card}>
        <Toolbar className={classes.toolbar}>
          <CardHeader
            className={classes.cardheader}
            title={cardtitle || cardname}
          />
          <Stepper className={classes.cardstepper} activeStep={step}>
            {steps.map((item: any, i: number) => (
              <Step key={i}>
                <StepLabel></StepLabel>
              </Step>
            ))}
          </Stepper>
        </Toolbar>
        {/* <CardMedia
            className={classes.media}
            image={logo}
            title={title}
          /> */}
        <Divider className={classes.divider} />
        <CardContent className={classes.content}>
          <Typography component='p'>{instruction}</Typography>
          <div
            className={classNames(classes.body /*, {[classes.hide]:!BODY}*/)}
          >
            {children}
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          {buttons.map((v: any, i: number) => (
            <Button
              key={`act-${i}`}
              disabled={!valid && v === "NEXT"}
              //disabled={(!this.props.valid||!valid)&&v==='NEXT'}
              className={classes.buttons[v.toLowerCase()]}
              //color={buttoncolors[v]||'default'}
              color={buttoncolors[v]}
              //color={buttoncolors['default']}
              onClick={this.handleClick(v.toLowerCase())}
            >
              {v}
            </Button>
          ))}
        </CardActions>
      </Card>
    );
  };
}

BaseCard.propTypes = {
  classes: PropTypes.object.isRequired,
  cardname: PropTypes.string.isRequired,
  cardid: PropTypes.string.isRequired,
};

export default withStyles(styles)(BaseCard);
