//react
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//material-ui design helpers
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import {LoadingDialog} from '../dialogs';
//siblings
import styles from '../../app/appstyles';
import {createItemList} from '../form';

/**
 * -------------------------------------------------------------------
 * Service Panel
 * -------------------------------------------------------------------
 */
class ColumnPanel extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      mode: this.props.mode,
      init: false,
      loading: false,
      fopen: false,
    };
  }

  handleChange = (name:any) => (event:any) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }

  mkColumns = (items:any,columns:any) => {
    let fields:any = {};
    items.map((item:any) => {
      let id = columns[item.id] || 1;
      let key = `col${id}`;
      if(!fields[key]) fields[key] = [];
      fields[key].push(item); 
      return true;
    });
    return fields;
  }
  
  render = () => {
    const {sconfig,mode,sdata,record,formatters,onChange,classes, selected, reset, cvalue, cuparams,...others} = this.props;
    const {loading} = this.state;
    const extras = {selected, reset, cvalue, cuparams }
    //console.log('column.others',others);
    
    const eform = sconfig.service.eform;
    const columns = eform.reduce((acc:any,v:any)=>{
      acc[v.id]=v.col;
      return acc;
    },{});
    
    //const formitems = generateItemList(mode,sdata,record,formatters,onChange);
    const formitems = createItemList(mode, sdata, record, formatters, onChange, extras);
    const fields = this.mkColumns(formitems,columns);
    
    return (
      <div className={classes.container}>
        <form id="requestform" className={classes.columnform}>
          { loading &&
            <LoadingDialog status={loading} start={this.startLoading} stop={this.stopLoading} />
          }
          {
            Object.keys(fields).map(key => (
              <div key={key} className={classes.columnfieldset}>
                {fields[key].map((item:any)=>(<div key={item.id}>{item.dom}</div>))}
              </div>
            ))
          }
        </form>
      </div>
    );
  }
};

ColumnPanel.propTypes = {
  service: PropTypes.string.isRequired,
  sconfig: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(['add','edit','view','manage','wizard']).isRequired,
  sdata: PropTypes.object,
  record: PropTypes.object,
  onChange: PropTypes.func,
  formatters: PropTypes.object,
  layout: PropTypes.object,
};

//ColumnPanel = withStyles(styles, { withTheme: true })(ColumnPanel);

export default withStyles(styles, { withTheme: true })(ColumnPanel);
