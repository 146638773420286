import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import List from '@mui/icons-material/List';
import Done from '@mui/icons-material/CheckCircleOutline';
import Cancel from '@mui/icons-material/HighlightOff';

import { formatDate,formatStatus } from '../renderers';
import GridMaskList from '../grid/GridMaskList';
import {bizpost} from '../../ajax';
import { extendedstyles } from '../../app/appstyles';

const extrastyles = (theme:Theme):any => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    marginBottom: 30,
    marginRight: -30,
  },
  title: {
    backgroundColor: theme.palette.grey['300'],
  },
  valueChip: {
    position: 'absolute',
    right: theme.spacing(12),
  },
  cancelButton: {
    position: 'absolute',
    right: theme.spacing(6),
    top: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  hide:{
    display:'none',
  },
});

const styles = extendedstyles(extrastyles);

class FMaskListField extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    const {options,value} = props;
    const {conf} = options||{};
    const {recField='rid',keyField='aky',accessField='acl'} = conf||{};
    
    let mask = props.value && +value; //1 << bit;
    this.state = {
      recField,
      keyField,
      accessField,
      next: true,
      data: props.data||[],
      open: false,
      svalue: null,
      value,
      mask,
    };
  }

  componentDidMount(){
    const {value,options} = this.props;
    if(!(value>=0)) return;
    const {params} = options;
    this.refresh(params);
  }

  refresh = async(params:any) => {
    try{
      this.startLoading();
      const response = await bizpost(params);
      const data = response.sd;
      this.setState({data,recordCount:+response.rc});
    }
    catch(error){
      this.setState({data:[],recordCount:0});
      const err:any = typeof error === 'object'?error : {};
      if(this.props.showNotification)
        this.props.showNotification(err.message||"request failed",err.type||'warn',err.title||'Loading Error'); 
    }
    finally{
      if (this.state.loading) this.stopLoading();
    }
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }

  setBit = (val:number,bit:number) => {
    return (val |= (1 << bit));
  }

  unsetBit = (val:number,bit:number) => {
    return (val &= ~(1 << bit));
  }

  toggleBit = (val:number,bit:number) => {
    return (val ^= (1 << bit));
  }

  setValid = (data:any) => {
    const valid = (!!data.ati);
    this.props.setValid(valid);
  }

  onChange = (name:any) => (value:any,m:any) => {
    const {data} = this.props;
    const newdata = {...data.data,[name]:value}; //newval
    this.setValid(newdata);
    this.props.setItem('data','data',newdata);
  }

  onSwitch = (rec:any) => (event:any) => {
      if(event.target){
        const {mask} = this.state;
        const {keyField} = this.state;
        const bit = +rec[keyField];
        const newmask = this.toggleBit(mask,bit);
        this.setState({mask:newmask});
      }
      else console.log('event ',event);
  }

  onOpen = (event?:any) => {
    this.setState({open:true});
  }

  onClose = (event?:any) => {
    this.setState({open:false});
  }

  onCancel = (event?:any) => {
    this.setState({open:false});
  }

  onUpdate = (event?:any) => {
    const {mask} = this.state;
    this.setState({open:false,value:mask});
    const {value,onChange} = this.props;
    if(typeof onChange === 'function' && +value !== mask) onChange(mask);
  }

  onFieldChange = (event?:any) => {
    const {onChange} = this.props;
    let value = event.target.value;
    this.setState({value});
    if(typeof onChange === 'function') onChange(value);
  };

  onMouseDown = (event:any) => {
    event.preventDefault();
  };

  defaultGridCfg = () => {
    const {recordCount} = this.state;
    const pages = (recordCount>5)?10:5;
    const [page,rowsPerPage,order,orderBy] = [0,pages,'asc','rid'];
    const [recField,keyField,accessField] = ['rid','aky','acl'];
    const grid = [['nam','Name'],['sts','Status']];
    const rownum = true; //numbered list
    var init = rownum?[{id:'id',numeric:false,disablePadding:true,label:'No'}]:[];
    const cols = grid.reduce((ac,va)=>{
      ac.push({id:va[0],numeric:false,disablePadding:true,label:va[1]});
      return ac;
    },init);
    const formatters = {sts:formatStatus};
    const filter = (cols:any) => cols;

    return {recField,keyField,accessField,cols,rowsPerPage,page,order,orderBy,formatters,filter};
  }

  render() {
    const {id,label,divider,classes,...others } = this.props;
    const {value,mask,data,open} = this.state;
    const {recField,keyField,accessField} = this.state;
    const {options} = this.props;
    const {gridcfg} = options;

    const defconf = this.defaultGridCfg();
    const optconf = (typeof gridcfg === 'object')?gridcfg:{recField,keyField,accessField};
    const gridconf = {...defconf,...optconf}
    const {cols,rowsPerPage,page,order,orderBy,formatters,filter} = gridconf;

    const bin = mask>=0?(mask >>> 0).toString(2):`${mask} invalid`;

    return (
      <div className={classes.root}>
        <Dialog onClose={this.onClose} aria-labelledby="simple-dialog-title" open={open}>
          <DialogTitle id="simple-dialog-title" className={classes.title}>
            {label}
            <Chip variant="outlined" color="primary" size="small" className={classes.valueChip} label={bin}/>
            <IconButton color={"secondary"} aria-label="Cancel" className={classes.cancelButton} onClick={this.onCancel}><Cancel/></IconButton>
            <IconButton color={"primary"} aria-label="Close" className={classes.closeButton} onClick={this.onUpdate}><Done/></IconButton>
          </DialogTitle>
          <GridMaskList 
            recField={recField}
            keyField={keyField}
            mask={mask}
            cols={cols}
            recordset={data}
            rowsPerPage={rowsPerPage}
            page={page}
            order={order}
            orderBy={orderBy}
            formatters={formatters}
            filterColumns={filter}
            onSwitch={this.onSwitch}
            {...others}
          />
        </Dialog>
        <TextField
            label={label}
            id={id}
            name={id}
            type={'text'}
            margin='dense'
            fullWidth={true}
            className={classes.textField}
            value={value}
            onChange={this.onFieldChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="open access control dialog"
                    onClick={this.onOpen}
                    onMouseDown={this.onMouseDown}
                    edge="end"
                    className={classes.button}
                  >
                    <List color="secondary"/>
                  </IconButton>
              </InputAdornment>)
            }}
          />
        {divider && <Divider />}
      </div>
    );
  }
}

FMaskListField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(FMaskListField);
