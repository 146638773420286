import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

const styles = (theme:Theme) => {
  const cfg:any = {
  master: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
  },
  hide:{
    display:'none',
  },
};
return cfg;
}

class FDisplayField extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      next: true,
      value: this.props.value 
    };
  }

  handleChange = () => {
    // let value = event.target.value;
    // this.props.onChange(value);
    // this.setState({value});
  };

  render() {
    const {id,label,divider,classes } = this.props;

    return (
      <div className={classes.master}>
        <TextField
            label={label}
            id={id}
            name={id}
            type={'text'}
            margin='dense'
            multiline={true}
            minRows={1}
            fullWidth={true}
            className={classes.textField}
            value={this.state.value}
            inputProps={
              { readOnly: true, }
            }
            //onChange={this.handleChange}
          />
        {divider && <Divider />}
      </div>
    );
  }
}

FDisplayField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(FDisplayField);
