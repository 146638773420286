import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
//import { extendedstyles } from '../../app/styles';
//const extrastyles = (theme:Theme) => ({});
//const styles = extendedstyles(extrastyles);

export class Validator extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props: any) {
    super(props);
    //this.props.deb.log("Validator");
    this.state = {};
  }

  componentDidUpdate = (prevProps: any) => {
    const { wvalid: prev } = prevProps;
    const { wvalid: curr, setValid } = this.props;
    if (prev !== curr) {
      if (typeof this.props.setValid === "function") {
        setValid(curr);
      }
    }
    // if (this.props.wvalid !== prevProps.wvalid) {
    //   if(typeof this.props.setValid === 'function'){
    //     this.props.setValid(this.props.wvalid);
    //   }
    // }
  };

  // setValid = (valid:boolean)  => {
  //   this.props.setValid(valid);
  // }

  render = () => {
    //const { setValid,valid  } = this.props;
    //setValid(valid);
    return <Fragment />;
  };
}

Validator.propTypes = {
  valid: PropTypes.bool,
};

export default Validator;

//const InfoCard = withStyles(styles)(Validator);
