import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FileUploadIcon from '@mui/icons-material/CloudUpload';


import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const styles = (theme:Theme):any => ({
  container: {
    display:'flex',
    justifyContent:'flex-start',
    alignItems: 'center'
  },
  preview: {
    width:200,
    height: 40,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: 'none',
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'flex'
  }
});

class FileUpload extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      loading: false,
      open: false,
      source: undefined
    };
  }
  
  // startLoading = () => {
  //   this.setState({loading:true});
  // }

  // stopLoading = () => {
  //   this.setState({loading:false});
  // }
  onMouseDown = (event:any) => {
    event.preventDefault();
  };

  onOpen = (event:any) => {
    const {id:xid} = this.props;
    const input = document.getElementById(xid);
    if(input) input.click();
  }

  onChange = (event:any) => {
    event.persist();
    let that = this;
    //console.log(event.target.name);
    let file:any, fr:any;
    let files:any = event.target.files || event.dataTransfer.files;
    if (files) {
      file = files[0];
      fr = new FileReader();
      fr.onload = function createImage() {
        that.setState({source:fr.result});
        if(that.props.onChange) that.props.onChange(event.target.name)(event,file);
        //console.log('source set, onChange called');
      };
      fr.readAsDataURL(file);
    }
  }

  render() {
    const {id,label,name,required,options,accept,preview,previewStyle,divider,classes } = this.props;
    const {InputProps:inprops={},...ooptions} = options||{};
    const {show,value} = this.state;

    //const {id,label,name,children,accept,preview,previewStyle,divider,classes} = this.props;
    const {source} = this.state;
    var xid = id || 'upfile';
    var xname = name || 'upfile'; 
    var xaccept = accept || "*/*";

    const xinprops = {
      accept:xaccept,
      className:classes.input,
      id:xid,
      name:xname,
      type:"file",
      onChange:this.onChange,
    };

    return (

      <React.Fragment>
        <TextField
            label={label}
            id={id}
            name={id}
            type={'text'}
            required={required}
            margin='dense'
            fullWidth={true}
            className={classes.textField}
            //onChange={this.onChange}
            //onKeyPress={onKeyPress}
            value={value}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <label htmlFor={xid}>
                  
                  <IconButton 
                    color={'primary'}
                    onClick={this.onOpen}
                    //onMouseDown={this.onMouseDown}
                  >
                    <FileUploadIcon color={'inherit'}/>
                    <input
                      style={{ display: 'none' }}
                      accept={xaccept}
                      className={classes.input}
                      id={xid}
                      name={xname}
                      type="file"
                      //onChange={this.onChange}
                    />
                  </IconButton>
                  {/* preview&& */}
                  {<img className={source?classes.preview:classes.hidden} style={previewStyle||{}} src={source} alt=""/>}
                  </label>
                </InputAdornment>
              ),
              //accept:xaccept,
              //...(xinprops)
            }}
            {...ooptions}
          />
        {divider && <Divider />}
      </React.Fragment>

      // <div>
      // <div className={classes.container}>
      //   <input
      //     accept={xaccept}
      //     className={classes.input}
      //     id={xid}
      //     name={xname}
      //     type="file"
      //     onChange={this.onChange}
      //   />
      //   <label htmlFor={xid}>
      //     <Button variant="contained" component="span" className={classes.button}>
      //       <FileUploadIcon color={'inherit'}/>
      //       {children}
      //     </Button>
      //   </label>
      //   {preview&&<img className={source?classes.preview:classes.hidden} style={previewStyle||{}} src={source} alt=""/>}
      // </div>
      // {divider && <Divider />}
      // </div>
    );
  }
}

FileUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  //children: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(FileUpload);
