class Rotation {
  element:any;
  rotatefn:Function;
  rotations:any;
  current:number;

  constructor(element:any, rotatefn:any){
    console.log('initializing rotations');
    this.element = element;
    this.rotatefn = rotatefn;
    this.rotations = [0,90,180,270];
    this.current = 0;
  }

  setRotation(){
    var deg = this.rotations[this.current];
    if(deg){
      this.rotatefn(deg);
    }
  }

  rotate() {
    var box = this.element;
    var current = this.current;
    console.log('current: ',current);
    if(current===0){
      box.style.transition = 'transform 1s linear';
      box.style.transform = 'rotate(90deg)';
      this.current = 1;
      //console.log();
      getComputedStyle(box);
      //console.log(this.current,',box',box);
      this.setRotation();
    }
    else if(current===1){
      box.style.transform = 'rotate(180deg)';
      this.current = 2;
      getComputedStyle(box);
      //console.log(this.current,',box',box);
      this.setRotation();
    }
    else if(current===2){
      box.style.transform = 'rotate(270deg)';
      this.current = 3;
      getComputedStyle(box);
      //console.log(this.current,',box',box);
      this.setRotation();
    }
    else if(current===3){
      box.style.transform = 'rotate(360deg)';
      this.current = 0;
      getComputedStyle(box);
      this.setRotation();
      setTimeout(()=>{
        box.style['-moz-transition'] = 'none !important';
        box.style['-o-transition'] = 'none !important';
        box.style['-webkit-transition'] = 'none !important';
        box.style.transition = 'none';
        box.style.transform = 'rotate(0deg)';
        //console.log(this.current,',box',box);
        console.log('complete');
      },1000);
    } 
  }
}

export default Rotation;
