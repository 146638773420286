import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import {grey, orange, cyan, pink, purple,blue,green,brown,red,teal} from '@mui/material/colors';
import {Tooltip, PieChart, Pie, Cell, ResponsiveContainer, Legend} from 'recharts';
import ExpandMore from '@mui/icons-material/ExpandMore';

const styles = (theme:Theme):any => ({
  paper: {
    backgroundColor: 'white',
    minHeight: 400,
    //overflow: 'scroll',
  },
  div: {
    height: 95,
    padding: '5px 15px 0 15px'
  },
  header: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    color: 'white',
    backgroundColor: orange['600'],
    padding: 10,
  },
  subheader: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    backgroundColor: orange[600],
    color: 'white'
  },
  legend: {
    paddingTop: 20,
  },
  pieChartDiv: {
    height: 290,
    textAlign: 'center'
  },
  clear: {
    clear: 'both'
  },
  customtooltip:{
    backgroundColor: 'white',
    padding: 5
       },
  nodata: {
    display: 'flex',
    justifyContent: 'center',
  }
});

const getcolor = (name:string) => (({grey, orange, cyan, pink, purple,blue,green,brown,red,teal}[name] || orange)['600']);

class Distribution extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      count:0,
      mode:'tot',//'amt',
    };
  }

  customTooltip = ({active,payload}:any) => {
    const {classes} = this.props;
    if(active){
      return(
        <div className={classes.customtooltip}>
        <p className={classes.revenue}>{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      )
    }
    return null;
  }

  setAnchor = (cx:number) => (x:number):string => x > cx ? "start" : "end";

  formatLabel = (data:any,cx:number,cy:number,midAngle:number,innerRadius:number,outerRadius:number,value:number,index:number) => {
    //console.log("handling label?");
    const RADIAN = Math.PI / 180;
    // eslint-disable-next-line
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    // eslint-disable-next-line
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    // eslint-disable-next-line
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="#8884d8" textAnchor={"start"}
        dominantBaseline="central"
      >
        {data[index].name} ({value})
      </text>
    );
  }

  render() {
    const {data,title='Distribution',color,classes} = this.props;
    //const {mode} = this.state;
    //const usage = this.format(data);
    //console.log('data',data);

    return (
      <Paper className={classes.paper}>
        <div className={classes.header} style={{backgroundColor: getcolor(color)}}>{title}</div>
        <div className={classes.div}>
            <div className={classes.pieChartDiv}>
              <ResponsiveContainer >
                {data.length>0?
                <PieChart >
                  <Pie
                    innerRadius={50}
                    outerRadius={100}
                    data={data}
                    nameKey='name'
                    dataKey='value'
                    fill="#8884d8"
                    label>
                    {data.map((item:any) => <Cell key={item.recid} fill={item.color}/>)}
                  </Pie>
                  <Tooltip content={<this.customTooltip />}/>
                  <Legend />
                </PieChart>
                : <div className={classes.nodata}>No data to show</div>}
              </ResponsiveContainer>
            </div>
        </div>
      </Paper>
    );
  }
}

Distribution.propTypes = {
  data: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(Distribution);
