import React,{Component} from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import grey from "@mui/material/colors/grey";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";

const styles = {
  root: {
    color: grey[600],
    "&$checked": {
      color: grey[500]
    }
  },
  checked: {}
};

// usage:
// const steps = ["1hr", "3hrs", "1day", "3days"];
// <FChoice id="exp" label="Expire" values={steps} value="1hr" onChange={this.handleChange}/>,

class FChoice extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  state = {
    cur: this.props.value || this.props.values[0]
  };

  handleChange = (event:any) => {
    // const { id, onChange } = this.props;
    this.setState({ cur: event.target.value });
    // if (onChange) onChange(id)(event.target.value);
  };

  render() {
    const { id, label, value, values, onChange, divider, classes } = this.props;
    const { cur } = this.state;
    if(value) console.log('');

    return (
      <div>
      <FormControl variant="outlined" fullWidth={true}> {/** id={id} **/}
        <FormLabel>
          {label}
        </FormLabel>
        <RadioGroup
          aria-label={label}
          id={id}
          name={id}
          value={cur}
          onChange={onChange || this.handleChange}
          row
        >
          {values.map((item:any, index:number) => (
            <FormControlLabel
              key={index}
              value={item}
              control={
                <Radio classes={{root:classes.root}}/>
              }
              label={item}
              labelPlacement="bottom"
            />
          ))}
        </RadioGroup>
      </FormControl>
      {divider && <Divider />}
      </div>
    );
  }
}

FChoice.propTypes = {
  classes: PropTypes.object.isRequired,
  //values: PropTypes.arrayOf("string").isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(FChoice);
