import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import CardPreview from '../components/CardPreview';
import CardPreview from '../../../components/imaging/CardPreview';
import {BaseCard} from '../../../components/wizard';
import { extendedstyles } from '../../appstyles';

const extrastyles = (theme:Theme) => ({
  card: {
    maxWidth: 345,
    minHeight: 600,
    margin: 'auto',
    paddingTop: 20,
  },
  media: {
    height: 28,
    marginLeft: '20px auto'
  },
  photo: {
    width: '90%',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#28dab6',
    border: '1px solid #28dab6',
    borderRadius: '1',
    color: '#fffff',
    paddingLeft: 20,
    paddingRight: 20,
  },
  second: {
    margin: theme.spacing(1),
    backgroundColor: '#ffffff',
    border: '1px solid #cccccc',
    borderRadius: '1',
    color: '#cccccc',
    paddingLeft: 20,
    paddingRight: 20,
  },
  cancel: {
    margin: theme.spacing(1),
    backgroundColor: '#ffffff',
    borderRadius: '1',
    color: '#cccccc',
  },
  divider: {
    margin: '5px 20px 5px 20px',
  },
});
const styles = extendedstyles(extrastyles);

class ReviewCard extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.props.deb.log("Event");
    //this.ui = this.props.getUI(this.props.uic,'ID','VIEW','ALL');
    this.state = {
      next: true,
      fwopen: false,
      loaded: false,
      idata: null,
      itype: 'jpg',
      rotation: 0,
    };
  }

  componentDidMount() {

    this.getImage()
  }

  getImage = async() => {
    try{
      let url = 'api/store/qclab/photo.jpg';
      const resp = await fetch(url);
      const blob = await resp.blob();
      var fr = new FileReader();
      fr.onload = () => {
        var ires = {url:fr.result,blob};
        var tile = {img:ires.url,title:'Photo', type:'jpg'};
        //ires.url = fr.result;
        //ires.blob = file;
        //that.props.setParam('idata', ires);
        this.setState({idata:ires,error:false,loaded:true,tile});
        
      };
    }
    catch(e){
      this.setState({error:true});
    }
    
  }

  // loadImage = (urls) => {
  //   var x = new XMLHttpRequest();
  //   x.open('GET', '//cors-anywhere.herokuapp.com/http://www.youtube.com/favicon.ico');
  //   x.responseType = 'blob';
  //   x.onload = function () {
  //     var blob = x.response;
  //     var fr = new FileReader();
  //     fr.onloadend = function () {
  //       var dataUrl = fr.result;
  //       // Paint image, as a proof of concept
  //       var img = document.createElement('img');
  //       img.src = dataUrl;
  //       document.body.appendChild(img);
  //     };
  //     fr.readAsDataURL(blob);
  //   };
  //   x.send();
  // }
  
  handleNext = (ev:any)  => {
    this.props.setNext(this.props.name);
  }

  handlePrev = (ev:any)  => {
    this.props.setPrev(this.props.name);
  }

  handleRotation = (value:any)  => {
    //this.props.setItem('idata','frot',value);
    this.setState({rotation:value});
  }

  render = () => {
    const { classes, theme, ...extraProps  } = this.props;  //fmtUI, logo, title, switchFn,
    //const { idata, itype, idfname, logo, title  } = this.props;
    const {loaded,tile,idata,itype} = this.state;
    // const { TITLE, INSTRUCTION,RETAKE,NEXT,CANCEL} = this.ui;
    
    return (
      <div>
        <BaseCard
          cardid={'PROC'}
          cardname={'proc'}
          title={'Processing'}
          instruction={'Process image for card printing'}
          step={2}
          buttons={['START','CANCEL','PREV','NEXT','GUIDE']}
          {...extraProps}
        >
          {loaded && (
          <CardPreview
            className={classes.preview}
            id={'photopreview'}
            loaded={loaded}
            tile={tile}
            rotation={this.state.rotation}
            rotatefn={this.handleRotation.bind(this)}
          />
          )}
        </BaseCard>
      </div>
    );
  }
}

ReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReviewCard);
