
//import 'formdata-polyfill';

const validateForm = (form:any) => {
  var check =  form.checkValidity();
  var report =  form.reportValidity();
  return {check,report};
}

const getFormConfig = (auth?:any) => {
  const config = {
    method:'POST',
    credentials:'include',
    headers: auth?new Headers({authorization:`Bearer ${auth}`}):new Headers()
  };
  return config;
}

const getFormData = (form:any,fn?:any) => {
  const fntype = typeof fn;
  const fd:any = new FormData(form);
  if(fntype==='function'){
    return fn(fd);
  }
  else if(fn==='userform'){
    fd.append('mode','client');
    fd.append('type',((fd.has('useemail') && fd.get('useemail')==="on")?"email":"sms"));
    fd.append('user',((fd.has('useemail') && fd.get('useemail')==="on")?fd.get('email'):fd.get('mobile')));
    fd.append('keyeddata',((fd.has('userdata') && fd.get('userdata')==="on")?"YES":"NO"));
    console.log('usemail',fd.get('useemail'));
  }
  else if(fn==='formsend'){
    fd.append('delivery',((fd.has('useemail') && fd.get('useemail')==="on")?"email":"sms"));
    fd.append('user',((fd.has('useemail') && fd.get('useemail')==="on")?fd.get('email'):fd.get('mobile')));
    console.log('usemail',fd.get('useemail'));
  }
  else{
    return fd;
  }
}

const processResponse = (response:any) => {
  if(response.success){
    return ({error:null,data:response});
  }
  else if(response.failure){
    return ({error:true,data:{name:'fetcherror',type:'error',title:response.et,message:response.em, data:response}});
  }
  else {
    return ({error:true,data:{name:'fetcherror',type:'error',title:'Request Error',message:'request failed', data:response}});
  }
}

const responseBlob = (blob:any,ext:any,type:any,filename:any):any => {
  try{
    var newBlob = new Blob([blob], {type:type});
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob. TODO: Test for IE

    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   if (window.navigator.msSaveOrOpenBlob) {
    //     window.navigator.msSaveOrOpenBlob(newBlob);
    //   }
    //   return;
    // } 

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download=filename||`file.${ext}`;
    link.click();
    setTimeout(()=>{
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
    return ({error:null,success:true});
  }
  catch(error){
    return ({error:error,success:null});
  }
}

const basesubmit = async (url:string,form:any,config:any,fn?:any) => {
  var rtn;
  //start:validation
  try{
    const {check,report} = validateForm(form);
    console.log('form.checkValidity:', check);
    console.log('form.reportValidity:', report);
    if(!report) throw new Error('Validation failed. Invalid fields');
  }
  catch(err){
    const verr:any = err || {};
    console.log('validation error:'+verr.message);
    rtn = {failure:true,title:verr.title,type:verr.type,mesg:verr.message,data:null};
    return Promise.reject(rtn);
  }
  
  try{
    var fd = getFormData(form,fn);
    config.body = fd._blob ? fd._blob() : fd;
    const resp = await fetch(url,config);
    const response = await resp.json();
    const {error,data} = processResponse(response);
    if(error) {
      return Promise.reject(data);
    }
    else {
      form.reset();
      return data;
    }
  }
  catch(err){
    return Promise.reject(err);
  }
}

const helpers = {validateForm,getFormConfig,getFormData,processResponse,responseBlob,basesubmit};
export {validateForm,getFormConfig,getFormData,processResponse,responseBlob,basesubmit};
export default helpers;
