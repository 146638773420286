import React from 'react';
import Typography from '@mui/material/Typography';
import {grey, pink, purple, blue, teal, green, orange, lightGreen, red} from '@mui/material/colors';
import moment from 'moment';

const arrayformats = [
  {text:'',color:'default',scolor:grey[500]},
  {text:'',color:'default',scolor:pink[500]},
  {text:'',color:'secondary',scolor:purple[500]},
  {text:'',color:'primary',scolor:blue[500]},
  {text:'',color:'primary',scolor:teal[500]},
  {text:'',color:'secondary',scolor:purple[500]},
  {text:'',color:'primary',scolor:green[500]},
  {text:'',color:'secondary',scolor:orange[500]},
  {text:'',color:'primary',scolor:lightGreen[500]},
  {text:'',color:'secondary',scolor:red[500]},
  {text:'',color:'error',scolor:red[500]},
  {text:'',color:'default',scolor:grey[800]},
];
const arraydefault = {text:'UNKNOWN',color:'default',scolor:'black'};

const getarrayformat = (key:any) => arrayformats[key]||arraydefault;

const formatDate = (id:any) => {  //id must be isostamp
  return moment(id).format('DD-MMM-YYYY HH:mm');
}

//.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
const formatMoney = (amt:number) => {  //amt must be numeric
  if(!amt) return '';
  const options = { 
    minimumFractionDigits: 2,
    maximumFractionDigits: 2 
  };
  return Number(amt).toLocaleString('en', options);
  //w.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")+;
}

const formatGender = (id:number) => {
  let text = ['Female','Male'][id] || 'unknown';
  let color:any = ['secondary','primary'][id] || 'inherit';
  let rtn = (<Typography color={color}>{text}</Typography>);
  return rtn;
}

const formatList = (id:any,txt:any) => {
  let fmt = getarrayformat(id);
  let rtn = (<Typography style={{color:fmt.scolor}}>{txt}</Typography>);
  return rtn;
}

export {formatDate,formatMoney,formatGender,formatList};
