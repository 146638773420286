import React from 'react';
import Location from '../actions/Location';

interface JSONLocation {
  x:number; 
  y:number;
}

const defaultCoordinate = [5.56100881348042, -0.194186227822011]

const locToArray = (loc:any) => JSON.parse(loc.replace('(','[').replace(')',']')); //loc.replace(/\((\-?\d+),(\-?\d+)\)/,[$1,$2])
const lonToArray = (loc:any) => JSON.parse(loc.replace('{','[').replace('}',']'));
const jsonToArray = (loc:JSONLocation) => [loc.x,loc.y];

const formatLocation = (loc:any,rec:any) => {
  console.log('formatLoction.loc:',loc);
  let rtn = (<Location style={{color:'blue'}}  position={loc} info={rec.csn} />);
  return rtn;
}

const formatJsonLocation  =  (jsonloc:JSONLocation|undefined,rec:any) => {
  const {nam="txloc"} = rec;
  const loc = jsonloc?jsonToArray(jsonloc):defaultCoordinate;
  console.log('formatJsonLoction.loc:',loc);
  const rtn = (<Location style={{color:'blue'}}  position={loc} info={nam} />);
  return rtn;
}

export {locToArray,lonToArray,formatLocation,formatJsonLocation};
