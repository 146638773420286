import React from 'react';
import APIBalance from './APIBalance';
//import KeyForm from './KeyForm';

const forms:any = {
  //verify: <VeriForm />,
};

//export {forms,KeyForm,};
export {forms,};

//export {APIBalance} from './APIBalance';
