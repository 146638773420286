import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import MenuBox from './MenuBox';
import {colors} from '../../base';

const styles = (theme:Theme):any => ({
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  itemicon: {
    minWidth: 24,
    marginRight: 8,
  },
  child: {
    margin: 50,
  }
});

class KanbanMenuChild extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  // static propTypes: { classes: PropTypes.Validator<object>; onClick: PropTypes.Requireable<(...args: any[]) => any>; openImmediately: PropTypes.Requireable<boolean>; title: PropTypes.Validator<string>; };
  // constructor(props:any) {
  //   super(props);
  // }
  componentDidMount() {
    // So we only run this logic once.
    if (!this.props.openImmediately) {
      return;
    }

    // Center the selected item in the list container.
    const activeElement = document.querySelector(`.${this.props.classes.active}`);
    if (activeElement && activeElement.scrollIntoView) {
      activeElement.scrollIntoView({});
    }
  }

  render() {
    const {
      children,
      classes,
      index,
      itemid,
      icon,
      action,
      onClick,
      openImmediately,
      title,
      ...other
    } = this.props;

    return (
      <Grid item xs={2} className={classes.child}>
        <MenuBox 
          Icon={ShoppingCart}
          icon={icon}
          color={colors[index]}
          title={title}
          value={""}
          onClick={onClick}
        />
      </Grid>
    );
    
  }
}

KanbanMenuChild.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  openImmediately: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

KanbanMenuChild.defaultProps = {
  openImmediately: false,
};

export default withStyles(styles)(KanbanMenuChild);
