import {appbcs, applcs} from '../app/config';
const basebcs:any = {
  context: {sv:'context',lb:'Context',nm:'ctx',vf:'rid',df:'nam',rf:'ctx'},
  entity:{sv:'entity',lb:'Entity',nm:'eid',vf:'rid',df:'nam',rf:'enm'},
  entitytype:{sv:'entitytype',lb:'EntityType',nm:'eti',vf:'rid',df:'nam',rf:'ety'},
  gender:{sv:'gender',lb:'Gender',nm:'gdi',vf:'rid',df:'nam',rf:'gdn'},
  role:{sv:'role',lb:'Role',nm:'roi',vf:'rid',df:'nam',rf:'rnm'},
  user:{sv:'user',lb:'User',nm:'usi',vf:'rid',df:'nam',rf:'usr'},
  loglevel:{sv:'loglevel',lb:'Log Level',nm:'lvi',vf:'rid',df:'nam',rf:'lvn'},
  loginstatus:{sv:'loginstatus',lb:'Login Status',nm:'lst',vf:'rid',df:'nam',rf:'lst'},
};

const baselcs:any = {
  status: { lb: 'Status', nm: 'sts', vf: 'rid', df: 'nam', et: 'Select', sd: [[1, 'Enabled'], [0, 'Disabled'], [2, 'Locked']] },
  title: { lb: 'Title', nm: 'tti', vf: 'rid', df: 'nam', et: 'Select', sd: [[3, 'Dr.'], [2, 'Mrs.'], [1, 'Mr.'], [0, 'Miss']] }
};

const bcs = {...basebcs,...appbcs};
const lcs = {...baselcs,...applcs};

function basic(service:string){
  return bcs[service];
}

function local(service:string){
  return bcs[service];
}

function combo(service:string){
  return bcs[service] || lcs[service];
}

function combox(type:string,service:string){
  return {bcs,lcs}[type][service];
}

export {bcs, lcs, basic, local, combo};
export default combox;
