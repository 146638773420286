class Router {
  rlist: any;

  constructor(list?: any) {
    this.rlist = list;
  }

  getRoute(service: string) {
    return this.rlist;
  }

  getPrev(route: any, current: any) {
    return route[Math.max(0, route.indexOf(current) - 1)];
  }

  getNext(route: any, current: any) {
    return route[Math.min(route.length - 1, route.indexOf(current) + 1)];
  }
}

export default Router;
