//react 
import React from 'react';
import ff from '../formfields';
import {combos} from '../../core';

export const displayVal = (cp:any,record:any,value:any) => {
  const {t,s} = cp;
  const cc = combos(t,s);
  if(!cc) throw new Error(`Invalid Combo ${t}.${s}`);
  const v = record[cc.rf]||value;
  return v;
}

export const createFormField = (service:string,mode:string,cfg:any,record:any,formatters:any,onChange:Function,extras?:any) => {
  const {error,reset,cvalue,cuparams,others={}} = extras||{};
  const fmt = formatters||{};
  let ft = cfg.type;
  let id = cfg.id;
  let val = fmt[id]?fmt[id](record[id],record,mode,id):record[id]===null?undefined:record[id];
  let haslist = (['cb','cg','rg','fa','mf','ml'].includes(ft));
  let iscombo = ['cb','fa'].includes(ft);
  let props:any = {
    service,
    mode,
    record,
    id: cfg.id,
    label: cfg.label,
    required: !!(cfg.req),
    options: cfg.ops||{},
    error,
    others
  };
  if(haslist){
    props.baseParams = {s:service,a:'combo',...(cfg.params||{})};
  }
  if(iscombo){
    props.baseParams = {s:service,a:'combo',...(cfg.params||{})};
    props.config = cfg.cp||{};
    props.parents = cfg.pns;//||[];
    props.childs = cfg.chs;
    if(cfg.pns) props.cuparams = extras.cuparams; //combo update parameters: only if you have parents
    if(cfg.pns) props.parent = {[cfg.pns[0]]:cvalue};
    //if(cfg.cp&&mode==='view') val = displayVal(cfg.cp,record,val);
    val = (cfg.cp&&mode==='view')?displayVal(cfg.cp,record,val):record[id];
  }
  else{
    props.autoFocus = true;
  }
  
  if(mode!=='add') props.value = val;
  if(mode!=='view') {
    if(typeof onChange === 'function'){
      props.onChange = (typeof onChange(id) === 'function')?onChange(id):onChange;
    }
  }
  const dt = (mode!=='view')?ft:(ft==='if')?'ph':'rf';
  return {id:id,dom:(React.cloneElement(ff[dt],props))};
}

export const createFormItems = (service:string,mode:string,sdata:any,record:any,formatters:any,onChange:Function,extras?:any) => {
  const {options} = extras||{};
  const eform = sdata.eform;
  let items = eform.reduce( (acc:any[],conf:any) => {
    const {on,off} = conf;
    if(off){if(off.includes(mode)) return acc;}
    if(on){if(!on.includes(mode)) return acc;}
    //let iscombo = (['cb','fa'].includes(cfg.type));
    let ops = {...(conf.ops||{}),...(options||{})};
    let cfg = {...conf,ops};
    acc.push(createFormField(service,mode,cfg,record,formatters,onChange,extras));
    return acc;
  },[]);
  return items;
}

export const createItemList = (mode:string,sdata:any,record:any,formatters:any,onChange:Function,extras?:any) => {
  const service = sdata.name;
  const formitems = createFormItems(service,mode,sdata,record,formatters,onChange,extras);
  return formitems;
}

export const createItemSet = (mode:string,sdata:any,record:any,formatters:any,onChange:Function,extras?:any) => {
  const formitems = createItemList(mode,sdata,record,formatters,onChange,extras);
  const itemset = formitems.reduce((set:any,item:any)=>{
    set[item.id] = item.dom;
    return set;
  },{});
  return itemset;
}

export const create = (mode:string,sdata:any,record:any,formatters:any,onChange:Function,classes:any,extras?:any) => {
  const formitems = createItemList(mode,sdata,record,formatters,onChange,extras);
  
  return (
    <form id="requestform" className={classes.reqform}>
      {formitems.map((item:any) => (
        <div key={item.id}>{item.dom}</div>
      ))}
    </form>
  );
}

export const createFiltered = (mode:string,sdata:any,record:any,formatters:any,onChange:Function,classes?:any,extras?:any) => {
  //TODO: To optimize, consider filtering meta before building fields
  const formitems = createItemList(mode,sdata,record,formatters,onChange,extras);
  //TODO: Validate menu: menu:{mnu:[{t,v}],def:}
  const menu = sdata.menu||{};
  const {mnu} = menu;
  const keys = mnu.map((item:any)=>item.v);
  const filteredItems = formitems.filter((item:any)=>keys.includes(item.id));
  return (
    <React.Fragment>
      {filteredItems.map((item:any) => (
        <React.Fragment key={item.id}>{item.dom}</React.Fragment>
      ))}
    </React.Fragment>
  );
}

//export {createItemList,createItemSet,create,createFiltered};

export default create;
