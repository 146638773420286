import React,{Component} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/ViewCompact';
import DeleteIcon from '@mui/icons-material/Delete';
import ManIcon from '@mui/icons-material/Assignment';
import RetryIcon from '@mui/icons-material/Replay';
import CloseIcon from '@mui/icons-material/HighlightOff';
import UploadIcon from '@mui/icons-material/CloudUpload';
//import FilterListIcon from '@mui/icons-material/FilterList';
import WizardIcon from '@mui/icons-material/FilterList';
import CheckIcon from '@mui/icons-material/CheckRounded';
import ProcessIcon from '@mui/icons-material/CallToAction';
// import ColumnViewIcon from '@mui/icons-material/ViewColumn';

const actionmenus = [
  {action:'edit',label:'Edit',title:'Edit',icon:<EditIcon/>},
  {action:'view',label:'View',title:'View',icon:<ViewIcon/>},
  {action:'delete',label:'Delete',title:'Delete',icon:<DeleteIcon/>},
  {action:'upload',label:'Upload',title:'Upload',icon:<UploadIcon/>},
  {action:'wizard',label:'Wizard',title:'Wizard',icon:<WizardIcon/>},
  {action:'process',label:'Process',title:'Process',icon:<ProcessIcon/>},
  {action:'man',label:'Manage',title:'Manage',icon:<ManIcon/>},
  {action:'check',label:'Check',title:'Check',icon:<CheckIcon/>},
  {action:'retry',label:'Retry',title:'Retry',icon:<RetryIcon/>},
  {action:'close',label:'Close',title:'Close',icon:<CloseIcon/>}
];

const defaultmenus = actionmenus.slice(0,2);
    
const filterActions = (actions:any) => {
  // console.log('gridactions.actions',actions);
  if(actions.length === 0) return defaultmenus;
  return actionmenus.reduce((ac:any,v:any) => {
    if(actions.indexOf(v.action)>-1) ac.push(v);
    return ac;
  },[]);
}

export {actionmenus,defaultmenus,filterActions}
export default defaultmenus;
