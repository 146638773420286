import {saveAs} from 'file-saver';
import {apidownload,apipdf,apizip,bizpdf} from '.';

class Download {
  servicename:string;
  url:string;
  constructor(servicename?:string,url?:string){
    this.servicename = servicename || 'app';
    this.url = url || '/biz/print/';
  }

  getPDF = async(data:any,record:any) => {
    try{
      const refid = record.rfi;
      const trackid = record.tri;
      const authcode = record.cli;
      const filename = `${this.servicename}_${data.rid}.pdf`;
      //this.startLoading();
      const response = await bizpdf('/biz/pdf',{refid:refid,trackid:trackid},filename);//,(err:any,response:any) => {
        // if(err){
        //   //TODO: show message here
        //   console.log(err);
        // }
        // else{
      const blob = new Blob([response], {type: "application/pdf"}); 
      saveAs(blob, filename);
        //}
        //that.stopLoading();
      //});
    }
    catch(e){
      Promise.reject(e)
    }
  }

  downloadPDF = async(data:any,record:any) =>{
    try{
    const refid = record.rfi;
    const trackid = record.tri;
    const authcode = record.cli;
    const filename = `${this.servicename}_${data.rid}.pdf`;
    //this.startLoading();
    const response = await apipdf(`${this.url}/pdf`,authcode,{refid:refid,trackid:trackid},filename); //,function(err,response){
      // if(err){
      //   //TODO: show message here
      //   console.log(err);
      // }
      // else{
    const blob = new Blob([response], {type: "application/pdf"}); 
    saveAs(blob, filename);
    //   }
    //   //that.stopLoading();
    // });
    }
    catch(e){
      Promise.reject(e)
    }
  }

  downloadZIP = async(data:any,record:any) =>{
    try{
      const refid = record.rfi;
      const trackid = record.tri;
      const authcode = record.cli;
      const filename = `${this.servicename}_${data.rid}.zip`;
      //this.startLoading();
      const response = await apizip(`${this.url}/zip`,authcode,{refid:refid,trackid:trackid},filename); //,function(err,response){
        // if(err){
        //   //TODO: show message here
        //   console.log('report request error',err);
        // }
        // else{
      const blob = new Blob([response], {type: "application/zip"}); 
      saveAs(blob, filename);
      //   }
      // });
    }
    catch(e){
      Promise.reject(e)
    }
  }

  downloadFile = async(mode:any,data:any,record:any) =>{
    try{
      const refid = record.rfi;
      const trackid = record.tri;
      const authcode = record.cli;
      const filename = `${this.servicename}_${data.rid}.${mode}`;
      const path = `${this.url}/${mode}`;
      const meta:any = {
        name: filename,
        ctype: `application/${mode}`,
        type: `application/${mode}`
      }
      //this.startLoading();
      const response = await apidownload(meta,path,authcode,{refid:refid,trackid:trackid}); //,(err) => {
        // if(err){
        //   //TODO: show message here
        //   console.log(err);
        // }
        //that.stopLoading();
      // });
    }
    catch(e){
      Promise.reject(e)
    }
  }

  txtDemo = () => {
    const file = new File(["Hello, world!"], "helloworld.txt", {type: "text/plain;charset=utf-8"});
    saveAs(file);
  }

  jsonData = (name:any,dataset:any,data:any) => {
    const filename = `${this.servicename}_${data.rid}.json`;
    // Create Summary Table

    const recordset:any = {
      info: dataset.info,
      summary: dataset.summary, 
      validations: dataset.vals,
      data: dataset.sdocs, 
      //files: dataset.files, 
    }
    const blob = new Blob([JSON.stringify(recordset)], {type: "application/json;charset=utf-8"});
    saveAs(blob, filename);
  }
}

export default Download;
