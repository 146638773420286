import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
//import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
//import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { Typography } from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ListIcon from '@mui/icons-material/List';
//import {enableDragging} from '../dialogs/DraggablePaperComponent';
import {createFiltered} from '../form';
import {bizpost} from '../../ajax';
import { extendedstyles } from '../../app/appstyles';

const extrastyles = (theme:Theme):any => ({
  badge:{
    margin: theme.spacing(1),
  },
  flex: {
    flex:1
  },
  hidden: {
    display: 'none'
  }
});

const styles = extendedstyles(extrastyles);

class FilterBox extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    let meta = props.sdata.add || [];
    this.state = {
      meta: meta,
      data: {},
      dirty: 0
    };
  }

  handleChange = (id:any) => (ev:any,v:any) => {
    console.log('fb change',id,v);
    // const {hash,list} = this.state;
    // //update column selector
    this.setState((prevState:any)=>({...(v&&{dirty:++prevState.dirty})}));
    // //update grid
    // const newhash = v?set(hash,i):unset(hash,i);
    // const newlist = toarray(list,newhash);
    // this.setState({count:newlist.length});
    // this.props.setColumns({},newlist);
  }

  handleSend = (ev:any) => {
    const {data} = this.state;
    this.props.refresh(data);
  }

  onChange0 = (name:any) => (value:any) => {
    console.log('fb.onchange:',name,',',value);
    //this.state.data[name]=value;
  }
  onChange = (name:any) => (value:any) => {
    let v = (typeof value === 'string' && value.length ===0)?null:value;
    this.setState((prevState:any)=>({data:{...prevState.data,[name]:v}})); // this.state.formData[name]=value;
  }

  onSubmit = async() => {
    const {service,mode,record} = this.props;
    const {data} = this.state;
    try{
      let s = service;
      let a = mode;
      let params = Object.assign(record,data,{s,a});
      console.log('fb.submit.params:',params);
      //this.startLoading();
      const response = await bizpost(params);
      // console.log('login response ',response);
      this.props.handleSubmit(true,response);
    }
    catch(error){
      console.log(error);
    }
    finally{
      //this.stopLoading();
    }
  }
  
  render() {
    const {open,sdata,onClose,classes } = this.props;
    const {count,data} = this.state;
    const mode = 'add';
    const record = data;
    const formatters = {};
    //console.log('fb.filteredform');
    const form = createFiltered(mode,sdata,record,formatters,this.onChange,classes);
    return (
      
      <Dialog
      disableEscapeKeyDown
      //{...enableDragging}
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogTitle>
      <Toolbar>
        <Typography variant="h6" color="inherit" className={classes.flex}>
          {"Filter"}
        </Typography>
        
        <Badge
          color="secondary"
          badgeContent={count}
          //invisible={!count}
          className={classes.badge}
        >
          {/* <ListIcon {...(!count&&{className:classes.hidden})} /> */}
          <ListIcon />
        </Badge>
        <IconButton color="inherit" onClick={onClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Search By:</FormLabel>
          <FormGroup>
            {form}
          </FormGroup>
          <FormHelperText>At least 1 column</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={this.handleSend} color="primary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
    );
  }
}

FilterBox.propTypes = {
  classes: PropTypes.object.isRequired,
  // cols: PropTypes.arrayOf(PropTypes.object).isRequired,
  // fcols: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  setColumns: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default withStyles(styles)(FilterBox);
