import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
// import IconButton from '@mui/material/IconButton';
// import ImageList from '@mui/material/ImageList';
// import ImageListItem from '@mui/material/ImageListItem';
// import ImageListItemBar from '@mui/material/ImageListItemBar';
// import ZoomInIcon from '@mui/icons-material/ZoomIn';
// import { makeStyles } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
//import {saveAs} from 'file-saver';
import { clone } from '../../../core/utils';
//import { formatDate } from '../../../components/renderers';
import { formatDate, formatGender, formatStatus } from '../../../components/renderers';

//import { createItemList } from '../../../components/form/formgenerator';
import { createItemList, create, isValid } from '../../../components/form';
//import { PreviewDialog } from '../../../components/dialogs';
// import qccardimage from '../../../resources/cards/qccard.png';
// import qccodeimage from '../../../resources/cards/qccode.png';
import {extendedstyles} from '../../appstyles';

const extrastyles = (theme:Theme) => ({
  formcontainer: {
    overflow: 'scroll',
  },
  hide: {
    display: 'none'
  },
  spinner: {
    backgroundImage: 'url(assets/img/loading.gif)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 'auto',
    width: 80,
    height: 80,
  },
  minicolumn:{
    width: 200
  }
});
const styles = extendedstyles(extrastyles);

const cardwidth = 512;
const cardheight = 323;

class SendPanel extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      source: {},
      preview: false,
      valid:true,
      count:0
    };
  }

  componentDidMount() {
    this.props.setValid(this.state.valid);
    //this.drawCard();
  }

  formconfig = () => {
    const config = {
      service: {
        name: 'review',
        title: 'Review Information',
        eform:[{id:'fnm',label:'Firstname',type:'tf',req:1,col:1,ops:{}},
              {id:'mnm',label:'Middlename',type:'tf',req:0,col:1,ops:{}},
              {id:'lnm',label:'Lastname',type:'ta',req:0,col:1,ops:{}},
              {id:'gdi',label:'Gender',type:'fa',req:0,col:1,ops:{},cp:{t:'lcs',s:'gender'}},
              {id:'mbn',label:'Mobile',type:'tf',req:0,col:1,ops:{type:'tel'}},
              {id:'aci',label:'Service',type:'fa',req:1,col:1,ops:{},cp:{t:'bcs',s:'idtype'}},
              {id:'bci',label:'ServiceID',type:'tf',req:0,col:1,ops:{type:'tel'}},
              {id:'coi',label:'Country',type:'fa',req:1,col:1,ops:{},cp:{t:'bcs',s:'idtype'}},
              {id:'iti',label:'ID Type',type:'fa',req:1,col:1,ops:{},cp:{t:'bcs',s:'idtype'}},
              {id:'idn',label:'ID No',type:'tf',req:0,col:1,ops:{} },
              {id:'cui',label:'Currency',type:'fa',req:1,col:1,ops:{},cp:{t:'bcs',s:'idtype'}},
              {id:'bri',label:'Branch',type:'fa',req:1,col:1,ops:{},cp:{t:'bcs',s:'idtype'}},
              {id:'ano',label:'Account No',type:'tf',req:0,col:1,ops:{type:'tel'}},
              {id:'anm',label:'Account Name',type:'tf',req:0,col:1,ops:{type:'tel'}},
              {id:'ctn',label:'Contact',type:'tf',req:0,col:1,ops:{type:'tel'}},
              {id:'knm',label:'Kin Name',type:'tf',req:0,col:1,ops:{type:'tel'}},
              {id:'kre',label:'Kin Relation',type:'tf',req:0,col:1,ops:{}},
              {id:'kco',label:'Kin Contact',type:'tf',req:0,col:1,ops:{type:'tel'}},
              {id:'rmk',label:'Remarks',type:'ta',req:0,col:1,ops:{}},
              {id:'sts',label:'Status',type:'fa',req:0,col:1,ops:{},cp:{t:'lcs',s:'status'}},
            ],
      },
      create: true,
      actions: { view: 'View' },
      panels: { view: 'column' },
    };
    return config;
  }

  mkColumns = (items:any,columns:any) => {
    const fields:any = {}; 
    items.map((item:any) => {
      let id = columns[item.id] || 1;
      let key = `col${id}`;
      if(!fields[key]) fields[key] = [];
      fields[key].push(item); 
      return true;
    });
    return fields;
  }

  makeform = () => {
    const config = this.formconfig();
    const { record, classes, extras } = this.props;
    const sconfig = clone(config);
    const sdata = sconfig.service;
    const mode = 'view';
    const formatters = {dob:formatDate, gdi:formatGender,sts:formatStatus};
    const onChange = this.onChange.bind(this);
    //const form = create(mode,sdata,record,formatters,onChange,classes,extras);
    extras.options = {fullWidth:false};
    const formitems = createItemList(mode,sdata,record,formatters,onChange, extras);
    
    const {eform} = config.service;
    const columns = eform.reduce((acc:any,v:any)=>{
      acc[v.id]=v.col;
      return acc;
    },{});
    
    const fields = this.mkColumns(formitems,columns);

    return fields;
    
  }

  onChange = (name:any) => (value:any) => {}

  // makeview = () => {
  //   const { data, classes } = this.props;
  //   const {data:record} = data;
  //   const config = this.formconfig();
  //   const {eform} = config.service;
  //   const rows = eform.reduce((acc,rec)=>{
  //     const {id,label} = rec;
  //     let row = {id,label,value:record[id]};
  //     acc.push(row);
  //     return acc;
  //   },[]);
  //   return (
  //     <div className={classes.viewroot}>
  //       <Paper className={classes.viewpaper}>
  //         <Table className={classes.viewtable} size="small" aria-label="a dense table">
  //           {/* <TableHead>
  //             <TableRow>
  //               <TableCell>Parameter</TableCell>
  //               <TableCell align="right">Value</TableCell>
  //             </TableRow>
  //           </TableHead> */}
  //           <TableBody>
  //             {rows.map(row => (
  //               <TableRow key={row.id}>
  //                 <TableCell component="th" scope="row">{row.label}</TableCell>
  //                 <TableCell align="left">{row.value}</TableCell>
  //               </TableRow>
  //             ))}
  //           </TableBody>
  //         </Table>
  //       </Paper>
  //     </div>
  //   );
  // }

  render() {
    const {loading,data,classes} = this.props;
    const fields = this.makeform();
    return (
      <div className={classNames(classes.container,classes.formcontainer)}>
        <div className={loading?classes.spinner:classes.hide}></div>
        <form id="requestform" className={loading?classes.hide:classes.columnform}>
          {
            Object.keys(fields).map(key => (
              <div key={key} className={classNames(classes.columnfieldset,classes.minicolumn)}>
                {fields[key].map((item:any)=>(<div key={item.id}>{item.dom}</div>))}
              </div>
            ))
          }
        </form>
      </div>
    );
  }
}

SendPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SendPanel);
