import React, {Component} from 'react';
import ProgressDialog from './ProgressDialog';

class LoadingDialog extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      loading: false,
      open: false,
    };
  }

  render() {
    const { status,start,stop } = this.props;
    return (
      <ProgressDialog open={status} loading={status} startLoading={start} stopLoading={stop} />
    );
  }
}

//export default withStyles(styles, { withTheme: true })(ProgressDialog);

export default LoadingDialog;
