import React from 'react';
//import { PropTypes } from 'prop-types';

import StartCard from './StartCard';
import InfoCard from './InfoCard';
import SecurityCard from './SecurityCard';
import AccessCard from './AccessCard';
import SendCard from './SendCard';
import CloseCard from './CloseCard';

const cards = {
  start: <StartCard name='start' />,
  info: <InfoCard name='info' />,
  security: <SecurityCard name='security' />,
  access: <AccessCard name='access' />,
  send: <SendCard name='send' />,
  close: <CloseCard name='close' />
}

const list = ['start','info','security','access','send','close'];
const wizard = {cards,list};

export {cards,list};

export default wizard;