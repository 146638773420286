import React, {Component} from 'react';
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Radio';
import GridHeader from './GridHeader';
//import GridToolbar from './GridToolbar';
import GridPaging from './GridPagingActions';
import {gridSort} from './GridSorter';
import { Switch } from '@mui/material';
import { extendedstyles } from '../../app/appstyles';

const extrastyles = (theme:Theme):any => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: { 
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

const styles = extendedstyles(extrastyles);

class GridSwitch extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      order: this.props.order || 'asc',
      orderBy: this.props.orderBy, //'calories', 
      selected: [],
      colids: props.cols.map((row:any)=>row.id),
      cols: props.cols,//this.props.cols,
      data: props.data,//this.props.data,
      page: props.page || 0,//this.props.page || 0,
      rowsPerPage: props.rowsPerPage||10,//this.props.rowsPerPage||5,
      selectedRecord: null,
      selectedIndex: null,
      actions: [],
      recs:props.recs||{},
      list:[],
    };
  }

  onColumnSort = (event:any, property:any) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  onPageChange = (event:any, page:any) => {
    this.setState({ page });
  };

  onRowsPerPageChange = (event:any) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  onSwitch = (n:any) => (event:any) => {
    //console.log('n: ',n,'target ',event.target);
    const {onSwitch} = this.props;
    const {recs,list} = this.state;
    if(event.target){
      const name = event.target.name; //typeof event.target.name == 'number'?`rec${event.target.name}`:`${event.target.name}`;
      const checked = event.target.checked || false;
      const newrecs = {...recs, [name]: checked };
      list.push(name);
      this.setState({recs:newrecs,list});
      if(typeof onSwitch === 'function') onSwitch(newrecs,name,checked,list);
    }
    else console.log('event ',event);
  }
  
  //RSM
  isSelected = (id:any) => this.state.selectedIndex === id;

  render() {
    const { cols,recordset,keyField, formatters,filterColumns,classes } = this.props;
    const { recs, selected, order, orderBy, rowsPerPage, page,colids } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, recordset.length - page * rowsPerPage);
    const fm = formatters||{};
    //console.log("data",recordset,"props",this.props, 'recs:',recs);
    const fcols = filterColumns(cols,colids);
    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} stickyHeader aria-labelledby="tableTitle">
            <GridHeader
              cols={fcols}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              rowCount={recordset.length}
              actionCols={true}
            />
            <TableBody>
              {gridSort(recordset, order, orderBy)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  //const isSelected = this.isSelected(n.id);
                  //const name = (typeof n.id == 'number')?`rec${n.id}`:n.id;
                  const name = (typeof +(n[keyField]) === 'number')?`${keyField}_${n[keyField]}`:n[keyField];
                  const isSelected = recs[name];
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell key="rsm" padding="checkbox">
                        <Checkbox checked={isSelected}/>
                      </TableCell>
                      {fcols.map((col:any) =>
                      <TableCell key={col.id} padding="none">
                        {fm[col.id]?fm[col.id](n[col.id],n):n[col.id]}
                       </TableCell>
                      )}
                      <TableCell key="rsw" padding="checkbox">
                        <Switch 
                          checked={isSelected}
                          onChange={this.onSwitch(n)}
                          name={name}
                          // record={n}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={fcols.length+2} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={fcols.length+2}
                  count={recordset.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.onPageChange}
                  onRowsPerPageChange={this.onRowsPerPageChange}
                  //ActionsComponent={GridPagingActions}
                  //ActionsComponent={'span'}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

GridSwitch.propTypes = {
  filterColumns:PropTypes.func,
  classes: PropTypes.object.isRequired,
  rowsPerPage: PropTypes.number,
  recordset: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
  orderBy: PropTypes.string.isRequired,
  keyField: PropTypes.string.isRequired,
};

export default withStyles(styles)(GridSwitch);
