import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const styles = (theme:Theme) => {
  const cfg:any = {
  bannermaster: {
    marginLeft: 10,
  },
  bannerbox:{
    height: 300,
    width: 400,
    backgroundImage: 'url(lib/img/wcm2.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '0 50%',
    backgroundColor: 'transparent',
    //marginBottom: 100,

    borderRadius:'15px 0 0 0',
  },
  messagebox:{
    width: 400,
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    //paddingLeft: 10,
    flexDirection: 'vertical',
    alignItems: 'baseline',
    flex:1
  },
  titlebar: {
    display: 'flex',
    paddingLeft: 10,
  },
  first: {
    color: '#FFC60B',
  },
  last: {
    color: '#FFffFF',
  },
  title: {
    color: '#FFC60B',
  },
  subtitlebar: {
    paddingLeft: 10,
  },
  subtitle: {
    color: '#FFC60B',
  },
  messagebar: {
    paddingLeft: 10,
  },
  message: {
    color: '#FFFFFF',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
  },
  hide:{
    display:'none',
  },
};
return cfg;
}

class LoginBanner extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      next: true,
      value: this.props.value 
    };
  }

  splitline = (line:string) => {
    const chunks = String(line).split(/\s+/);
    const [first,last] = [chunks.shift(), chunks.join(' ')];
    return {first,last};
  }

  render() {
    const {id,label,divider,item,classes } = this.props;
    const {first,last} = this.splitline(item.title);
    return (
      <div className={classes.master00}>
        <div className={classes.bannerbox} style={{backgroundImage: `url(${item.photo})`}}>
          {/* <!-- Left Panel --> */}
        </div>
        <div className={classes.messagebox}>
          <div className={classes.titlebar}>
            <Typography className={classes.first} variant="h5" component="h5">{first}</Typography>&nbsp;
            <Typography className={classes.last} variant="h5" component="h5">{` ${last}`}</Typography>
          </div>
          <div className={classes.subtitlebar}>
            <Typography className={classes.subtitle} variant="subtitle1" component="span">{item.subtitle}</Typography>
          </div>
          <div className={classes.messagebar}>
            <Typography className={classes.message} variant="body1" component="div">{item.message}</Typography>
          </div>
        </div>
      </div>
    );
  }
}

LoginBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  //id: PropTypes.string.isRequired,
  //label: PropTypes.string.isRequired,
};

export default withStyles(styles)(LoginBanner);
