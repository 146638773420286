//colors
import { Theme } from '@mui/material';
import {pink} from '@mui/material/colors';
import {common} from '@mui/material/colors';
//import { Color } from '@mui/material';
//app defaults
import servicestyle from '../../globalstyles/servicestyle';
import statusstyles from '../../globalstyles/statusstyles';

const styles = (theme:Theme):any => {
  let white  = common.white;
  let rtn = servicestyle(theme,pink,white);
  let status = statusstyles(theme);
  return {...rtn,status};
}

const extendedstyles = (extrafn:any) => (theme:Theme):any => {
  let rtn = styles(theme);
  const extra = extrafn(theme);
  return {...rtn,...extra};
}

export {extendedstyles};

export default styles;