import React, { Component } from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {bizpost} from '../../ajax';
import {extendedstyles} from '../../app/appstyles';
import ClosableBar from './ClosableBar';

const extrastyles = (theme:Theme):any => ({
  submitBtn: {
    marginLeft: 10
  },
});

const styles = extendedstyles(extrastyles);
// const styles = (theme:Theme):any => ({
//   submitBtn: {
//     marginLeft: 10
//   }
// });

class LogoutWizard extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      open: null
    };
  }

  stopLoading = () => {
    const {stopProgress} = this.props;
    if(typeof stopProgress === 'function') stopProgress();
  }

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  handleLogout = async() => {
    var that = this;
    try{
      const json = {s:'security',a:'logout'};
      const response = await bizpost(json);
      console.log('login response ',response);
      document.location.reload();
    }
    catch(error){
      that.setState({open:false});
      that.props.close();
      //this.showNotification(error.message||"request failed",error.type||'warn',error.title||'Loading Error');
    }
    finally{
      this.stopLoading();
    }
  }

  render() {
    const {open,cancelLogout,classes} = this.props;
    const title = "User Logout";

    return (
      // <div>
      <Dialog open={open}  className={classes.dialog} classes={{paper: classes.dialogcontent}} maxWidth={'md'}>
          <DialogTitle className={classes.titlebar}>
            <ClosableBar onClose={cancelLogout}>{title}</ClosableBar>
          </DialogTitle>
          <DialogContent className={classes.dialogcontent}>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to logout?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={cancelLogout}>No, Cancel</Button>
            <Button variant="contained" color="primary" onClick={this.handleLogout}>Yes, Logout</Button> {/*className={classes.submitBtn}*/}
          </DialogActions>
        </Dialog>
      // </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LogoutWizard);
