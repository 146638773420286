import React, { Component } from 'react';
import PropTypes from 'prop-types';

import WelcomeCard from './WelcomeCard';
import VeriCard from './VeriCard';
import ProcCard from './ProcCard';
import SendCard from './SendCard';
import FinalCard from './FinalCard';

const cards = {
  welcome: <WelcomeCard name='welcome' />,
  veri: <VeriCard name='veri' />,
  proc: <ProcCard name='proc' />,
  send: <SendCard name='send' />,
  final: <FinalCard name='final' />
}

const list = ['welcome','veri','proc','send','final'];
const wizard = {cards,list};

export {cards,list};

export default wizard;