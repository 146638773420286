import regmatch from "./regmatch";
import customer from "./customer";
import agent from "./agent";
import client from "./client";

const wizards: any = {
  agent: {
    add: agent,
  },
  client: {
    add: client,
  },
  customer: {
    add: customer,
  },
  identity: {
    process: regmatch,
  },
};
//TODO: create single card error wizard as default using try/catch
const findWizard = (service: string) => (action: string) => {
  return wizards[service][action];
};

const getWizard = (service: string, action: string) => {
  return wizards[service][action];
};

export { findWizard, getWizard };
export default wizards;
