import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

const styles = (theme:Theme):any => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
  },
  hide:{
    display:'none',
  },
});

class FDateField extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      next: true,
      value: props.value||''
    };
  }

  // handleChange = (name:any) => (event:any) => {
  //   this.setState({
  //     [name]: event.target.value,
  //   });
  // };

  handleChange = (event:any) => {
    const { onChange } = this.props;
    let value = event.target.value;
    this.setState({ value });
    if (typeof onChange === 'function') onChange(value);
  };

  render() {
    const {id,label,required,options,divider,classes } = this.props;
    const {value} = this.state;

    return (
      <React.Fragment>
        <TextField
          label={label}
          id={id}
          name={id}
          type={'date'}
          required={required}
          margin='dense'
          fullWidth={true}
          className={classes.textField}
          onChange={this.handleChange}
          placeholder="Please select a date"
          InputLabelProps={{shrink: true }}
          value={value}
          {...options}
        />
        {divider && <Divider />}
      </React.Fragment>
    );
  }
}

FDateField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(FDateField);
