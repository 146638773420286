import React from 'react';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props:any) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"],[class*="MuiButtonBase-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
const enableDragging = {PaperComponent:PaperComponent};
export {enableDragging};
export default PaperComponent;
