
import {entitybase} from './baseconfig';

export const entityconfig = {
  
  clearer: Object.assign({},entitybase,{
    service:{
      name:'clearer',
      title:'Clearing Agent',
    }
  }),
  
  facility: Object.assign({},entitybase,{
    service:{
      name:'facility',
      title:'Facility',
    }
  }),
  
  installer: Object.assign({},entitybase,{
    service:{
      name:'installer',
      title:'Installer',
    }
  }),
  
  manufacturer: Object.assign({},entitybase,{
    service:{
      name:'manufacturer',
      title:'Manufacturer',
    }
  }),
  
  project: Object.assign({},entitybase,{
    service:{
      name:'project',
      title:'Project',
    }
  }),
  
  supplier: Object.assign({},entitybase,{
    service:{
      name:'supplier',
      title:'Supplier',
    }
  }),


};