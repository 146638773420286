import React from 'react';
import Typography from '@mui/material/Typography';
import {grey, pink, purple, blue, teal, green, orange, lightGreen, red} from '@mui/material/colors';
//import moment from 'moment';

const verformats:any = {
  REQUESTED:{text:'REQUESTED',color:'default',scolor:grey[500]},
  ACCESSED:{text:'ACCESSED',color:'default',scolor:pink[500]},
  UPLOADED:{text:'PENDING',color:'secondary',scolor:purple[500]},
  PROCESSED:{text:'PENDING',color:'secondary',scolor:blue[500]},
  SUBMITTED:{text:'PROCESSING',color:'primary',scolor:teal[500]},
  AUTOPASSED:{text:'PASSED',color:'primary',scolor:green[500]},
  AUTOFAILED:{text:'PROCESSING',color:'secondary',scolor:orange[500]},
  MANUALPASSED:{text:'PASSED',color:'primary',scolor:lightGreen[500]},
  MANUALFAILED:{text:'FAILED',color:'error',scolor:red[500]},
  DISABLED:{text:'CLOSED',color:'default',scolor:grey[800]},
  DEFAULT:{text:'ERROR',color:'default',scolor:'black'}
};

const getvformat = (stn:any) => verformats[stn]||verformats.DEFAULT;

const requestStatus = (id:number,rec:any) => {
  let fmt = getvformat(rec.stn);
  let rtn = (<Typography color={fmt.color}>{fmt.text}</Typography>);
  return rtn;
}

const reviewStatus = (id:number,rec:any) => {
  let fmt = getvformat(rec.stn);
  let rtn = (<Typography style={{color:fmt.scolor}}>{rec.stn}</Typography>);
  return rtn;
}

const formatSource = (id:number,rec:any) => {
  if(rec.dsc === "") return null;
  var it = JSON.parse(rec.dsc) || {};
   return it.src||'None';
}

export {requestStatus,reviewStatus,formatSource};
