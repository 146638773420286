import React,{Component} from 'react';
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import GridMSMHeader from './GridMSMHeader';
import GridMSMToolbar from './GridMSMToolbar';
import GridPagingActions from './GridPagingActions';
import {gridSort} from './GridSorter';
import { extendedstyles } from '../../app/appstyles';

const extrastyles = (theme:Theme):any => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

const styles = extendedstyles(extrastyles);

class GridMSMPanel extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      order: this.props.order || 'asc',
      orderBy: this.props.orderBy, //'calories',
      selected: [],
      rows: this.props.rows,
      data: this.props.data,
      page: this.props.page || 0,
      rowsPerPage: this.props.rowsPerPage||5,
      selectedRecord: null,
      selectedIndex: null,
    };
  }

  handleRequestSort = (event:any, property:any) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    //console.log('order',event,property,orderBy,order);
    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event:any) => {
    // MSM
    if (event.target.checked) {
      this.setState((state:any) => ({ selected: state.data.map((n:any) => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event:any, id:any) => {
    //MSM
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected:any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    //
    this.setState({ selected: newSelected });
  };

  onPageChange = (event:any, page:any) => {
    this.setState({ page });
  };

  onRowsPerPageChange = (event:any) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  //MSM
  isSelected = (id:any) => this.state.selected.indexOf(id) !== -1;
  
  render() {
    const { title, rows, data, formatters,handleSelect, classes } = this.props;
    const { selected, selectedRecord, order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const fm = formatters||{};
    return (
      <Paper className={classes.root}>
        <GridMSMToolbar 
          title={title} 
          numSelected={selected.length} 
          selectedRecord={selectedRecord}
          handleSelect={handleSelect}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <GridMSMHeader
              rows={rows}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {gridSort(data,order, orderBy)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n:any) => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell key="rsm" padding="checkbox">
                        <Checkbox checked={isSelected} />
                      </TableCell>
                      {rows.map((col:any) =>
                      <TableCell key={col.id} >
                        {fm[col.id]?fm[col.id](n[col.id],n):n[col.id]}
                       </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={rows.length+1} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={rows.length+1}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.onPageChange}
                  onRowsPerPageChange={this.onRowsPerPageChange}
                  //ActionsComponent={GridPagingActions}
                  //ActionsComponent={'span'}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

GridMSMPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  rowsPerPage: PropTypes.number,
  data: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default withStyles(styles)(GridMSMPanel);