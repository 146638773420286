import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button, DialogActions, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import L, { LatLngBoundsExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import ClosableBar from '../dialogs/ClosableBar';
import {extendedstyles} from '../../app/appstyles';

const extrastyles = (theme:Theme):any => ({
  dialogcontent: {
    backgroundColor: 'white',
    width:'100%',
  },
  root: {
    flex: '1 1 auto',
    background: theme.palette.background.paper,
  },
});

const styles = extendedstyles(extrastyles);

class MapPreview extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any = {zoom:13};
  constructor(props:any) {
    super(props);
    this.state = {
      loading: false,
      open: false,
    };
  }

  render() {
    const {classes,open,position,zoom,info,onClose} = this.props;
    const title = 'Location Preview';
    const dialogClasses = {
      paper: classes.dialogcontent
    }
    //const position = [51.505, -0.09]; //point
    const bounds:LatLngBoundsExpression = [[4.71046214438, -3.24437008301],[11.0983409693, 1.0601216976]];
    console.log('MapPreview:',open,position,zoom,info);
    return (
      <Dialog open={open} className={classes.dialog} classes={dialogClasses} onClose={onClose} draggable={true}>
        <DialogTitle className={classes.titlebar}><ClosableBar onClose={onClose}>{title}</ClosableBar></DialogTitle>
        <DialogContent className={classes.dialogcontent}>
          <div className={classes.root}>
            <MapContainer center={position} zoom={zoom} scrollWheelZoom={false} style={{height:600,width:'100%'}}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                bounds={bounds}
              />
              <Marker position={position}>
                <Popup>{info}</Popup>
              </Marker>
            </MapContainer>  
          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button variant="contained" color="secondary" onClick={onClose}><CloseIcon/>Close </Button>
        </DialogActions> */}
      </Dialog>
    );
  }
}

MapPreview.defaultProps = {
  position: [20,10],
  zoom: 13,
  // open: false,
  info: 'Current Location',
};

MapPreview.propTypes = {
  open: PropTypes.bool,
  position: PropTypes.array.isRequired,
  zoom: PropTypes.number,
  info: PropTypes.string,
  onClose: PropTypes.func
};

export default withStyles(styles, { withTheme: true })(MapPreview);
