import { Theme } from '@mui/material';
import {grey,cyan, pink, purple, orange, blue,green,red} from '@mui/material/colors';
//list: ['disabled','requested','accessed','submitted','passed','processing','passed','failed','unauthorized'],
const statusstyle = (theme:Theme) =>({
  disabled: {
    color: grey['500'],
  },
  requested: {
    color: orange['500'],
  },
  accessed: {
    color: pink['700'],
  },
  submitted: {
    color: blue['300'],
  },
  processing: {
    color: blue['700'],
  },
  passed: {
    color: green['500'],
  },
  failed: {
    color: red['500'],
  },
  unauthorized: {
    color: purple['500'],
  },
  unknown: {
    color: cyan['500'],
  },
});

export default statusstyle;