
export const baseconfig:any = {
  fieldTypes: {tf:'',ta:'',nf:'',df:'',cf:'',rf:'',cb:'',fa:'',rb:'',if:'',ff:''},
  service:{
    name:'default',
    title:'Service List',
    key:'rid',
    num: false,
    fields: ['rid','nam', 'shc', 'dsc', 'sts', 'ast', 'stp'],
    params: {nam:'Service',shc:'Code',dsc:'Description',sts:'Status'},
    grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['sts','Status'],['stp','Stamp']],
    bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
    menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
    eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
          {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
          {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
          {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
        ],
  },
  create:true,
  actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
};

//export {baseconfig};

export const codebase:any = {
  service:{
    name:'service',
    title:'Service List',
    key:'rid',
    num: false,
    fields: ['rid','nam', 'shc', 'dsc', 'svc', 'sts', 'ast', 'stp'],
    params: {nam:'Service',shc:'Code',dsc:'Description',svc:'Service Code',sts:'Status'},
    grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['svc','Service Code'],['sts','Status'],['stp','Stamp']],
    bar: {pag:true,btn:['add','edit','del','view']},
    menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
    eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
          {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
          {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
          {id:'svc',label:'Service Code',type:'nf',req:1,ops:{}},
          {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
        ],
  },
  create:true,
  actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
};

export const entitybase:any =  {
  service: {
    name: 'entity',
    title: 'Service Entity',
    key: 'rid',
    num: true,
    fields: ['rid','nam','shc','dsc','eni','enm','svc','exi','sts','ast','stp'],
    params: {nam:'Name',shc:'Code',dsc:'Description',eni:'Entity',svc:'Service Code',exi:'Extra Info',sts:'Status'},
    grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['enm','Entity'],['svc','Service Code'],['exi','Extra Info'],['sts','Status'],['stp','Stamp']],
    bar: {pag:true,btn:['add','edit','del','view']},
    menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
    eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
          {id:'shc',label:'Code',type:'tf',req:1,ops:{}},
          {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
          {id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'}},
          {id:'svc',label:'Service Code',type:'nf',req:1,ops:{}},
          {id:'exi',label:'ExtraInfo',type:'jf',req:0,ops:{}},
          {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
        ],
  },
  create:true,
  actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
};
