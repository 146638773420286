import {configs as ServiceConfig} from '../app/config';

const getConfig = (service:string) => () => {
  var rtn = Object.assign({},ServiceConfig.baseconfig,(ServiceConfig[service]||{}));
  return rtn;
}

const setConfig = (config:any) => {
  console.log('ServiceConfig',ServiceConfig);
  console.log('config',config);
  var rtn = Object.assign({},ServiceConfig.baseconfig,config);
  return rtn;
}

export {getConfig,setConfig};

export default getConfig;
