import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

const styles = (theme:Theme):any => ({
  grid: {
    minHeight: '100vh',
  },
});
    
class DynamicGrid extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;

  render() {
    const {xs,sm,md,lg,xl, children,classes} = this.props;
    const xxs = xs || 12;
    const xsm = sm || 6; 
    const xmd = md || 4;
    const xlg = lg || 2; 
    const xxl = xl || 2;
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.grid}
      >
        <Grid item xs={xxs} sm={xsm} md={xmd} lg={xlg} xl={xxl}>
          {children}
        </Grid>   
      </Grid> 
    );
  }
}

DynamicGrid.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default withStyles(styles, { withTheme: true })(DynamicGrid);
