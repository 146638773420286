import React, { Component, Fragment } from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import {blue,pink,green} from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';

import {extendedstyles} from '../appstyles';
import { clone } from '../../core/utils';
import { formatDate } from '../../components/renderers';
import { createItemList,create, isValid } from '../../components/form';

const extrastyles = (theme:Theme) => ({
  profileform: {
    padding: theme.spacing(1),
    width: 450,
  },
  details:  {
    width: 400,
  },
  leftIcon: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(1),
  },
  actionbox: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1),
  },
  action: {
    margin: 'auto 5px',
  },
  button: {
    margin: theme.spacing(1),
  },
});

const styles = extendedstyles(extrastyles);

class ChangePassword extends Component<any,any> {
  constructor(props:any) {
    super(props);
    this.state = {
      count:0,
      data: [],
      valid: false,
    };
  }

  componentDidMount(){
    this.props.setValid(this.state.valid);
  }

  formconfig = () => {
    // const cbip = {InputLabelProps:{ shrink:true,}};
    const config = {
      service: {
        name: 'changepassword',
        title: 'Change Password',
        eform: [
          { id:'opd',label:'Old Password',type:'pf',req:1,ops:{ helperText:'Enter Old Password'} },
          { id:'npd',label:'New Password',type:'pf',req:1,ops:{ helperText:'Enter New Password'} },
          { id:'cpd',label:'Confirm New Password',type:'pf',req:1,ops:{ helperText:'confirm New Password' } },
        ],
      },
      create: true,
      actions: { add: 'Add' },
      panels: { add: 'simple' },
    };
    return config;
  }

  setValid = (data:any) => {
    const sconfig = this.formconfig();
    const mode = 'add';
    const valid = isValid(sconfig,data,mode);
    this.props.setValid(valid);
  }

  onChange = (name:any) => (value:any) => {
    const {data} = this.props;
    const newdata = {...data.data,[name]:value};
    this.setValid(newdata);
    this.props.setItem('data','data',newdata);
  }

  makeform = () => {
    const config = this.formconfig();
    const { record } = this.props;
    const sconfig = clone(config);
    const sdata = sconfig.service;
    const mode = 'add';
    const formatters = { dob: formatDate };
    const onChange = this.onChange.bind(this);
    // const form = create(mode,sdata,record,formatters,onChange,classes, extras);
    const  extras = {};
    const list = createItemList(mode,sdata,record,formatters,onChange, extras);
    return list;
  }

  onReset = (event:any) => {
    //reset for fields and data
  }

  render() {
    console.log(this.props);
    const {valid,doSubmit,onReset,classes } = this.props;
    const form = this.makeform();
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <LockIcon className={classes.leftIcon} />
          <Typography variant="h5" className={classes.titletext}>
            Change Password
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <form className={classes.profileform}>
            {form.map((item:any) => (<React.Fragment key={item.id}>{item.dom}</React.Fragment>))}
            <div className={classes.actionbox}>
              <Button variant="contained" color="secondary" className={classes.action} disabled={!valid} onClick={this.onReset}>Cancel</Button>
              <Button variant="contained" color="primary" className={classes.action} disabled={!valid} onClick={doSubmit}>Submit</Button>
            </div>
          </form>
        </AccordionDetails>
      </Accordion> 
    );
  }
}

export default withStyles(styles)(ChangePassword);
