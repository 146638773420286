import React, {Component} from 'react';

import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import LoginForm from './LoginForm';
import LoginFooter from './LoginFooter';
import LoginCarousel from './LoginCarousel';
import { CenterGrid } from '../layout';

const styles = (theme:Theme):any => ({
  rootbox:{
    position: 'relative',
    height: '100vh',
  },
  gridbox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)'
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor:theme.palette.primary.main,
    borderRadius: 15,
  },
});
  
class LoginMain extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);

    this.state = {
      count: 0,
    };
  }


  render() {
    const {home,apptheme,logincontext,onMessage,classes} = this.props;

    return (
      <div className={classes.rootbox}>
        <div className={classes.gridbox}>
          <CenterGrid>
            <Paper elevation={3} square={false} className={classes.main}>
              <LoginCarousel onMessage={onMessage} />
              <LoginForm home={home} apptheme={apptheme} logincontext={logincontext} onMessage={onMessage}/>
            </Paper>
            <LoginFooter home={home} apptheme={apptheme} onMessage={onMessage} />
          </CenterGrid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LoginMain);
