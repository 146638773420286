import React, {Component, Fragment} from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
//import Dialog, {DialogTitle,DialogContent, DialogContentText, DialogActions} from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

const styles = (theme:Theme):any => ({
  button: {
    margin: theme.spacing(1)
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'block'
  },
  progress: {
    margin: theme.spacing(4),
    backgroundColor: 'transparent'
  },
  dialogcontent: {
    backgroundColor: 'white'
  }
});

class ProgressDialog extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      loading: false,
      open: false,
    };
  }

  stopLoading(){
    const {stopProgress} = this.props;
    if(typeof stopProgress === 'function') stopProgress();
  }

  render() {
    const {classes,loading} = this.props;
    const dialogClasses = {
      paper: classes.dialogcontent
    }
    return (
      <Fragment>
        <Dialog open={this.props.open}  className={classes.dialogcontent} classes={dialogClasses}>
          <DialogContent className={classes.dialogcontent}>
          { loading &&
            <CircularProgress 
              //className={classNames(classes.progress,loading?classes.show:classes.hide)} 
              className={classes.progress} 
              color="secondary"
              size={100}
              //style={{ color: purple[500] }} 
              thickness={7} />
          }
          </DialogContent>
          {loading &&
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={this.stopLoading}><CloseIcon/> </Button>
          </DialogActions>
          }
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ProgressDialog);
