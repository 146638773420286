import {cyan, pink, purple, orange,blue,brown,green,grey} from '@mui/material/colors';

const basecolors = [
  cyan['50'],pink['50'],green['50'],orange['50'],blue['50'],purple['50'],brown['50'],
  cyan['100'],pink['100'],green['100'],orange['100'],blue['100'],purple['100'],brown['100'],
  cyan['200'],pink['200'],green['200'],orange['200'],blue['200'],purple['200'],brown['200'],
  cyan['300'],pink['300'],green['300'],orange['300'],blue['300'],purple['300'],brown['300'],
  cyan['400'],pink['400'],green['400'],orange['400'],blue['400'],purple['400'],brown['400'],
  cyan['500'],pink['500'],green['500'],orange['500'],blue['500'],purple['500'],brown['500'],
  cyan['600'],pink['600'],green['600'],orange['600'],blue['600'],purple['600'],brown['600'],
  cyan['700'],pink['700'],green['700'],orange['700'],blue['700'],purple['700'],brown['700'],
];
const colors = [
  // cyan['50'],pink['50'],green['50'],orange['50'],blue['50'],purple['50'],brown['50'],
  // cyan['100'],pink['100'],green['100'],orange['100'],blue['100'],purple['100'],brown['100'],
  // cyan['200'],pink['200'],green['200'],orange['200'],blue['200'],purple['200'],brown['200'],
  cyan['300'],pink['300'],green['300'],orange['300'],blue['300'],purple['300'],brown['300'],
  cyan['400'],pink['400'],green['400'],orange['400'],blue['400'],purple['400'],brown['400'],
  cyan['500'],pink['500'],green['500'],orange['500'],blue['500'],purple['500'],brown['500'],
  cyan['600'],pink['600'],green['600'],orange['600'],blue['600'],purple['600'],brown['600'],
  cyan['700'],pink['700'],green['700'],orange['700'],blue['700'],purple['700'],brown['700'],
  // cyan['100'],pink['100'],green['100'],orange['100'],blue['100'],purple['100'],brown['100'],
  // cyan['200'],pink['200'],green['200'],orange['200'],blue['200'],purple['200'],brown['200'],
  cyan['300'],pink['300'],green['300'],orange['300'],blue['300'],purple['300'],brown['300'],
  cyan['400'],pink['400'],green['400'],orange['400'],blue['400'],purple['400'],brown['400'],
  cyan['500'],pink['500'],green['500'],orange['500'],blue['500'],purple['500'],brown['500'],
  cyan['600'],pink['600'],green['600'],orange['600'],blue['600'],purple['600'],brown['600'],
  cyan['700'],pink['700'],green['700'],orange['700'],blue['700'],purple['700'],brown['700'],
  // cyan['100'],pink['100'],green['100'],orange['100'],blue['100'],purple['100'],brown['100'],
  // cyan['200'],pink['200'],green['200'],orange['200'],blue['200'],purple['200'],brown['200'],
  cyan['300'],pink['300'],green['300'],orange['300'],blue['300'],purple['300'],brown['300'],
  cyan['400'],pink['400'],green['400'],orange['400'],blue['400'],purple['400'],brown['400'],
  cyan['500'],pink['500'],green['500'],orange['500'],blue['500'],purple['500'],brown['500'],
  cyan['600'],pink['600'],green['600'],orange['600'],blue['600'],purple['600'],brown['600'],
  cyan['700'],pink['700'],green['700'],orange['700'],blue['700'],purple['700'],brown['700'],
  // cyan['100'],pink['100'],green['100'],orange['100'],blue['100'],purple['100'],brown['100'],
  // cyan['200'],pink['200'],green['200'],orange['200'],blue['200'],purple['200'],brown['200'],
  cyan['300'],pink['300'],green['300'],orange['300'],blue['300'],purple['300'],brown['300'],
  cyan['400'],pink['400'],green['400'],orange['400'],blue['400'],purple['400'],brown['400'],
  cyan['500'],pink['500'],green['500'],orange['500'],blue['500'],purple['500'],brown['500'],
  cyan['600'],pink['600'],green['600'],orange['600'],blue['600'],purple['600'],brown['600'],
  cyan['700'],pink['700'],green['700'],orange['700'],blue['700'],purple['700'],brown['700'],
];

const def = grey['500'];

const getRandomInt = (min:number,max:number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const getColorRandom = () => {
  let i = getRandomInt(1,56);
  return basecolors[i];
}

const getColor = (id:number,rand?:boolean) => colors[id] || rand?getColorRandom():def;

export {colors,getColor,getColorRandom};

export default colors;
