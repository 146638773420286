import {getFormConfig,processResponse,responseBlob,basesubmit} from './helpers';


// const formsend = async(url,form,auth) =>{
//   try{
//     const config = getFormConfig(auth);
//     return await basesubmit(url,form,config,'formsend');
//   }
//   catch(error){
//     console.log('formsend error:'+error.message);
//     var rtn = {failure:true,title:error.title,type:error.type,mesg:error.message,data:null};
//     return Promise.reject(rtn);
//   }
// }


const apipost = async(url:string,auth:string,json:any) => {
  try{
    const config:any = {
      method:'POST',
      credentials:'include',
      body:JSON.stringify(json), //{s:'security',a:'basicdata'}
      headers: new Headers({
        'Content-Type': 'application/json', //TODO: Accept application/json
        'Authorization': `Bearer ${auth}`
      })
    };
    const resp = await fetch(url,config);
    const response = await resp.json();
    const {error,data} = processResponse(response);
    if(error) return Promise.reject(data);
    else return data;
  }
  catch(err){
    return Promise.reject(err);
  }
}

const apisubmit = async (url:string,auth:string,form:any) =>{
  try{
    const config = getFormConfig(auth);
    return await basesubmit(url,form,config,'apisubmit');
  }
  catch(err){
    const error:any = err||{};
    console.log('apisubmit error:'+error.message);
    var rtn  = {failure:true,title:error.title,type:error.type,mesg:error.message,data:null};
    return Promise.reject(rtn);
  }
}

const apifile = async (url:string,auth:string,json:any,filename:string,type:string,ext:string) => {
  try{
    const config:any = {
      method:'POST',
      credentials:'include',
      body:JSON.stringify(json), //{s:'security',a:'basicdata'}
      headers: new Headers({
        'Content-Type': 'application/json', //TODO: Accept application/json
        'Authorization': `Bearer ${auth}`
      })
    };
    const resp = await fetch(url,config);
    const blob = await resp.blob();
    const {error,success} = responseBlob(blob,ext,type,filename)||{};
    if(error) return Promise.reject(error);
    return success;
  }
  catch(err){
    return Promise.reject(err);
  }
}

const apipdf = async (url:string,auth:string,json:any,filename:string) => {
  try{
    return apifile(url,auth,json,filename,'pdf','application/pdf');
  }
  catch(err){
    return Promise.reject(err);
  }
}

const apizip = async (url:string,auth:string,json:any,filename:string) =>{
  try{
    return apifile(url,auth,json,filename,'zip','application/zip');
  }
  catch(err){
    return Promise.reject(err);
  }
}

const apidownload = async (meta:any,url:string,auth:string,json:any) => {
  try{
    const ext = meta.name.split('.').pop();
    return await apifile(url,auth,json,meta.name,ext,meta.type);
  }
  catch(err){
    return Promise.reject(err);
  }
}

const apirequest = {apipost,apisubmit,apifile,apipdf,apizip,apidownload};
export {apipost,apisubmit,apifile,apipdf,apizip,apidownload};
export default apirequest;
