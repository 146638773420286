//react
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
//material-ui design helpers
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import {getDialogs} from '../dialogs';
//siblings
import styles from '../../app/appstyles';

/**
 * -------------------------------------------------------------------
 * Service Panel
 * -------------------------------------------------------------------
 */
class DialogPanel extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;

  dialogError = () => {
    return (
      <Fragment>
        <Typography>
          {"No rendering component for selected service panel"}
        </Typography>
      </Fragment>
    )
  }

  render = () => {
    const {mode,onPanelError} = this.props;
    const dt = getDialogs(mode);
    if(dt) {
      return dt;
    }
    else {
      onPanelError(true);
      return this.dialogError();
    }
  }
};

DialogPanel.propTypes = {
  service: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['delete','view','close','process','retry','check','print']).isRequired,
  record: PropTypes.object,
};

//DialogPanel = withStyles(styles, { withTheme: true })(DialogPanel);

export default withStyles(styles, { withTheme: true })(DialogPanel);
