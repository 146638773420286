

function desc(a:any, b:any, orderBy:any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array:any[], cmp:any) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order:any, orderBy:any) {
  return order === 'desc' ? (a:any, b:any) => desc(a, b, orderBy) : (a:any, b:any) => -desc(a, b, orderBy);
}

function gridSort(data:any,order:any,orderBy:any) {
  let newdata = stableSort(data, getSorting(order, orderBy));
  var rtn = newdata.map((d:any,i:number)=>{d.id=i+1;return d});
  return rtn;
}

export {gridSort};
