import ResourceView from './ResourceView';
import RegisterView from './RegisterView';
import ResultView from './ResultView';
import VerificationView from './VerificationView';

const views:any = {
  resource:ResourceView,
  result:ResultView,
  register:RegisterView,
  verification:VerificationView
};

export {ResourceView,ResultView,RegisterView};

export default views;