import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';

const styles = (theme:Theme):any => ({
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  itemicon: {
    minWidth: 24,
    marginRight: 8,
  }
});

class DynamicMenuItem extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  
  state = {
    open: this.props.openImmediately,
  };

  componentDidMount() {
    // So we only run this logic once.
    if (!this.props.openImmediately) {
      return;
    }

    // Center the selected item in the list container.
    const activeElement = document.querySelector(`.${this.props.classes.active}`);
    if (activeElement && activeElement.scrollIntoView) {
      activeElement.scrollIntoView({});
    }
  }

  render() {
    const {
      children,
      classes,
      icon,
      index,
      action,
      onClick,
      openImmediately,
      title,
      ...other
    } = this.props;

    var menuicon = React.isValidElement(icon) ? icon : (<MenuIcon />);

    return ( 
      <ListItem button onClick={onClick(null,action,index)} {...other}> 
        <ListItemIcon className={classes.itemicon}>
          {menuicon}
        </ListItemIcon>
        <ListItemText  primary={title} />
      </ListItem>
    );
  }
}

DynamicMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  openImmediately: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

DynamicMenuItem.defaultProps = {
  openImmediately: false,
};

export default withStyles(styles)(DynamicMenuItem);
