import React, {Component} from 'react';

import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar'; 
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Mail from '@mui/icons-material/Mail';
import Help from '@mui/icons-material/Help';
import {CenterGrid} from '../../components/layout';
import {bizpost} from '../../ajax';
import APIBalance from '../custom/APIBalance';
import APIStatement from '../custom/APIStatement';
//import APIService from '../custom/APIService';
//import APITransfer from '../custom/APITransfer';
import { extendedstyles } from '../appstyles';
import { clone } from '../../core/utils';
import { formatDate } from '../../components/renderers';
import { create, isValid } from '../../components/form';
import { InputAdornment } from '@mui/material';

const extrastyles = (theme:Theme) => ({
  loginbox: {
    //width: 450
    margin:'50px auto',
    display: 'inline-block'
  },
  loginform: {
    padding: theme.spacing(1),
  },
  titlebar: {
    color: 'white',
    textALign: 'center',
    flex:1
  },
  formControl: {
    margin: theme.spacing(1),
  },
  input: {
    margin: theme.spacing(1),
  },
  actionbox: {
    display: 'flex',
    justifyContent: 'center'
  },
  action: {
    margin: 'auto 5px',
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
  },
  menu: {
    //width: 200,
  },
  hide: {
    display:'node'
  },
  show: {
    display:'inline-block'
  },
  logo: {
    cursor: 'pointer',
    fontSize: 22,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightLight,
    backgroundImage: 'url(lib/img/logo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '0 50%',
    backgroundColor: 'transparent',
    paddingLeft: 5,
    paddingRight: 5,
    height: 20,
    width:25,
    [theme.breakpoints.down('sm')]:{
      display:'none',
    },
  },

  progress: {
    margin: theme.spacing(0, 2),
  },
  spinner: {
    backgroundImage: 'url(lib/img/loading.gif)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 'auto',
    width: 80,
    height: 80,
  },
  note:{
    margin: theme.spacing(1),
  },
  error: {
    color: '#ff0000',
  }
  
});

const styles = extendedstyles(extrastyles);

class Support extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);

    this.state = {
      loading: false,
      count: 0,
      open: false,
      sbopen: false,
      sbmesg: '',

      serviceid: 1,
      remember: false,

      valid: props.valid||true,
      data: {},

      percent: 0,
      fwopen: false,
      error: false,
      status: 'Saving',
    };
  }

  componentDidMount(){
    //this.props.setValid(this.state.valid);
  }

  formconfig = () => {
    //combo.inputLabelProps => cilp
    const cbip = {InputLabelProps:{ shrink:true,}};
    const subprops = {startAdornment: (<InputAdornment position="start"><Help /></InputAdornment> )};
    const msgprops = {startAdornment: (<InputAdornment position="start"><Mail /></InputAdornment> )};
    const config = {
      service: {
        name: 'dataform',
        title: 'Data Form',
        eform: [ //:nam,:ati,:aci,:ayi,:mbn,:cbu,:cbp,:cbc,:cba,:lci,:dtl,:sts'
          { id:'nam',label:'Name',type:'rf',req:1,ops:{ value:'Ernie Ofori' } },
          { id:'mbn',label:'Mobile',type:'rf',req:0,ops:{value:'0244711825' } },
          { id:'eml',label:'Email',type:'rf',req:0,ops:{value:'ernieofori@gmail.com' } },
          { id:'sub',label:'Issue',type:'tf',req:0,ops:{ helperText:'Enter Issue Subject',InputProps:subprops } },
          { id:'msg',label:'Message',type:'ta',req:0,ops:{helperText:'Enter Issue Details',InputProps:msgprops}},
        ],
      },
      create: false,
      actions: { add: 'Add' },
      panels: { add: 'simple' },
    };
    return config;
  }

  setValid = (data:any)  => {
    const sconfig = this.formconfig();
    //const {mode} = this.props;
    const mode = 'edit';
    const valid = isValid(sconfig,data,mode);
    //console.log('valid:',valid);
    //this.setState({valid});

    //const valid = (!!newdata.nam && !!newdata.agc && !!newdata.mbn && !!newdata.lci);
    //const valid = (!!data.nam && !!data.agc && !!data.mbn && !!data.lci);
    //console.log('valid:',valid);
    this.setState({valid});
    //this.props.setValid(valid);
  }

  onChange = (name:any) => (value:any) => {
    //const {data} = this.props;
    const {data} = this.state;
    const newdata = {...data.data,[name]:value};
    this.setValid(newdata);
    //this.props.setItem('data','data',newdata);
    this.setParamItem('data',name,value,undefined);
    //this.setState({data:newdata});
  }

  makeform = () => {
    const config = this.formconfig();
  
    //const { record, extras, classes } = this.props;
    const { userData, extras, classes } = this.props;
    //console.log('userdata:',JSON.stringify(userData));
    const {bd} = userData;
    console.log('bd:',JSON.stringify(bd));
    const {rid,nam,unm,ct1:mbn,eml} = bd;
    const record = {nam,mbn,eml};
    const sconfig = clone(config);
    const sdata = sconfig.service;
    const mode = 'edit';
    const formatters = { dob: formatDate };
    const onChange = this.onChange.bind(this);
    const form = create(mode,sdata,record,formatters,onChange,classes, extras);
    return form;
  }

  // setValid = (valid:any) => {
  //   this.setState({valid})
  // }

  // setBalanceValid = (balanceValid:any) => {
  //   this.setState({balanceValid})
  // }

  // setStatementValid = (statementValid:any) => {
  //   this.setState({statementValid})
  // }

  setParam = (key:string,val:any,done:any) =>{
    try{
      this.setState({[key]:val});
    }
    catch(e){
      //this.props.deb.log('setParam error: ',key,val);
      console.log('setParam error: ',key,val);
    }
  }

  setParamItem = (key:string,name:any,val:any,done:any) =>{
    try{
      this.setState((prevState:any)=>({[key]:{...prevState[key],[name]:val}}),done)
    }
    catch(e){
      //this.props.deb.log('setParamItem error: ',key,name,val);
      console.log('setParamItem error: ',key,name,val);
    }
  }

  onProgress = (ev:any,perc:any) => {
    this.setState({percent:perc});
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }

  doCancel = () => {};

  //this method is designed for basecard.handleSend
  doSubmit = async() => {
    //const { classes, theme, showNotification, ...extraProps } = this.props;  //fmtUI, logo, title, switchFn,
    const { showNotification} = this.props;  //fmtUI, logo, title, switchFn,

    try{
      const { userData } = this.props;
      const {bd} = userData;
      const {rid,nam,unm,ct1:mbn,eml} = bd;

      const {data} = this.state;
      console.log('data',data);
      const {msg,sub} = data;

      const  bp = {s:'support',a:'email',rid,nam,unm,mbn,eml};
      let params = {...bp,msg,sub};
      
      this.startLoading();
      const response = await bizpost(params);
      
      if (response.success){
        showNotification(response.sm,'success',response.st)
        //onResponse(true,response);
        return response;
      }
      else if(response.failure){
        if (response.en===60){
        }
        else{
          showNotification(response.message,response.type||'error',response.title);
          return Promise.reject(response);
        }
      }      
    }
    catch(err){
      const error:any = err || {};
      console.log('submit.error:',JSON.stringify(error));
      showNotification(error.message||"request failed",error.type||'warn',error.title||'Update Error');
      return Promise.reject(error);
    }
    finally{
      this.stopLoading();
    }
     
  }

  render() {
    const {classes} = this.props;
    //const {service,code,showNotification,setPage,setHome,classes, theme} = this.props;
    //const {data,record,balanceValid,statementValid,valid} = this.state;
    //const {balanceResponse,statementResponse} = this.state;
    const title = "Support Contact";
    //const vertical = 'top', horizontal = 'center';
    //const logosrc = '/api/logo/icon.png';
    const logosrc = 'lib/img/logo.png';

    const form = this.makeform();

    return (
      <CenterGrid xs={12} sm={6}>
        <Paper elevation={3} square={false}>
          <AppBar position="static">
            <Toolbar>
              <div className={classes.logo} style={{backgroundImage: `url(${logosrc})`}}/>
              <Typography variant="h3" className={classes.titlebar}>
                {title}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className={classes.root}>
            {form} 
          </div>
          <div className={classes.actionbox}>
            <Button variant="contained" color="secondary" className={classes.action} onClick={this.doCancel}>Cancel</Button>
            <Button variant="contained" color="primary" className={classes.action} onClick={this.doSubmit}>Submit</Button>
          </div>
        </Paper>
      </CenterGrid>
    );
  }

}

export default withStyles(styles, { withTheme: true })(Support);
