import React from 'react';
import Typography from '@mui/material/Typography';
import {grey, pink, purple, blue, teal, green, orange, lightGreen, red} from '@mui/material/colors';
//import moment from 'moment';

const ftformats:any = {
  REQUESTED:{text:'REQUESTED',color:'default',scolor:grey[500]},
  ACCESSED:{text:'ACCESSED',color:'default',scolor:pink[500]},
  UPLOADED:{text:'UPLOADED',color:'secondary',scolor:purple[500]},
  PROCESSED:{text:'PROCESSED',color:'primary',scolor:blue[500]},
  SUBMITTED:{text:'SUBMITTED',color:'primary',scolor:teal[500]},
  PENDING:{text:'PENDING',color:'secondary',scolor:purple[500]},
  PASSED:{text:'PASSED',color:'primary',scolor:green[500]},
  FAILED:{text:'FAILED',color:'secondary',scolor:orange[500]},
  SUCCESS:{text:'SUCCESS',color:'primary',scolor:lightGreen[500]},
  FAILURE:{text:'FAILED',color:'secondary',scolor:red[500]},
  ERROR:{text:'ERROR',color:'error',scolor:red[500]},
  DISABLED:{text:'CLOSED',color:'default',scolor:grey[800]},
  DEFAULT:{text:'ERROR',color:'default',scolor:'black'}
};

const getftformat = (key:any) => ftformats[key]||ftformats.DEFAULT;

const formatTxnStatus = (key:any) => {
  let fmt = getftformat(key);
  let rtn = (<Typography style={{color:fmt.scolor}}>{fmt.text}</Typography>);
  return rtn;
}

const formatTxStatusList = (id:number,txt:any) => {
  let fmt = getftformat(id);
  let rtn = (<Typography style={{color:fmt.scolor}}>{txt}</Typography>);
  return rtn;
}

//export {txnStatus,arrayStatus};

export {formatTxnStatus,formatTxStatusList};