
class Debugger {
  qs:any;
  remote:boolean;
  local:boolean;
  constructor(props:any){
    this.qs = props.qs || {};
    const lev = +(props.qs.log);
    this.remote = ((lev&3)===1); //props.remote || false;
    this.local = ((lev&3)===2); //!this.remote;
    this.log = this.log.bind(this);
    //can we access public members here?
    console.log('starting debugger');
    this.log("Debugger started");
  }

  log(...message:any){
    let mesg = message.join("");
    //console.log('message',mesg);
    if(this.local){
      console.log(mesg);
    }
    if(this.remote){
      this.remotelog(mesg);
    }
  }

  locallog(...message:any){
    let mesg = message.join("");
    console.log(mesg);
  }

  remotelog(...message:any){
    //let mesg = message.join("");
    const serverurl = "/api/debug";
    const body = this.getJsonData(message); //application/json
    if(!body) return;
    const request = new XMLHttpRequest();
    request.open("POST", serverurl,true);
    //request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    request.send(body);
  }

  getJsonData(...message:any){
    let msg = message.join("");
    if(!msg) return msg;
    const {code,type,user,token} = this.qs;
    var json = {msg,code,type,user,token};
    return JSON.stringify(json);
  }
  
}

export default Debugger;
