import React, { Component } from "react";
import PropTypes from "prop-types";

import Router from "./Router";

class Master extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  cards: any;
  router: any;
  constructor(props: any) {
    super(props);
    const { deb, service, cards, list } = props;
    (deb || console).log("Master");
    //var service = this.props.service;
    this.cards = cards;
    this.router = new Router();
    const route = list;
    const next = this.router.getNext(route, route[0]);
    const prev = this.router.getPrev(route, route[0]);
    const card = route[0];
    const wd: any = {};

    route.map((r: any) => {
      wd[r] = {};
      return r;
    });

    this.state = {
      modal: true,
      message: "",
      count: 0,
      vdata: {},
      valid: true,

      sdata: {},
      card: card,
      prev: prev,
      next: next,
      route: route,
      service: service,
      data: wd,
    };
  }

  setRoute = () => {
    var route = this.props.list;
    this.setState({ route: route });
  };

  setParam = (key: string, val: any, done?: any) => {
    try {
      this.setState({ [key]: val });
    } catch (e) {
      this.props.deb.log("setParam error: ", key, val);
    }
  };

  setParamItem = (key: string, name: any, val: any, done?: any) => {
    try {
      this.setState(
        (prevState: any) => ({ [key]: { ...prevState[key], [name]: val } }),
        done
      );
    } catch (e) {
      this.props.deb.log("setParamItem error: ", key, name, val);
    }
  };

  setValid = (valid: boolean) => {
    this.setState({ valid });
  };

  changeCard = (cardname: any) => {
    this.setState({ card: cardname });
  };

  goFirst = () => {
    //const
    var newcard = this.state.route[0];
    this.setState({ card: newcard });
  };

  goLast = () => {
    var newcard = this.state.route[this.state.route.length - 1];
    this.setState({ card: newcard });
  };

  goNext = (cardname: any) => {
    const { route } = this.state;
    var newcard = this.router.getNext(route, cardname);
    //console.log('goNext,', cardname, route,',newcard:',newcard);
    this.setState({ card: newcard });
  };

  goPrev = (cardname: any) => {
    var newcard = this.router.getPrev(this.state.route, cardname);
    this.setState({ card: newcard });
  };

  switchCard = (cardname: any) => {
    this.setState({ card: cardname });
  };

  doReset = () => {
    this.goFirst();
  };

  showNotification = (card: any, message: any) => {
    this.props.deb.log("Notification: ", message);
  };

  render = () => {
    const {
      service,
      code,
      title,
      mode,
      record,
      type,
      deb,
      wizClose,
      showNotification,
      extras,
      ...others
    } = this.props;
    //console.log('Master.showNotification:',showNotification);
    const { valid, card, message, route, data } = this.state;
    const logo = "lib/img/banner.jpg";
    return React.cloneElement(
      card in this.cards
        ? this.cards[card]
        : this.cards.welcome || Object.values(this.cards)[0],
      {
        //showNotification: this.showNotification,
        showNotification,
        service,
        code,
        title,
        logo,
        deb,
        mode,
        record,
        type,
        valid,
        extras,

        cardname: card,
        message,
        routes: route,
        data,
        //...others,

        setParam: this.setParam.bind(this),
        setItem: this.setParamItem.bind(this),
        setPrev: this.goPrev.bind(this),
        setNext: this.goNext.bind(this),
        setFirst: this.goFirst.bind(this),
        setLast: this.goLast.bind(this),
        setReset: this.doReset.bind(this),
        setRoute: this.setRoute.bind(this),
        switchFn: this.changeCard.bind(this),
        wizClose,
      }
    );
  };
}

Master.propTypes = {
  styles: PropTypes.object,
  showNotification: PropTypes.func,
  page: PropTypes.string,
};

export default Master;
