import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import {grey} from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import PageBase from '../../site/PageBase';
import KanbanMenuChild from './KanbanMenuChild';
// import KanbanMenuItem from './KanbanMenuItem';
import KanbanMenuParent from './KanbanMenuParent';

const styles = (theme:Theme):any => ({
  navigation: {
    fontSize: 15,
    fontWeight: 300,
    color: grey['600'],
    paddingBottom: 15,
    display: 'block'
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
    marginBottom: 20,
    color:'white'
  },
  paper: {
    padding: 30,
    margin: 50
  },
  clear: {
    clear: 'both'
  }
});

const defaultmenus = () => {
  return(
  <KanbanMenuChild 
    index={0} 
    key={'home'} 
    title={'Service Menu'} 
    action={'home'} 
    activeid={'home'} 
    onClick={() => alert('Please reload page')}
    />
  )
}

class KanbanMenu extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      dd: this.props.userData.dd,
      ping: null,
      count:0,
      title: '',
      curmenu: defaultmenus()
    };
  }

  mkKey = (k:any,n:any) => k+n.toLocaleString('en', {minimumIntegerDigits:3});

  toArray(o:any){
    Object.keys(o).reduce(function(out:any, key:any) {
      out.push({
        key: key,
        value: o[key]
      });
      return out;
    }, []);
  }

  componentDidMount(){
    this.userMenus(this.props.userData.mn||{});
  }

  onParentClick = (name:any) => (event:any,value:any) => {
    console.log('kanban.parentclick:',name,event,value);
    //const {curmenu,smdata,mndata,mnlist} = this.state;
    const {title,curmenu,mndata} = this.state;
    const selmenu = mndata[name].smlist || curmenu;
    const seltitle = mndata[name].title || title;
    this.setState({activeId:name,curmenu:selmenu,title:seltitle});
  }

  onItemClick = (menu:any,page:any,index:any)=> () => {
    console.log('kanban.itemclick:menu,page,index',menu,page,index);
    this.props.setPage(page);
  }

  userMenus = (mns:any) => {
    let mnu,mnlist,mndata:any,smdata,mnkey,mni,smn,smtitle,smaction,smlist,smi,smkey,mnid,iti;
    const title = 'Services';
    mnlist = [];
    mndata = {};
    mni=0;
    iti=0;
    for(var key in mns){
      // console.log('mns.key',key);
      if(!key || key === 'undefined') continue;
      mnid = key.toLowerCase();
      mnu = mns[key];
      smn = mnu.smn;
      smlist = [];
      smdata = [];
      smi = 1;
      for(smtitle in smn){ 
        smaction = smn[smtitle];
        smkey = this.mkKey(key,smi);
        smlist.push((<KanbanMenuChild index={mni*10+smi} itemid={iti} icon={mnid} key={smkey} title={smtitle.replace(/^Manage\s+/,'')} action={smaction} activeid={this.state.activeId} onClick={this.onItemClick(mnid,smaction,iti)} />)); //onclick
        smdata.push({smid:smi, index:(mni*10+smi), itemid:iti, key:smkey, title:(smtitle.replace(/^Manage\s+/,'')), action:smaction, activeid:this.state.activeId, onClick:this.onItemClick(mnid,smaction,iti)}); //onclick
        smi++;
        iti++;
      }
      mnkey = this.mkKey(key,0);
      mnlist.push(
        (
          <KanbanMenuParent index={mni*10} menuid={mni} icon={mnid} key={mnkey} title={mnu.nam.replace(/ .*/,'')} divider activeid={this.state.activeId} value={smlist.length} onClick={this.onParentClick(mnid)}> 
            {smlist.map((smitem) =>
              smitem 
            )}
          </KanbanMenuParent>
        )
      );
      mndata[mnid] = {menuid:mni, index:(mni*10), key:mnkey, title:(mnu.nam.replace(/ .*/,'')), activeid:this.state.activeId, value:smlist.length, onClick:this.onParentClick(mnid),smdata,smlist};
      mni++;
    }
    this.setState({title,mnlist,mndata,smlist,smdata,curmenu:mnlist});
    //return mnlist;
  }

  render() {
    const {userData,classes} = this.props;
    //const {mnlist,mndata,smdata,curmenu} = this.state;
    const {title,curmenu} = this.state;

    return (
      <PageBase navigation=" Service Menu" subid={userData.bd.eni}>
      <div>
        <h3 className={classes.navigation}>{title}</h3>
        <Grid container spacing={1}>
        {curmenu}
        </Grid>        
      </div>
      </PageBase>
    );
  }
};

KanbanMenu.propTypes = {
  userData: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(KanbanMenu);