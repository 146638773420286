
import {codebase} from './baseconfig';

export const codeconfig = {
  
  installmode: Object.assign({},codebase,{
    service:{
      name:'installmode',
      title:'Install Mode',
    }
  }),
  
  metertype: Object.assign({},codebase,{
    service:{
      name:'metertype',
      title:'Meter Type',
    }
  }),
  
  servicecode: Object.assign({},codebase,{
    service:{
      name:'servicecode',
      title:'Service Codes',
    }
  }),
};