import React, {Component} from 'react';

import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar'; 
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import CenterGrid from '../../components/layout/CenterGrid';
import {bizpost} from '../../ajax';
import ChangePassword from '../custom/ChangePassword';
import { extendedstyles } from '../appstyles';

const extrastyles = (theme:Theme) => ({
  loginbox: {
    //width: 450
    margin:'50px auto',
    display: 'inline-block'
  },
  loginform: {
    padding: theme.spacing(1),
  },
  titlebar: {
    color: 'white',
    textALign: 'center',
    flex:1
  },
  formControl: {
    margin: theme.spacing(1),
  },
  input: {
    margin: theme.spacing(1),
  },
  actionbox: {
    display: 'flex',
    justifyContent: 'center'
  },
  action: {
    margin: 'auto 5px',
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
  },
  menu: {
    //width: 200,
  },
  hide: {
    display:'node'
  },
  show: {
    display:'inline-block'
  },
  logo: {
    cursor: 'pointer',
    fontSize: 22,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightLight,
    backgroundImage: 'url(lib/img/logo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '0 50%',
    backgroundColor: 'transparent',
    paddingLeft: 5,
    paddingRight: 5,
    height: 20,
    width:25,
    [theme.breakpoints.down('sm')]:{
      display:'none',
    },
  },

  progress: {
    margin: theme.spacing(0, 2),
  },
  spinner: {
    backgroundImage: 'url(lib/img/loading.gif)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 'auto',
    width: 80,
    height: 80,
  },
  note:{
    margin: theme.spacing(1),
  },
  error: {
    color: '#ff0000',
  }
  
});

const styles = extendedstyles(extrastyles);

class Password extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);

    this.state = {
      loading: false,
      count: 0,
      open: false,
      sbopen: false,
      sbmesg: '',
      opassword:'',
      npassword:'',
      cpassword:'',
      showPassword: false,
      valid: props.valid||true,
      data: {}
    };
  }

  setValid = (valid:any) => {
    this.setState({valid})
  }

  setParamItem = (key:string,name:any,val:any,done:any) =>{
    console.log('set paramitem called: ', key,'-',name,'-',val);
    try{
      this.setState((prevState:any)=>({[key]:{...prevState[key],[name]:val}}),done)
    }
    catch(e){
      console.log('setParamItem error: ',key,name,val);
    }
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }

  handleLogout = async() => {
    var that = this;
    try{
      const json = {s:'security',a:'logout'};
      const response = await bizpost(json);
      document.location.reload();
    }
    catch(error){
      that.setState({open:false});
      that.props.close();
    }
    finally{
      this.stopLoading();
    }
  }

  //this method is designed for basecard.handleSend
  doSubmit = async() => {
    const { classes, theme, showNotification} = this.props;
    try{
      const {data} = this.state;
      const {opd,npd,cpd} = data.data;
      var json = {s:'security',a:'change',opw:opd,npw:npd};

      if(npd !== cpd){
        showNotification('Password Do Not Match','warn','Mismatch Password');
          return;
      }

      this.startLoading();

      const response = await bizpost(json);
      // console.log('change password response ',response);
      if(response.success){
        showNotification(response.sm,'success',response.st)
        this.handleLogout();
      }
      else if(response.failure){
        if (response.en===60){
        }
        else{
          showNotification(response.message,response.type||'error',response.title);
          return Promise.reject(response);
        }
      }       
    }
    catch(err){
      const error:any = err || {};
      console.log('submit.error:',JSON.stringify(error));
      showNotification(error.message||"request failed",error.type||'warn',error.title||'Update Error');
      return Promise.reject(error);
    }
    finally{
      this.stopLoading();
    }
     
  }

  handleReset = () => {
    this.setParamItem('data','data',{},()=>{});
  }

  render() {
    const {classes} = this.props;
    const {data,valid} = this.state;
    // const {balanceResponse} = this.state;
    const logosrc = '/api/logo/icon.png';

    return (
      <CenterGrid xs={12} sm={6}>
        <Paper elevation={3} square={false}>
        <AppBar position="static">
          <Toolbar>
            <div className={classes.logo} style={{backgroundImage: `url(${logosrc})`}}/>
            <Typography variant="h4" className={classes.titlebar}>
              Change Password
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <ChangePassword 
            data={data}
            record={{}}
            valid={valid}
            setValid={this.setValid.bind(this)}
            setItem={this.setParamItem.bind(this)}
            doSubmit={this.doSubmit.bind(this)}
          />   
        </div>
        </Paper>
      </CenterGrid>
    );
  }

}

export default withStyles(styles, { withTheme: true })(Password);
