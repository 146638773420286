import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import JsonViewer from 'react-json-view';
import Divider from '@mui/material/Divider';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import IconMore from '@mui/icons-material/MoreHoriz';
import IconSave from '@mui/icons-material/Save';
import IconCancel from '@mui/icons-material/Cancel';
import IconClose from '@mui/icons-material/Close';
import { ClosableBar } from '../dialogs';
import {extendedstyles} from '../../app/appstyles';

const extrastyles = (theme:Theme):any => ({
  dialogcontent: {
    backgroundColor: 'white'
  },
  root: {
    flex: '1 1 auto',
    background: theme.palette.background.paper,
  },
  jsonbox: {

  },
});

const styles = extendedstyles(extrastyles);
// const styles = (theme:Theme) => {
//   const cfg:any = {
//     root: {
//       //width: '100%',
//       flex: '1 1 auto',
//       background: theme.palette.background.paper,
//     },
//     master: {
//       display: 'flex',
//       flexWrap: 'wrap',
//       borderBottomColor: theme.palette.grey[500],
//       borderBottomWidth: 1,
//       borderBottomType: 'solid',
//     },
//     textField: {
//       marginLeft: theme.spacing(1),
//       marginRight: theme.spacing(1),
//       //width: 200,
//     },
//     
//       display:'none',
//     },
//   };
// return cfg;
// }

class JsonView extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      next: true,
      id: this.props.id,
      value: this.props.value, // || {"name":"Adenta Barrier","district":"Adenta","region":"Greater Accra","records":[1,5,6,7,13,27]},
      open: false,
    };
  }

 

  onMouseDown = (event:any) => {
    console.log('called onMouseDown',event.currentTarget);
    event.preventDefault();
  }

  onAdd = () => {
  };

  onEdit = () => {
  };

  onDelete = () => {
  };

  onView = () => {
  }

  count = (rec:any) => {
    const to = typeof rec;
    const cn = (rec === undefined || rec === null)?0:
               (Array.isArray(rec))?rec.length:
               (to === 'boolean' || to === 'number' || to === 'string')?1:
               (to === 'object')?Object.keys(rec).length: 0;
    return cn;
  }

  formatValue = (label:string,value:any) => {
    const cn = this.count(value);
    const rtn = `${label}: {. . .} ${cn} items`;
    return rtn;
  }

  render() {
    //const {id,label,divider,classes } = this.props;
    //const {} = this.state;
    const {open,value,mode,id,label,classes,onClose} = this.props;
    const title = `${label} Dataset`;

    return (
      // <div className={classes.master222}>
      <Fragment>
        <Dialog open={open}  className={classes.dialog} classes={{paper: classes.dialogcontent}} maxWidth={'md'}>
          <DialogTitle className={classes.titlebar}>
            <ClosableBar onClose={onClose}>{title}</ClosableBar>
          </DialogTitle>
          <DialogContent className={classes.dialogcontent}>
            <div className={classes.jsonbox}>
              <JsonViewer
                src={value}
                //id={id}
                //theme={'monokai'}
                name={false}
                //iconStyle={'triangle'}
                indentWidth={2}
                collapsed={false}
                collapseStringsAfterLength={15}
                quotesOnKeys={false}
                //onAdd={(mode!=='view')?this.onAdd:false}
                //onEdit={(mode!=='view')?this.onEdit:false}
                //onDelete={(mode!=='view')?this.onDelete:false}
                //onChange={this.handleChange}
              />
              
              
            </div>

          </DialogContent>
          <DialogActions>
            <IconButton onClick={onClose} onMouseDown={this.onMouseDown}><IconClose /></IconButton>
          </DialogActions>
        </Dialog>
      </Fragment>  
      // </div>
    );
  }
}

JsonView.propTypes = {
  classes: PropTypes.object.isRequired,
  // id: PropTypes.string.isRequired,
  //label: PropTypes.string.isRequired,
};

export default withStyles(styles)(JsonView);
