import React from 'react';

import StartCard from './StartCard';
import PersonalCard from './PersonalCard';
import AccountCard from './AccountCard';
import SystemCard from './SystemCard';
import ContactCard from './ContactCard';
import SendCard from './SendCard';
import CloseCard from './CloseCard';

const cards = {
  start: <StartCard name='start' />,
  personal: <PersonalCard name='personal' />,
  account: <AccountCard name='account' />,
  system: <SystemCard name='system' />,
  contact: <ContactCard name='contact' />,
  send: <SendCard name='send' />,
  close: <CloseCard name='close' />
}

const list = ['start','personal','account','system','contact','send','close'];
const wizard = {cards,list};

export {cards,list};

export default wizard;
