import React, { Component } from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
//import {cyan, grey, pink, purple, orange, yellow, blue} from '@mui/material/colors';
import {grey} from '@mui/material/colors';
import Grid from '@mui/material/Grid';
// import Assessment from '@mui/icons-material/Assessment';
// import Face from '@mui/icons-material/Face';
// import ThumbUp from '@mui/icons-material/ThumbUp';
// import ShoppingCart from '@mui/icons-material/ShoppingCart';
import PageBase from '../site/PageBase';
// import InfoBox from './Info';
// import Daily from './Daily';
// import Recent from './Recent';
// import Monthly from './Monthly';
// import Feature from './Feature';
import {bizpost} from '../../ajax';
import Network from './Network';


const styles = (theme:Theme):any => ({
  root: {
    flexGrow: 1,
  },
  navigation: {
    fontSize: 15,
    fontWeight: 300,
    color: grey['600'],
    paddingBottom: 15,
    display: 'block'
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
    marginBottom: 20,
    color:'white'
  },
  paper: {
    padding: 30
  },
  clear: {
    clear: 'both'
  }
});

class Members extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      dd: this.props.userData.dd,
      ping: null,
      timer: 60000, //300000,
      count:0
    };
  }

  // componentDidMount(){
  //   const {timer} = this.state;
  //   var ping = setInterval(async()=>{
  //     try{
  //       const baseparams = {s:'security',a:'memsdata'};
  //       const response = await bizpost(baseparams);
  //       this.setState({dd:response.dd});
  //     }
  //     catch(error){
  //       console.log('dd error',error);
  //     } 
  //   },timer);
  //   this.setState({ping:ping});
  // }

  // componentWillUnmount(){
  //   if(this.state.ping) clearInterval(this.state.ping);
  //}

  render() {
    const {userData,theme,classes} = this.props;
    const {bd} = userData;
    const {dd} = this.state;
    
    return (
      <PageBase navigation="Service Middleware / Connections" subid={(bd||{}).eni}>
        <div className={classes.root}>
          <Grid container spacing={theme.breakpoints.up('sm')?3:1}>
            {/* <Grid item xs={12} sm={6}> */}
            <Grid item xs={12} sm={12}>
              <Network data={dd.type||[]} title={"Member Network"}/>
            </Grid>
          </Grid>
        </div>
      </PageBase>
    );
  }
};

export default withStyles(styles, { withTheme: true })(Members);
